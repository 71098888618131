import React, { useState, useEffect, useContext } from "react";
import { Outlet, Link, Navigate, useNavigate } from "react-router-dom";
import { GlobalContext } from "../utilities/globalContext";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import authList from "../utilities/authList";
import Grid from "@mui/material/Unstable_Grid2";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";

export default function ClosedAuthorizations() {
  const { URL } = useContext(GlobalContext);
  const [authData, setAuthData] = useState([]);
  const [deleteStatus, setDeleteStatus] = useState(0);
  const authorizations = authList(deleteStatus, setDeleteStatus);

  //Get authorizations
  useEffect(() => {
    axios
      .get(`${URL}ThePortClinic-API/getAuthorizations.php?status=0`)
      .then((response) => {
        console.log(response.data);
        setAuthData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [deleteStatus]);

  const rows =
    authData.status === false
      ? []
      : authData.map((item) => ({
          id: item.id,
          entry_date: item.entry_date,
          companyName: item.company_name,
          employeeName: item.employee_name,
          currentBill: `$${Number(item.total_bill).toFixed(2)}`,
        }));

  return (
    <Box sx={{ flexGrow: 1, height: "100%" }}>
      <Grid container sx={{ height: "100%" }} spacing={2} columns={4}>
        <Grid md={4}>
          <Typography variant="h4">Open Authorizations</Typography>
        </Grid>
        <Grid md={4}>
          <Paper elevation={5} sx={{ p: 3, height: "70vh" }}>
            <DataGrid
              rows={rows}
              columns={authorizations.columns}
              pageSize={15}
              rowsPerPageOptions={[15]}
              checkboxSelection
              components={{
                Toolbar: GridToolbar,
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: "entry_date", sort: "desc" }],
                },
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
