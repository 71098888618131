import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { setFormValues } from "../../../../utilities/Forms/formFunctions";

export default function InjuryReportWC(props) {
  const handleForm = (event) => {
    setFormValues(
      props.formInput,
      props.setFormInput,
      props.formData,
      props.setFormData,
      event
    );
    console.log(props.formInput);
  };

  return (
    <Box component="form">
      <Paper elevation={5} sx={{ p: 3, mt: 2 }}>
        <Grid container columns={8} spacing={2}>
          <Grid md={2}>
            <Typography variant="h6">Injury Report</Typography>
          </Grid>
          <Grid md={4}>
            <Typography variant="h6">
              Mechanism of Injury / Causation Analysis
            </Typography>
          </Grid>
          <Grid md={2}>
            <Typography variant="h6">Current Work Status</Typography>
          </Grid>
          <Grid md={2}>
            <Typography variant="subtitle1">Prior Injuries/Pain</Typography>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="mechanism1"
              name="mechanism1"
              value={props.formInput.mechanism1}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.formInput.RTWWithRestrictions ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    props.setFormInput({
                      ...props.formInput,
                      RTWWithRestrictions: value,
                    });
                  }}
                />
              }
              label="RTW - With Restrictions"
              name="RTWWithRestrictions"
              value={props.formInput.RTWWithRestrictions}
              onChange={handleForm}
            />
          </Grid>
          <Grid md={2}>
            <Typography variant="subtitle1">Activities/Height/Wt.</Typography>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="mechanism2"
              name="mechanism2"
              value={props.formInput.mechanism}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.formInput.RTWNoRestrictions ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    props.setFormInput({
                      ...props.formInput,
                      RTWNoRestrictions: value,
                    });
                  }}
                />
              }
              label="RTW - No Restrictions"
              name="RTWWithRestrictions"
              value={props.formInput.RTWNoRestrictions}
              onChange={handleForm}
            />
          </Grid>
          <Grid md={2}>
            <Typography variant="subtitle1">Frequencies/Duration</Typography>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="mechanism3"
              name="mechanism3"
              value={props.formInput.mechanism3}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.formInput.NotWorking ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    props.setFormInput({
                      ...props.formInput,
                      NotWorking: value,
                    });
                  }}
                />
              }
              label="Not Working"
              name="NotWorking"
              value={props.formInput.NotWorking}
              onChange={handleForm}
            />
          </Grid>
          <Grid md={2}>
            <Typography variant="subtitle1">Sudden/Gradual Onset</Typography>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="mechanism4"
              name="mechanism4"
              value={props.formInput.mechanism4}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.formInput.LastDay ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    props.setFormInput({
                      ...props.formInput,
                      LastDay: value,
                    });
                  }}
                />
              }
              label="Last Day"
              name="LastDay"
              value={props.formInput.LastDay}
              onChange={handleForm}
            />
          </Grid>
          <Grid md={8}>
            <hr />
          </Grid>
          <Grid md={1}>
            <Typography variant="h6">Areas</Typography>
          </Grid>
          <Grid md={7}>
            <Typography variant="h6">Subjective Complaints</Typography>
          </Grid>
          <Grid md={1}>
            <Typography variant="subtitle1">Areas I:</Typography>
          </Grid>
          <Grid md={7}>
            <Stack direction="row" spacing={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.formInput.SCOccasion1 ? true : false}
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      props.setFormInput({
                        ...props.formInput,
                        SCOccasion1: value,
                      });
                    }}
                  />
                }
                label="Occasion"
                name="SCOccasion1"
                value={props.formInput.SCOccasion1}
                onChange={handleForm}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.formInput.SCFrequent1 ? true : false}
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      props.setFormInput({
                        ...props.formInput,
                        SCFrequent1: value,
                      });
                    }}
                  />
                }
                label="Frequent"
                name="SCFrequent1"
                value={props.formInput.SCFrequent1}
                onChange={handleForm}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.formInput.SCConstant1 ? true : false}
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      props.setFormInput({
                        ...props.formInput,
                        SCConstant1: value,
                      });
                    }}
                  />
                }
                label="Constant"
                name="SCConstant1"
                value={props.formInput.SCConstant1}
                onChange={handleForm}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.formInput.SCMild1 ? true : false}
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      props.setFormInput({
                        ...props.formInput,
                        SCMild1: value,
                      });
                    }}
                  />
                }
                label="Mild"
                name="SCMild1"
                value={props.formInput.SCMild1}
                onChange={handleForm}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.formInput.SCModerate1 ? true : false}
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      props.setFormInput({
                        ...props.formInput,
                        SCModerate1: value,
                      });
                    }}
                  />
                }
                label="Moderate"
                name="SCModerate1"
                value={props.formInput.SCModerate1}
                onChange={handleForm}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.formInput.SCSevere1 ? true : false}
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      props.setFormInput({
                        ...props.formInput,
                        SCSevere1: value,
                      });
                    }}
                  />
                }
                label="Severe"
                name="SCSevere1"
                value={props.formInput.SCSevere1}
                onChange={handleForm}
              />
              <TextField
                required
                fullWidth
                id="SCscale1"
                name="SCscale1"
                label="1=10"
                value={props.formInput.SCscale1}
                onChange={handleForm}
              ></TextField>
            </Stack>
          </Grid>
          <Grid md={1}>
            <Typography variant="subtitle1">Aggravated By</Typography>
          </Grid>
          <Grid md={7}>
            <Stack direction="row" spacing={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.formInput.SCSitting1 ? true : false}
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      props.setFormInput({
                        ...props.formInput,
                        SCSitting1: value,
                      });
                    }}
                  />
                }
                label="Sitting"
                name="SCSitting1"
                value={props.formInput.SCSitting1}
                onChange={handleForm}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.formInput.SCStanding1 ? true : false}
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      props.setFormInput({
                        ...props.formInput,
                        SCStanding1: value,
                      });
                    }}
                  />
                }
                label="Standing"
                name="SCStanding1"
                value={props.formInput.SCStanding1}
                onChange={handleForm}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.formInput.SCWalking1 ? true : false}
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      props.setFormInput({
                        ...props.formInput,
                        SCWalking1: value,
                      });
                    }}
                  />
                }
                label="Walking"
                name="SCWalking1"
                value={props.formInput.SCWalking1}
                onChange={handleForm}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.formInput.SCBending1 ? true : false}
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      props.setFormInput({
                        ...props.formInput,
                        SCBending1: value,
                      });
                    }}
                  />
                }
                label="Bending"
                name="SCBending1"
                value={props.formInput.SCBending1}
                onChange={handleForm}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.formInput.SCReaching1 ? true : false}
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      props.setFormInput({
                        ...props.formInput,
                        SCReaching1: value,
                      });
                    }}
                  />
                }
                label="Reaching"
                name="SCReaching1"
                value={props.formInput.SCReaching1}
                onChange={handleForm}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.formInput.SCGrasping1 ? true : false}
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      props.setFormInput({
                        ...props.formInput,
                        SCGrasping1: value,
                      });
                    }}
                  />
                }
                label="Grasping"
                name="SCGrasping1"
                value={props.formInput.SCGrasping1}
                onChange={handleForm}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.formInput.SCLifting1 ? true : false}
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      props.setFormInput({
                        ...props.formInput,
                        SCLifting1: value,
                      });
                    }}
                  />
                }
                label="Lifting"
                name="SCLifting1"
                value={props.formInput.SCLifting1}
                onChange={handleForm}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.formInput.SCCarrying1 ? true : false}
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      props.setFormInput({
                        ...props.formInput,
                        SCCarrying1: value,
                      });
                    }}
                  />
                }
                label="Carrying"
                name="SCCarrying1"
                value={props.formInput.SCCarrying1}
                onChange={handleForm}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.formInput.SCClimbing1 ? true : false}
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      props.setFormInput({
                        ...props.formInput,
                        SCClimbing1: value,
                      });
                    }}
                  />
                }
                label="Climbing"
                name="SCClimbing1"
                value={props.formInput.SCClimbing1}
                onChange={handleForm}
              />
            </Stack>
          </Grid>
          <Grid md={1}>
            <Typography variant="subtitle1">Relieved With</Typography>
          </Grid>
          <Grid md={7}>
            <Stack direction="row" spacing={1}>
              
            </Stack>
          </Grid>
          <Grid md={8}>
            <Typography variant="h6">Treatment Received</Typography>
          </Grid>
          <Grid md={8}>
            <Stack direction="row" spacing={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.formInput.PT ? true : false}
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      props.setFormInput({
                        ...props.formInput,
                        PT: value,
                      });
                    }}
                  />
                }
                label="PT"
                name="PT"
                value={props.formInput.PT}
                onChange={handleForm}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.formInput.OT ? true : false}
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      props.setFormInput({
                        ...props.formInput,
                        OT: value,
                      });
                    }}
                  />
                }
                label="OT"
                name="OT"
                value={props.formInput.OT}
                onChange={handleForm}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.formInput.DC ? true : false}
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      props.setFormInput({
                        ...props.formInput,
                        DC: value,
                      });
                    }}
                  />
                }
                label="DC"
                name="DC"
                value={props.formInput.PT}
                onChange={handleForm}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.formInput.ESI ? true : false}
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      props.setFormInput({
                        ...props.formInput,
                        ESI: value,
                      });
                    }}
                  />
                }
                label="ESI"
                name="ESI"
                value={props.formInput.ESI}
                onChange={handleForm}
              />
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
