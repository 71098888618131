import React, { useState, useEffect } from "react";
import axios from "axios";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export default function ClientPrices(props) {
  const [updatePriceId, setUpdatePriceId] = useState(0);
  const handleUpdate = (price) => {
    setUpdatePriceId(price.id);
    setInput({
      ...price,
    });
  };

  //Handle input update
  const [input, setInput] = useState({});
  const updateInput = (event) => {
    setInput({
      ...input,
      [event.target.name]: event.target.value,
      id: updatePriceId,
    });
  };

  const deleteURL = `${props.url}ThePortClinic-API/deletePrice.php`;
  const deletePrice = (id) => {
    if (
      confirm(
        "You are about to delete this special price, do you want to continue?"
      ) === true
    ) {
      axios
        .post(deleteURL, { id: id })
        .then((response) => {
          console.log(response.data);
          if (response.data.status === true) {
            alert("Success!");
            props.setDeleteStatus(props.deleteStatus + 1);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return;
    }
  };

  //Update service
  const updateURL = `${props.url}ThePortClinic-API/updatePrice.php`;
  const updatePrice = (apiURL) => {
    axios
      .post(apiURL, input)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === true) {
          alert("Success!");
        }
        setUpdatePriceId(0);
        props.setUpdateStatus(props.updateStatus + 1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (Array.isArray(props.prices)) {
    return props.prices.map((price) => {
      let service = { service: "No service..." };
      try {
        service = props.services.find(
          (item) => Number(item.id) === price.service_id
        );
      } catch (error) {
        console.log(error);
      }
      if (price.id === updatePriceId) {
        return (
          <TableRow>
            <TableCell>{service ? service.service : "No service"}</TableCell>
            <TableCell>
              <TextField
                fullWidth
                id="price"
                name="price"
                label="Price"
                onChange={updateInput}
                value={input.price}
              ></TextField>
            </TableCell>
            <TableCell>
              <Button
                variant="contained"
                onClick={() => {
                  updatePrice(updateURL);
                }}
              >
                <CheckCircleOutlineIcon />
              </Button>
            </TableCell>
          </TableRow>
        );
      } else {
        return (
          <TableRow>
            <TableCell>{service ? service.service : "No service"}</TableCell>
            <TableCell>${Number(price.special_price).toFixed(2)}</TableCell>
            <TableCell>
              <Button
                variant="contained"
                onClick={() => {
                  handleUpdate(price);
                }}
              >
                <EditIcon />
              </Button>
            </TableCell>
            <TableCell>
              <Button
                variant="contained"
                onClick={() => {
                  deletePrice(price.id);
                }}
              >
                <DeleteForeverIcon />
              </Button>
            </TableCell>
          </TableRow>
        );
      }
    });
  } else {
    return (
      <TableRow>
        <TableCell>No prices...</TableCell>
      </TableRow>
    );
  }
}
