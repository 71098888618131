import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { DataGrid, GridToolbar, GridFilterModel } from "@mui/x-data-grid";
import serviceList from "../utilities/serviceList";
import reportList from "../utilities/reportList";
import axios from "axios";

export default function Reports() {
    const Services = serviceList();
    const Reports = reportList();
    const [clients, setClients] = useState([]);
    const [service, setService] = useState('');
    const [company, setCompany] = useState('');
    const [showGrid, setShowGrid] = useState(false);
    const [filterModel, setFilterModel] = useState(
      {
        items: [
          { columnField: 'company', operatorValue: 'contains', value: company },
          { columnField: 'service', operatorValue: 'contains', value: service },
        ],
      }
    )

    useEffect(()=>{
      axios.get("https://localhost/ThePortClinic-API/getClients.php")
      .then((res)=>{
        res.data.forEach(element => {
          setClients(res.data);
        })  
      })
      .catch((error)=>{
        console.log(error);
      });
    },[]);
  
    const handleChangeService = (event) => {
      setService(event.target.value);
    };
    const handleChangeCompany = (event) => {
      setCompany(event.target.value);
    }
    const toggleGrid = ()=>{
      setShowGrid(true);
    }
  
    const serviceOptions = () => {
      return (
        Services.rows.map((item) => (
          <MenuItem value={item.id}>{item.service}</MenuItem>
        ))
      )
    }
    const companyOptions = () => {
        return (
          clients.map((item) => (
            <MenuItem value={item.id}>{item.company_name}</MenuItem>
          ))
        )
    }
  
    return (
      <Box>
        <Typography variant="h4">Reports</Typography>
        <Paper elevation={5} sx={{ p: 3, mt: 2 }}>
          <Grid container spacing={2} columns={8}>
            <Grid md={3}>
              <FormControl fullWidth>
                <InputLabel id="company-label">Company</InputLabel>
                <Select
                  labelId="company-label"
                  id="company"
                  value={company}
                  label="Company"
                  onChange={handleChangeCompany}
                >
                  {companyOptions()}
                </Select>
              </FormControl>
            </Grid>
            <Grid md={3}>
              <FormControl fullWidth>
                <InputLabel id="service-label">Service</InputLabel>
                <Select
                  labelId="service-label"
                  id="service"
                  value={service}
                  label="Service"
                  onChange={handleChangeService}
                >
                  {serviceOptions()}
                </Select>
              </FormControl>
            </Grid>
            <Grid md={2}>
              <Button size="large" variant="contained" onClick={toggleGrid}>Search</Button>
            </Grid>
            <Grid md={8}  sx={{ p: 3, height: 400 }}>
              {
                showGrid ?
                <DataGrid
                  rows={Reports.rows}
                  columns={Reports.columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  checkboxSelection
                  filterModel={filterModel}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                /> :
                null
              }
            </Grid>
          </Grid>
        </Paper>
      </Box>
    )
  }