import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../utilities/globalContext";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "axios";

export default function AuthorizationForm(props) {
  const { URL } = useContext(GlobalContext);
  const [service, setService] = useState("");
  const [insertStatus, setInsertStatus] = useState(0);
  const [updateStatus, setUpdateStatus] = useState(0);

  //Get services
  const [services, setServices] = useState([]);
  const getServicesURL = `${URL}ThePortClinic-API/getServices.php`;
  const getServices = (url) => {
    axios
      .get(url)
      .then((response) => {
        setServices(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Get client
  const [client, setClient] = useState({});
  const getClientURL = `${URL}ThePortClinic-API/getClient.php?id=${props.clientId}`;
  const getClient = (url) => {
    axios
      .get(url)
      .then((response) => {
        setClient(response.data);
        console.log(response.data);
        console.log(props.clientId);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Get client contacts
  const [contacts, setContacts] = useState([]);
  const getContactsURL = `${URL}ThePortClinic-API/getClientContacts.php?client_id=${props.clientId}`;
  const getContacts = (url) => {
    axios
      .get(url)
      .then((response) => {
        setContacts(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Get employee
  const [employee, setEmployee] = useState({});
  const getEmployeeURL = `${URL}ThePortClinic-API/getEmployee.php?id=${props.employeeId}`;
  const getClientEmployee = (url) => {
    axios
      .get(url)
      .then((response) => {
        setEmployee(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Insert authorization
  const insertAuthURL = `${URL}ThePortClinic-API/insertNewAuthorization.php`;
  const insertAuth = (data, url) => {
    axios
      .post(url, data)
      .then((response) => {
        if (response.data.status === true) {
          props.setAuthId(response.data.id);
          setInsertStatus(insertStatus + 1);
          alert("Success!");
        } else {
          alert("Error saving authorization");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Generate select options
  const selectServiceOptions = () => {
    return services.map((item) => (
      <MenuItem value={item.id}>{item.service}</MenuItem>
    ));
  };

  const selectContactOptions = () => {
    if(Array.isArray(contacts)){
      return contacts.map((item) => (
        <MenuItem value={item.id}>{item.contact_name}</MenuItem>
      ))
    }
    return <MenuItem value="0">No contacts...</MenuItem>
  };

  //Handle input
  const handleChange = (event) => {
    props.setAuthInput({
      ...props.authInput,
      [event.target.name]: event.target.value,
      services: props.selectedServices,
      date_authorized: props.authDate,
      status: 1,
      auth_id: props.authId,
      company_id: props.clientId,
      employee_id: props.employeeId,
    });
    console.log(props.authInput);
  };

  const handleServiceChange = (event) => {
    setService(event.target.value);
  };

  const handleSelectedServices = () => {
    services.map((item) => {
      if (item.id === service && service !== "") {
        props.setSelectedServices([
          ...props.selectedServices,
          { id: item.id, service: item.service },
        ]);
        console.log(props.selectedServices);
      }
    });
  };

  const handleAuthForms = (event) => {
    props.setAuthForms({
      ...props.authForms,
      [event.target.name]: event.target.checked,
    });
  };

  const deleteService = (index) => {
    const newServices = [...props.selectedServices];
    newServices.splice(index, 1);
    props.setSelectedServices(newServices);
  };

  const showSelectedServices = () => {
    return props.selectedServices.map((item, index) => (
      <Chip
        label={item.service}
        onDelete={() => {
          deleteService(index);
        }}
        variant="outlined"
      />
    ));
  };

  useEffect(() => {
    getServices(getServicesURL);
    getClient(getClientURL);
    getContacts(getContactsURL);
    getClientEmployee(getEmployeeURL);
  }, [props.clientId]);

  return (
    <Box component="form">
      <Paper elevation={5} sx={{ p: 3, mt: 2 }}>
        <Grid container columns={8} spacing={2}>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="insurance_claim"
              name="insurance_claim"
              label="Insurance Claim"
              value={props.authInput.insurance_claim}
              onChange={handleChange}
            ></TextField>
          </Grid>
          <Grid md={4}>
            <FormControl fullWidth>
              <InputLabel id="companyContact">Company Contact</InputLabel>
              <Select
                labelId="companyContact"
                id="company_contact"
                name="company_contact"
                label="Contact Type"
                value={props.authInput.company_contact}
                onChange={handleChange}
              >
                {selectContactOptions()}
              </Select>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="first_name"
              name="first_name"
              label="First Name"
              value={employee.first_name}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="middle_name"
              name="middle_name"
              label="Middle Name"
              value={employee.middle_name}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="last_name"
              name="last_name"
              label="Last Name"
              value={employee.last_name}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="ssn"
              name="ssn"
              label="Social Security Number"
              value={employee.ssn}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Date of birth"
                value={employee.dob}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={employee.email}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="phone"
              name="phone"
              label="Phone"
              value={employee.phone}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Grid>
          <Grid md={6}>
            <TextField
              required
              fullWidth
              id="employer"
              label="Employer"
              value={client.company_name}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Grid>
          <Grid md={6}>
            <TextField
              required
              fullWidth
              id="address"
              name="address"
              label="Address"
              value={employee.address}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="location_number"
              name="location_number"
              label="Location Number"
              value={props.authInput.location_number}
              onChange={handleChange}
            ></TextField>
          </Grid>
          <Grid md={8}>
            <hr></hr>
          </Grid>
          <Grid md={8}>
            <Typography variant="subtitle">Add service forms</Typography>
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.authForms.DOTPhysical ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    props.setAuthForms({
                      ...props.authForms,
                      DOTPhysical: value,
                    });
                  }}
                />
              }
              label="DOT Physical"
              name="DOTPhysical"
              value={props.authForms.DOTPhysical}
              onChange={handleAuthForms}
            />
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  check={props.authForms.NonDOTPhysical ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    props.setAuthForms({
                      ...props.authForms,
                      NonDOTPhysical: value,
                    });
                  }}
                />
              }
              label="Non-DOT Physical"
              name="NonDOTPhysical"
              value={props.authForms.NonDOTPhysical}
              onChange={handleAuthForms}
            />
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  check={props.authForms.WorkersComp ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    props.setAuthForms({
                      ...props.authForms,
                      WorkersComp: value,
                    });
                  }}
                />
              }
              label="Workers Comp"
              name="WorkersComp"
              value={props.authForms.WorkersComp}
              onChange={handleAuthForms}
            />
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  check={props.authForms.RapidDrugScreen5 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    props.setAuthForms({
                      ...props.authForms,
                      RapidDrugScreen5: value,
                    });
                  }}
                />
              }
              label="Rapid Drug Screen (5 panel)"
              name="RapidDrugScreen5"
              value={props.authForms.RapidDrugScreen5}
              onChange={handleAuthForms}
            />
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  check={props.authForms.RapidDrugScreen10 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    props.setAuthForms({
                      ...props.authForms,
                      RapidDrugScreen10: value,
                    });
                  }}
                />
              }
              label="Rapid Drug Screen (10 panel)"
              name="RapidDrugScreen10"
              value={props.authForms.RapidDrugScreen10}
              onChange={handleAuthForms}
            />
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  check={props.authForms.RapidDrugScreen12 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    props.setAuthForms({
                      ...props.authForms,
                      RapidDrugScreen12: value,
                    });
                  }}
                />
              }
              label="Rapid Drug Screen (12 panel)"
              name="RapidDrugScreen12"
              value={props.authForms.RapidDrugScreen12}
              onChange={handleAuthForms}
            />
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  check={props.authForms.RapidDrugScreen16 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    props.setAuthForms({
                      ...props.authForms,
                      RapidDrugScreen16: value,
                    });
                  }}
                />
              }
              label="Rapid Drug Screen (16 panel)"
              name="RapidDrugScreen16"
              value={props.authForms.RapidDrugScreen16}
              onChange={handleAuthForms}
            />
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  check={props.authForms.InjectionConsent ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    props.setAuthForms({
                      ...props.authForms,
                      InjectionConsent: value,
                    });
                  }}
                />
              }
              label="Injection Consent Form"
              name="InjectionConsent"
              value={props.authForms.InjectionConsent}
              onChange={handleAuthForms}
            />
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  check={props.authForms.ReleaseMedInf ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    props.setAuthForms({
                      ...props.authForms,
                      ReleaseMedInf: value,
                    });
                  }}
                />
              }
              label="Authorization to Release"
              name="ReleaseMedInf"
              value={props.authForms.ReleaseMedInf}
              onChange={handleAuthForms}
            />
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  check={props.authForms.Tuberculosis ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    props.setAuthForms({
                      ...props.authForms,
                      Tuberculosis: value,
                    });
                  }}
                />
              }
              label="Tuberculosis Exam Form"
              name="Tuberculosis"
              value={props.authForms.Tuberculosis}
              onChange={handleAuthForms}
            />
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  check={props.authForms.Audiogram ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    props.setAuthForms({
                      ...props.authForms,
                      Audiogram: value,
                    });
                  }}
                />
              }
              label="Audiogram"
              name="Audiogram"
              value={props.authForms.Audiogram}
              onChange={handleAuthForms}
            />
          </Grid>
          <Grid md={8}>
            <hr></hr>
          </Grid>
          <Grid md={8}>
            <Typography variant="subtitle">Services</Typography>
          </Grid>
          <Grid md={6}>
            <FormControl fullWidth>
              <InputLabel id="service-label">Select a service...</InputLabel>
              <Select
                labelId="service-label"
                id="service"
                value={service}
                label="Service"
                onChange={handleServiceChange}
              >
                {selectServiceOptions()}
              </Select>
            </FormControl>
          </Grid>
          <Grid md={2}>
            <Button
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={handleSelectedServices}
            >
              Add Service
            </Button>
          </Grid>
          <Grid md={8}>
            <Typography variant="subtitle1">Selected Services</Typography>
          </Grid>
          <Grid md={4}>
            <Stack spacing={2}>
              {props.selectedServices.length !== 0 ? (
                showSelectedServices()
              ) : (
                <Typography variant="subtitle1">No services...</Typography>
              )}
            </Stack>
          </Grid>
          <Grid md={8}>
            <hr></hr>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="authorized_by"
              name="authorized_by"
              label="Authorized By"
              value={props.authInput.authorized_by}
              onChange={handleChange}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="title"
              name="title"
              label="Title"
              value={props.authInput.title}
              onChange={handleChange}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="phone"
              name="phone"
              label="Phone"
              value={props.authInput.phone}
              onChange={handleChange}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Date authorized"
                name="date_authorized"
                value={props.authDate}
                onChange={(newDate) => {
                  props.setAuthDate(newDate);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={2}>
            <FormControl fullWidth>
              <InputLabel id="billingType">Billing Type</InputLabel>
              <Select
                labelId="billingType"
                id="billing_type"
                name="billing_type"
                label="Billing Type"
                value={props.authInput.billing_type}
                onChange={handleChange}
              >
                <MenuItem value="Bill Company">Bill Company</MenuItem>
                <MenuItem value="Bill Insurance">Bill Insurance</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="certifying_doctor"
              name="certifying_doctor"
              label="Certifying Doctor"
              value={props.authInput.certifying_doctor}
              onChange={handleChange}
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="treating_doctor"
              name="treating_doctor"
              label="Treating Doctor"
              value={props.authInput.treating_doctor}
              onChange={handleChange}
            ></TextField>
          </Grid>
          <Grid md={8}>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={6}
              id="notes"
              name="notes"
              placeholder="Notes..."
              value={props.authInput.notes}
              onChange={handleChange}
              style={{ width: 400 }}
            />
          </Grid>
          <Grid md={8}>
            {props.authId === "" ? (
              <Button
                variant="contained"
                onClick={() => {
                  insertAuth(props.authInput, insertAuthURL);
                }}
              >
                Save Authorization
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  updateAuth(props.authInput, updateAuthURL);
                }}
                disabled
              >
                Save Authorization
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
