import React, { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../utilities/globalContext";
import axios from "axios";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { setFormValues } from "../../utilities/Forms/formFunctions";
import insertForm from "../../utilities/Queries/insertNewForm";

const style = {
  width: "100%",
};

export default function RapidDrugScreen(props) {
  //Context
  const { URL } = useContext(GlobalContext);
  //Form related states
  const [formInput, setFormInput] = useState({});
  const [collectionTestDate, setCollectionTestDate] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [signatureDate1, setSignatureDate1] = useState("");
  const [signatureDate2, setSignatureDate2] = useState("");
  const [formData, setFormData] = useState({
    employee_id: props.employeeId,
    client_id: props.clientId,
    authorization_id: props.authId,
    form_name: `RapidDrugScreen${props.panels}`,
  });

  const handleChange = (event) => {
    setFormValues(formInput, setFormInput, formData, setFormData, event);
  };

  const handleInsert = () => {
    insertForm(formData, URL);
  };

  return (
    <Box>
      <Paper elevation={5} sx={{ p: 3, mt: 2 }}>
        <Grid container spacing={2} columns={9}>
          <Grid md={7}></Grid>
          <Grid md={2}>
            <Stack spacing={1}>
              <TextField
                required
                fullWidth
                id="specimenIdNumber"
                name="specimenIdNumber"
                onChange={handleChange}
                value={
                  props.loadData
                    ? props.formInput.specimenIdNumber
                    : formInput.specimenIdNumber
                }
                label="Specimen Id Number"
              ></TextField>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  id="collectionTestDate"
                  name="collectionTestDate"
                  label="Date sent"
                  inputFormat="DD/MM/YYYY"
                  onChange={(newDate) => {
                    setCollectionTestDate(newDate);
                    setFormInput({ ...formInput, collectionTestDate: newDate });
                    handleChange;
                  }}
                  value={
                    props.loadData
                      ? props.formInput.collectionTestDate
                      : collectionTestDate
                  }
                  renderInput={(params) => <TextField {...params} required />}
                />
              </LocalizationProvider>
            </Stack>
          </Grid>
          <Grid md={9}>
            <Typography variant="h6">Collector's Name:</Typography>
          </Grid>
          <Grid md={9}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby=""
                name="collectorName"
                onChange={handleChange}
                value={
                  props.loadData
                    ? props.formInput.collectorName
                    : formInput.collectorName
                }
              >
                <FormControlLabel
                  value="Letecia Rey, NRCMA"
                  control={<Radio />}
                  label="Letecia Rey, NRCMA"
                />
                <FormControlLabel
                  value="Lupe Benabe, Radiology Tech"
                  control={<Radio />}
                  label="Lupe Benabe, Radiology Tech"
                />
                <FormControlLabel
                  value="Amanda Saldivar, Radilogy Tech"
                  control={<Radio />}
                  label="Amanda Saldivar, Radilogy Tech"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={9}>
            <Typography variant="h6">
              Donnor Informaton (Person being tested)
            </Typography>
          </Grid>
          <Grid md={9}>
            <TextField
              required
              fullWidth
              id="donorName"
              name="donorName"
              onChange={handleChange}
              value={
                props.loadData ? props.formInput.donorName : formInput.donorName
              }
              label="Donor's Name"
            ></TextField>
          </Grid>
          <Grid md={9}>
            <TextField
              required
              fullWidth
              id="idSSN"
              name="idSSN"
              onChange={handleChange}
              value={props.loadData ? props.formInput.idSSN : formInput.idSSN}
              label="ID# or SSN"
            ></TextField>
          </Grid>
          <Grid md={7}>
            <TextField
              required
              fullWidth
              id="identificationType"
              name="identificationType"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.identificationType
                  : formInput.identificationType
              }
              label="Identification Type"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="expirationDate"
                name="expirationDate"
                label="Expiration"
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setExpirationDate(newDate);
                  setFormInput({ ...formInput, expirationDate: newDate });
                  handleChange;
                }}
                value={
                  props.loadData
                    ? props.formInput.expirationDate
                    : expirationDate
                }
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={9}>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={8}
              placeholder="Notes..."
              style={style}
              id="notes"
              name="notes"
              onChange={handleChange}
              value={props.loadData ? props.formInput.notes : formInput.notes}
            />
          </Grid>
          <Grid md={9}>
            <Typography variant="h6">
              Certification Information: (Must be signed by BOTH Donor and
              Collector)
            </Typography>
          </Grid>
          <Grid md={9}>
            <Typography
              variant="subtitle1"
              style={{ color: "red", textDecoration: "underline" }}
            >
              I (Donor) hereby certify that the specimen provided is my own and
              has not been substituted or adulterated. I further agree and grant
              permission for the testing of my specimen for drug and metabolites
              and/or alcohol.
            </Typography>
          </Grid>
          <Grid md={7}>
            <TextField
              required
              fullWidth
              id="donorSignature"
              name="donorSignature"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.donorSignature
                  : formInput.donorSignature
              }
              label="Donor's Signature"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="signatureDate1"
                name="signatureDate1"
                label="Date"
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setSignatureDate1(newDate);
                  setFormInput({ ...formInput, signatureDate1: newDate });
                  handleChange;
                }}
                value={
                  props.loadData
                    ? props.formInput.signatureDate1
                    : signatureDate1
                }
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={9}>
            <Typography
              variant="subtitle1"
              style={{ textDecoration: "underline" }}
            >
              I (Collector) hereby certify that I collected the specimen
              provided by the aforementioned-donor and that, to the best of my
              knowledge, it was not substituted or adulterated. The specimen
              temperature and color were acceptable.
            </Typography>
          </Grid>
          <Grid md={7}>
            <TextField
              required
              fullWidth
              id="collectorSignature"
              name="collectorSignature"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.collectorSignature
                  : formInput.collectorSignature
              }
              label="Collector's Signature"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="signatureDate2"
                name="signatureDate2"
                label="Date"
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setSignatureDate2(newDate);
                  setFormInput({ ...formInput, signatureDate2: newDate });
                  handleChange;
                }}
                value={
                  props.loadData
                    ? props.formInput.signatureDate2
                    : signatureDate2
                }
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={2}>
            <Typography
              variant="subtitle1"
              style={{ textDecoration: "underline" }}
            >
              Drug Name
            </Typography>
          </Grid>
          <Grid md={2}>
            <Typography
              variant="subtitle1"
              style={{ textDecoration: "underline" }}
            >
              Device Code
            </Typography>
          </Grid>
          <Grid md={2}></Grid>
          <Grid md={3}>
            <Stack>
              <Typography
                variant="subtitle1"
                style={{ textDecoration: "underline" }}
              >
                Specimen Temperature
              </Typography>
              <Typography variant="subtitle1">(90° - 100°)</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formInput.range ? true : false}
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      setFormInput({
                        ...formInput,
                        range: value,
                      });
                    }}
                  />
                }
                label="In Range"
                name="range"
                value={props.loadData ? props.formInput.range : formInput.range}
                onChange={handleChange}
              />
            </Stack>
          </Grid>
          {(props.panels == "5" ||
            props.panels == "10" ||
            props.panels == "12" ||
            props.panels == "16") && (
            <>
              <Grid md={2}>
                <Typography variant="subtitle1">1. Cocaine</Typography>
              </Grid>
              <Grid md={2}>
                <Typography variant="subtitle1">COC</Typography>
              </Grid>
              <Grid md={2}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby=""
                    name="drugScreen1"
                    onChange={handleChange}
                    value={
                      props.loadData
                        ? props.formInput.drugScreen1
                        : formInput.drugScreen1
                    }
                  >
                    <FormControlLabel
                      value="Non-negative"
                      control={<Radio />}
                      label="Non-negative"
                    />
                    <FormControlLabel
                      value="Positive"
                      control={<Radio />}
                      label="Positive"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid md={3}></Grid>
            </>
          )}
          {(props.panels == "5" ||
            props.panels == "10" ||
            props.panels == "12" ||
            props.panels == "16") && (
            <>
              <Grid md={2}>
                <Typography variant="subtitle1">2. Marijuana</Typography>
              </Grid>
              <Grid md={2}>
                <Typography variant="subtitle1">THC</Typography>
              </Grid>
              <Grid md={2}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby=""
                    name="drugScreen2"
                    onChange={handleChange}
                    value={
                      props.loadData
                        ? props.formInput.drugScreen2
                        : formInput.drugScreen2
                    }
                  >
                    <FormControlLabel
                      value="Non-negative"
                      control={<Radio />}
                      label="Non-negative"
                    />
                    <FormControlLabel
                      value="Positive"
                      control={<Radio />}
                      label="Positive"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid md={3}></Grid>
            </>
          )}
          {(props.panels == "5" ||
            props.panels == "10" ||
            props.panels == "12" ||
            props.panels == "16") && (
            <>
              <Grid md={2}>
                <Typography variant="subtitle1">3. Opiates/Morphine</Typography>
              </Grid>
              <Grid md={2}>
                <Typography variant="subtitle1">OPI/MOR</Typography>
              </Grid>
              <Grid md={2}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby=""
                    name="drugScreen3"
                    onChange={handleChange}
                    value={
                      props.loadData
                        ? props.formInput.drugScreen3
                        : formInput.drugScreen3
                    }
                  >
                    <FormControlLabel
                      value="Non-negative"
                      control={<Radio />}
                      label="Non-negative"
                    />
                    <FormControlLabel
                      value="Positive"
                      control={<Radio />}
                      label="Positive"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid md={3}></Grid>
            </>
          )}
          {(props.panels == "5" ||
            props.panels == "10" ||
            props.panels == "12" ||
            props.panels == "16") && (
            <>
              <Grid md={2}>
                <Typography variant="subtitle1">4. Amphetamines</Typography>
              </Grid>
              <Grid md={2}>
                <Typography variant="subtitle1">AMP</Typography>
              </Grid>
              <Grid md={2}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby=""
                    name="drugScreen4"
                    onChange={handleChange}
                    value={
                      props.loadData
                        ? props.formInput.drugScreen4
                        : formInput.drugScreen4
                    }
                  >
                    <FormControlLabel
                      value="Non-negative"
                      control={<Radio />}
                      label="Non-negative"
                    />
                    <FormControlLabel
                      value="Positive"
                      control={<Radio />}
                      label="Positive"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid md={3}></Grid>
            </>
          )}
          {(props.panels == "10" || props.panels == "16") && (
            <>
              <Grid md={2}>
                <Typography variant="subtitle1">5. Phencyclidine</Typography>
              </Grid>
              <Grid md={2}>
                <Typography variant="subtitle1">PCP</Typography>
              </Grid>
              <Grid md={2}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby=""
                    name="drugScreen5"
                    onChange={handleChange}
                    value={
                      props.loadData
                        ? props.formInput.drugScreen5
                        : formInput.drugScreen5
                    }
                  >
                    <FormControlLabel
                      value="Non-negative"
                      control={<Radio />}
                      label="Non-negative"
                    />
                    <FormControlLabel
                      value="Positive"
                      control={<Radio />}
                      label="Positive"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid md={3}></Grid>
            </>
          )}
          {(props.panels == "10" ||
            props.panels == "12" ||
            props.panels == "16") && (
            <>
              <Grid md={2}>
                <Typography variant="subtitle1">6. Methamphetamine</Typography>
              </Grid>
              <Grid md={2}>
                <Typography variant="subtitle1">mAMP</Typography>
              </Grid>
              <Grid md={2}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby=""
                    name="drugScreen6"
                    onChange={handleChange}
                    value={
                      props.loadData
                        ? props.formInput.drugScreen6
                        : formInput.drugScreen6
                    }
                  >
                    <FormControlLabel
                      value="Non-negative"
                      control={<Radio />}
                      label="Non-negative"
                    />
                    <FormControlLabel
                      value="Positive"
                      control={<Radio />}
                      label="Positive"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid md={3}></Grid>
            </>
          )}
          {(props.panels == "5" ||
            props.panels == "10" ||
            props.panels == "12" ||
            props.panels == "16") && (
            <>
              <Grid md={2}>
                <Typography variant="subtitle1">7. Benzodiazepine</Typography>
              </Grid>
              <Grid md={2}>
                <Typography variant="subtitle1">BZO</Typography>
              </Grid>
              <Grid md={2}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby=""
                    name="drugScreen7"
                    onChange={handleChange}
                    value={
                      props.loadData
                        ? props.formInput.drugScreen7
                        : formInput.drugScreen7
                    }
                  >
                    <FormControlLabel
                      value="Non-negative"
                      control={<Radio />}
                      label="Non-negative"
                    />
                    <FormControlLabel
                      value="Positive"
                      control={<Radio />}
                      label="Positive"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid md={3}></Grid>
            </>
          )}
          {(props.panels == "10" ||
            props.panels == "12" ||
            props.panels == "16") && (
            <>
              <Grid md={2}>
                <Typography variant="subtitle1">8. Barbiturates</Typography>
              </Grid>
              <Grid md={2}>
                <Typography variant="subtitle1">BAR</Typography>
              </Grid>
              <Grid md={2}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby=""
                    name="drugScreen8"
                    onChange={handleChange}
                    value={
                      props.loadData
                        ? props.formInput.drugScreen8
                        : formInput.drugScreen8
                    }
                  >
                    <FormControlLabel
                      value="Non-negative"
                      control={<Radio />}
                      label="Non-negative"
                    />
                    <FormControlLabel
                      value="Positive"
                      control={<Radio />}
                      label="Positive"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid md={3}></Grid>
            </>
          )}
          {(props.panels == "10" ||
            props.panels == "12" ||
            props.panels == "16") && (
            <>
              <Grid md={2}>
                <Typography variant="subtitle1">9. Methadone</Typography>
              </Grid>
              <Grid md={2}>
                <Typography variant="subtitle1">MTD</Typography>
              </Grid>
              <Grid md={2}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby=""
                    name="drugScreen9"
                    onChange={handleChange}
                    value={
                      props.loadData
                        ? props.formInput.drugScreen9
                        : formInput.drugScreen9
                    }
                  >
                    <FormControlLabel
                      value="Non-negative"
                      control={<Radio />}
                      label="Non-negative"
                    />
                    <FormControlLabel
                      value="Positive"
                      control={<Radio />}
                      label="Positive"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid md={3}></Grid>
            </>
          )}
          {(props.panels == "10" ||
            props.panels == "12" ||
            props.panels == "16") && (
            <>
              <Grid md={2}>
                <Typography variant="subtitle1">10. Oxycodone</Typography>
              </Grid>
              <Grid md={2}>
                <Typography variant="subtitle1">OXY</Typography>
              </Grid>
              <Grid md={2}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby=""
                    name="drugScreen10"
                    onChange={handleChange}
                    value={
                      props.loadData
                        ? props.formInput.drugScreen10
                        : formInput.drugScreen10
                    }
                  >
                    <FormControlLabel
                      value="Non-negative"
                      control={<Radio />}
                      label="Non-negative"
                    />
                    <FormControlLabel
                      value="Positive"
                      control={<Radio />}
                      label="Positive"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid md={3}></Grid>
            </>
          )}
          {props.panels == "16" && (
            <>
              <Grid md={2}>
                <Typography variant="subtitle1">11. Fentanyl</Typography>
              </Grid>
              <Grid md={2}>
                <Typography variant="subtitle1">FEN</Typography>
              </Grid>
              <Grid md={2}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby=""
                    name="drugScreen11"
                    onChange={handleChange}
                    value={
                      props.loadData
                        ? props.formInput.drugScreen11
                        : formInput.drugScreen11
                    }
                  >
                    <FormControlLabel
                      value="Non-negative"
                      control={<Radio />}
                      label="Non-negative"
                    />
                    <FormControlLabel
                      value="Positive"
                      control={<Radio />}
                      label="Positive"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid md={3}></Grid>
            </>
          )}
          {props.panels == "16" && (
            <>
              <Grid md={2}>
                <Typography variant="subtitle1">12. Tramadol</Typography>
              </Grid>
              <Grid md={2}>
                <Typography variant="subtitle1">TRA</Typography>
              </Grid>
              <Grid md={2}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby=""
                    name="drugScreen12"
                    onChange={handleChange}
                    value={
                      props.loadData
                        ? props.formInput.drugScreen12
                        : formInput.drugScreen12
                    }
                  >
                    <FormControlLabel
                      value="Non-negative"
                      control={<Radio />}
                      label="Non-negative"
                    />
                    <FormControlLabel
                      value="Positive"
                      control={<Radio />}
                      label="Positive"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid md={3}></Grid>
            </>
          )}
          {props.panels == "16" && (
            <>
              <Grid md={2}>
                <Typography variant="subtitle1">
                  13. Synthetic Marijuana
                </Typography>
              </Grid>
              <Grid md={2}>
                <Typography variant="subtitle1">K2</Typography>
              </Grid>
              <Grid md={2}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby=""
                    name="drugScreen13"
                    onChange={handleChange}
                    value={
                      props.loadData
                        ? props.formInput.drugScreen13
                        : formInput.drugScreen13
                    }
                  >
                    <FormControlLabel
                      value="Non-negative"
                      control={<Radio />}
                      label="Non-negative"
                    />
                    <FormControlLabel
                      value="Positive"
                      control={<Radio />}
                      label="Positive"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid md={3}></Grid>
            </>
          )}
          {(props.panels == "12" || props.panels == "16") && (
            <>
              <Grid md={2}>
                <Typography variant="subtitle1">14. MDMA</Typography>
              </Grid>
              <Grid md={2}>
                <Typography variant="subtitle1">ECSTASY</Typography>
              </Grid>
              <Grid md={2}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby=""
                    name="drugScreen14"
                    onChange={handleChange}
                    value={
                      props.loadData
                        ? props.formInput.drugScreen14
                        : formInput.drugScreen14
                    }
                  >
                    <FormControlLabel
                      value="Non-negative"
                      control={<Radio />}
                      label="Non-negative"
                    />
                    <FormControlLabel
                      value="Positive"
                      control={<Radio />}
                      label="Positive"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid md={3}></Grid>
            </>
          )}
          {(props.panels == "12" || props.panels == "16") && (
            <>
              <Grid md={2}>
                <Typography variant="subtitle1">15. Buprenorphine</Typography>
              </Grid>
              <Grid md={2}>
                <Typography variant="subtitle1">BUP</Typography>
              </Grid>
              <Grid md={2}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby=""
                    name="drugScreen15"
                    onChange={handleChange}
                    value={
                      props.loadData
                        ? props.formInput.drugScreen15
                        : formInput.drugScreen15
                    }
                  >
                    <FormControlLabel
                      value="Non-negative"
                      control={<Radio />}
                      label="Non-negative"
                    />
                    <FormControlLabel
                      value="Positive"
                      control={<Radio />}
                      label="Positive"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid md={3}></Grid>
            </>
          )}
          {props.panels == "16" && (
            <>
              <Grid md={2}>
                <Typography variant="subtitle1">
                  16. Ethyl Glucuronide
                </Typography>
              </Grid>
              <Grid md={2}>
                <Typography variant="subtitle1">ETG/ALCOHOL</Typography>
              </Grid>
              <Grid md={2}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby=""
                    name="drugScreen16"
                    onChange={handleChange}
                    value={
                      props.loadData
                        ? props.formInput.drugScreen16
                        : formInput.drugScreen16
                    }
                  >
                    <FormControlLabel
                      value="Non-negative"
                      control={<Radio />}
                      label="Non-negative"
                    />
                    <FormControlLabel
                      value="Positive"
                      control={<Radio />}
                      label="Positive"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid md={3}></Grid>
            </>
          )}
          {props.panels == "12" && (
            <>
              <Grid md={2}>
                <Typography variant="subtitle1">
                  17. Tricyclic Antidepressants
                </Typography>
              </Grid>
              <Grid md={2}>
                <Typography variant="subtitle1">TCA</Typography>
              </Grid>
              <Grid md={2}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby=""
                    name="drugScreen17"
                    onChange={handleChange}
                    value={
                      props.loadData
                        ? props.formInput.drugScreen17
                        : formInput.drugScreen17
                    }
                  >
                    <FormControlLabel
                      value="Non-negative"
                      control={<Radio />}
                      label="Non-negative"
                    />
                    <FormControlLabel
                      value="Positive"
                      control={<Radio />}
                      label="Positive"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid md={3}></Grid>
            </>
          )}
          <Grid md={5}>
            <Button variant="contained" onClick={handleInsert}>
              Send
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
