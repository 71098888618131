import React, { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../utilities/globalContext";
import axios from "axios";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { setFormValues } from "../../utilities/Forms/formFunctions";
import insertForm from "../../utilities/Queries/insertNewForm";

export default function ReleaseMedInf(props) {
  //Context
  const { URL } = useContext(GlobalContext);
  //Form related states
  const [formInput, setFormInput] = useState({});
  const [dob, setDob] = useState("");
  const [treatmentDate1, setTreatmentDate1] = useState("");
  const [treatmentDate2, setTreatmentDate2] = useState("");
  const [date, setDate] = useState("");
  const [requestDate, setRequestDate] = useState("");
  const [recievedDate, setRecievedDate] = useState("");
  const [formData, setFormData] = useState({
    employee_id: props.employeeId,
    client_id: props.clientId,
    authorization_id: props.authId,
    form_name: "ReleaseMedInf",
  });

  const handleChange = (event) => {
    setFormValues(formInput, setFormInput, formData, setFormData, event);
  };

  const handleInsert = () => {
    insertForm(formData, URL);
  };

  return (
    <Box>
      <Paper elevation={5} sx={{ p: 3, mt: 2 }}>
        <Grid container spacing={2} columns={9}>
          <Typography variant="h5">
            Authorization to Release Medical Information
          </Typography>
          <Grid md={9}>
            <hr></hr>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="fullName"
              name="fullName"
              onChange={handleChange}
              value={
                props.loadData ? props.formInput.fullName : formInput.fullName
              }
              label="Patient's Full Name"
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="SSN"
              name="SSN"
              onChange={handleChange}
              value={props.loadData ? props.formInput.SSN : formInput.SSN}
              label="Patient's Social Security Number"
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="homeAddress"
              name="homeAddress"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.homeAddress
                  : formInput.homeAddress
              }
              label="Home Address"
            ></TextField>
          </Grid>
          <Grid md={3}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="dob"
                name="dob"
                label="Patient's Date of Birth"
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setDob(newDate);
                  setFormInput({ ...formInput, dob: newDate });
                  handleChange;
                }}
                value={props.loadData ? props.formInput.dob : dob}
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="cityStateZip1"
              name="cityStateZip1"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.cityStateZip1
                  : formInput.cityStateZip1
              }
              label="City, State, ZIP code"
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="phoneNumber1"
              name="phoneNumber1"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.phoneNumber
                  : formInput.phoneNumber
              }
              label="Patient's Telephone Number"
            ></TextField>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              <b>
                I hereby authorize the use of disclosure of protected health
                information about me as described below
              </b>
            </Typography>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle2">
              <b>1.</b> The following disclosing party is authorized to disclose
              protected health information about me:
            </Typography>
          </Grid>
          <Grid md={8}>
            <TextField
              required
              fullWidth
              id="previousFacilityDoctor"
              name="previousFacilityDoctor"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.previousFacilityDoctor
                  : formInput.previousFacilityDoctor
              }
              label="Name of Previous Treatment Facility or Doctor"
            ></TextField>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle2">
              <b>2.</b> The following party may recieve protected health
              information about me from the disclosing party:
            </Typography>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              <b>The Port Clinic</b>
            </Typography>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="address"
              name="address"
              onChange={handleChange}
              value={
                props.loadData ? props.formInput.address : formInput.address
              }
              label="Address"
            ></TextField>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="phoneNumber2"
              name="phoneNumber2"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.phoneNumber2
                  : formInput.phoneNumber2
              }
              label="Phone Number"
            ></TextField>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="cityStateZip2"
              name="cityStateZip2"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.cityStateZip2
                  : formInput.cityStateZip2
              }
              label="City, State, Zip Code"
            ></TextField>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="faxNumber"
              name="faxNumber"
              onChange={handleChange}
              value={
                props.loadData ? props.formInput.faxNumber : formInput.faxNumber
              }
              label="Fax Number"
            ></TextField>
          </Grid>
          <Grid md={5}>
            <Typography variant="subtitle2">
              <b>3.</b> The Specific informationthat should be disclosed: My
              entire medical record for treatment dates ___ to ___.
            </Typography>
          </Grid>
          <Grid md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="treatmentDate1"
                name="treatmentDate1"
                label="From..."
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setTreatmentDate1(newDate);
                  setFormInput({ ...formInput, treatmentDate1: newDate });
                  handleChange;
                }}
                value={
                  props.loadData
                    ? props.formInput.treatmentDate1
                    : treatmentDate1
                }
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="treatmentDate2"
                name="treatmentDate2"
                label="To..."
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setTreatmentDate2(newDate);
                  setFormInput({ ...formInput, treatmentDate2: newDate });
                  handleChange;
                }}
                value={
                  props.loadData
                    ? props.formInput.treatmentDate2
                    : treatmentDate2
                }
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle2">
              <b>4.</b> I Understand that my express consent is required to
              release any protected health information relating to testing,
              diagnosis, and/or treatment for HIV (AIDS Virus), sexually
              transmitted diseases, psychiatric disorders, mental health issues,
              or drug and/or alcohol use. If I have been tested diagnosed, or
              treated for HIV (AIDS virus), sexually transmitted diseases,
              psychiatric disorders, mental health issues, or drug and/or
              alcohol use, you are specifically authorized to release all health
              information relating to such diagnosis, testing or treatment.
            </Typography>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle2">
              <b>5.</b> The Purpose of the requested information is:{" "}
              <u>
                For continuity of care by my current treatment providers at The
                Port Clinic.
              </u>
            </Typography>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle2">
              <b>6.</b> I understand the information disclosed may be subject to
              re-disclosure by the noted recipient, at which time it may no
              longer be protected by federal privacy regulations.
            </Typography>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle2">
              <b>7.</b> I understand that I have the right to revoke this
              authorization at any given time by giving written notice to the
              disclosing party. I understand the revocation will not apply to
              information that has already been disclosed in response to this
              authorization.
            </Typography>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle2">
              <b>8.</b> The Port Clinic will not condition treatment on this
              authorization.
            </Typography>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle2">
              <b>9.</b> I, on behalf, heirs, assigns any person who may have
              interest in the matter hereby release and hold harmless the
              disclosing party above and The Port Clinic, its affiliated
              entities, and their employees and agents from any liability which
              may occur as a result of the use or disclosure of the health
              information authorized herein.
            </Typography>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle2">
              <b>10.</b> Expiration: This authorization will expire one year
              from the date executed below. A copy of facsimileof this
              authorization shall have the same force and effect as an original.
            </Typography>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="patientSignature"
              name="patientSignature"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.patientSignature
                  : formInput.patientSignature
              }
              label="Signature of Patient/Guardian/Parent"
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="patientRelationship"
              name="patientRelationship"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.patientRelationship
                  : formInput.patientRelationship
              }
              label="Relationship to Patient if Other Than Self"
            ></TextField>
          </Grid>
          <Grid md={3}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="date"
                name="date"
                label="From..."
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setDate(newDate);
                  setFormInput({ ...formInput, date: newDate });
                  handleChange;
                }}
                value={props.loadData ? props.formInput.date : date}
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={9}>
            <hr></hr>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle2">Official Use Only</Typography>
          </Grid>
          <Grid md={3}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="requestDate"
                name="requestDate"
                label="Date Request Sent"
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setRequestDate(newDate);
                  setFormInput({ ...formInput, requestDate: newDate });
                  handleChange;
                }}
                value={
                  props.loadData ? props.formInput.requestDate : requestDate
                }
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={3}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="recievedDate"
                name="recievedDate"
                label="Date Records Recieved"
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setRecievedDate(newDate);
                  setFormInput({ ...formInput, recievedDate: newDate });
                  handleChange;
                }}
                value={
                  props.loadData ? props.formInput.recievedDate : recievedDate
                }
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="priority"
              name="priority"
              onChange={handleChange}
              value={
                props.loadData ? props.formInput.priority : formInput.priority
              }
              label="Priority"
            ></TextField>
          </Grid>
          <Grid md={9}>
            <Button variant="contained" onClick={handleInsert}>
              Send
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
