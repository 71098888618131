import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { setFormValues } from "../../../../utilities/Forms/formFunctions";

export default function DriverHealthHistoryReview(props) {

  const handleForm = (event) => {
    setFormValues(props.formInput, props.setFormInput, props.formData, props.setFormData, event);
  };
  return (
    <Box component="form">
      <Paper elevation={5} sx={{ p: 3, mt: 2 }}>
        <Grid container columns={8} spacing={2}>
          <Grid md={8}>
            <Typography variant="subtitle">
              Review and discuss pertinent driver answers and any available
              medical records. Comment on the driver's responses to the "health
              history" questions that may affect the driver's safe operation of
              a commercial motor vehicle (CMV).
            </Typography>
          </Grid>
          <Grid md={8}>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="Write your description..."
              style={{ width: 400 }}
              name="DHH_Review"
              value={props.formInput.DHH_Review}
              onChange={handleForm}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
