import React, { useContext } from "react";
import { GlobalContext } from "../utilities/globalContext";
import { Outlet, Link, Navigate, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

const patientDocumentList = () => {
  const { URL } = useContext(GlobalContext);
  const columns = [
    { field: "id", headerName: "ID", width: 60 },
    { field: "docName", headerName: "Document Name", width: 320 },
    { field: "date", headerName: "Date", width: 160 },
    {
      field: "view",
      headerName: "More Info",
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <a
            href={`${URL}ThePortClinic-API/files/${params.row.docName}`}
            target="_blank"
          >
            <Button variant="contained">Open</Button>
          </a>
        );
      },
    },
  ];

  const patientDocuments = { columns: columns };

  return patientDocuments;
};

export default patientDocumentList;
