import { Outlet, Link, Navigate, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import patientList from "./patientList";

const reportList = () => {
  
  const patients = patientList();
  const rows = [];
  const columns = [
    { field: "id", headerName: "ID", width: 40 },
    { field: "service_date", headerName: "Service Date", width: 150 },
    { field: "company", headerName: "Company", width: 200 },
    { field: "name", headerName: "Name", width: 150 },
    { field: "service", headerName: "Service", width: 150 },
    {
      field: "view",
      headerName: "More Info",
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Link
            to="/Report"
            state={{ id: params.row.id }}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Button variant="outlined" size="small" color="secondary">
              View
            </Button>
          </Link>
        );
      },
    },
  ];

  patients.rows.map((patient)=>{
    let row = {id: patient.id, service_date: "12/12/2022", company: patient.company, name: patient.name, service: patient.service}
    rows.push(row);
  })

  const reports = { columns: columns, rows: rows };

  return reports;
};

export default reportList;
