import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../utilities/globalContext";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Modal from "@mui/material/Modal";

export default function ClinicalExamination() {
  const [examData, setExamData] = useState([]);

  const updateExamData = (event) => {
    setExamData({
      ...examData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Box component="form">
      <Paper elevation={5} sx={{ p: 3, mt: 2 }}>
        <Grid container columns={10} spacing={2}>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="name"
              label="Name"
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="DOB"
              label="DOB"
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="SSN"
              label="SSN"
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="date_of_exam"
              name="date_of_exam"
              label="Date of Exam"
              value={examData.date_of_exam}
              onChange={updateExamData}
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="employer"
              name="employer"
              label="Employer"
              value={examData.employer}
              onChange={updateExamData}
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="occupation"
              name="occupation"
              label="Occupation"
              value={examData.occupation}
              onChange={updateExamData}
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="date_of_injury"
              name="date_of_injury"
              label="Date of Injury"
              value={examData.date_of_injury}
              onChange={updateExamData}
            ></TextField>
          </Grid>
          <Grid md={1.4}>
            <TextField
              required
              fullWidth
              id="age"
              name="age"
              label="Age"
              value={examData.age}
              onChange={updateExamData}
            ></TextField>
          </Grid>
          <Grid md={1.4}>
            <TextField
              required
              fullWidth
              id="sex"
              name="sex"
              label="Sex"
              value={examData.sex}
              onChange={updateExamData}
            ></TextField>
          </Grid>
          <Grid md={1.4}>
            <TextField
              required
              fullWidth
              id="HT"
              name="HT"
              label="HT"
              value={examData.HT}
              onChange={updateExamData}
            ></TextField>
          </Grid>
          <Grid md={1.4}>
            <TextField
              required
              fullWidth
              id="WT"
              name="WT"
              label="WT"
              value={examData.WT}
              onChange={updateExamData}
            ></TextField>
          </Grid>
          <Grid md={1.4}>
            <TextField
              required
              fullWidth
              id="BP"
              name="BP"
              label="BP"
              value={examData.BP}
              onChange={updateExamData}
            ></TextField>
          </Grid>
          <Grid md={1.4}>
            <TextField
              required
              fullWidth
              id="HR"
              name="HR"
              label="HR"
              value={examData.HR}
              onChange={updateExamData}
            ></TextField>
          </Grid>
          <Grid md={1.4}>
            <TextField
              required
              fullWidth
              id="temp"
              name="temp"
              label="Temp"
              value={examData.temp}
              onChange={updateExamData}
            ></TextField>
          </Grid>
          <Grid md={8}>
            <Typography variant="subtitle1">Comorbidities:</Typography>
          </Grid>
          <Grid md={3}>
            <FormControlLabel
              control={<Checkbox />}
              id="NA"
              name="NA"
              label="NA"
              value={examData.NA}
              onChange={updateExamData}
            />
          </Grid>
          <Grid md={3}>
            <FormControlLabel
              control={<Checkbox />}
              id="cardiovascular"
              name="cardiovascular"
              label="Cardiovascular"
              value={examData.cardiovascular}
              onChange={updateExamData}
            />
          </Grid>
          <Grid md={3}>
            <FormControlLabel
              control={<Checkbox />}
              id="diabetes"
              name="diabetes"
              label="Diabetes"
              value={examData.diabetes}
              onChange={updateExamData}
            />
          </Grid>
          <Grid md={3}>
            <FormControlLabel
              control={<Checkbox />}
              id="respiratory"
              name="respiratory"
              label="Respiratory"
              value={examData.respiratory}
              onChange={updateExamData}
            />
          </Grid>
          <Grid md={3}>
            <FormControlLabel
              control={<Checkbox />}
              id="arthritis"
              name="arthritis"
              label="Arthritis"
              value={examData.arthritis}
              onChange={updateExamData}
            />
          </Grid>
          <Grid md={3}>
            <FormControlLabel
              control={<Checkbox />}
              id="other"
              name="other"
              label="Other"
              value={examData.other}
              onChange={updateExamData}
            />
          </Grid>
          <Grid md={10}>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="Chief Complaint:"
              style={{ width: 400 }}
              id="chief_comp"
              name="chief_comp"
              value={examData.chief_comp}
              onChange={updateExamData}
            />
          </Grid>
          <Grid md={10}>
            <hr></hr>
          </Grid>
          <Grid md={10}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Injury Report</TableCell>
                    <TableCell>
                      MECHANISM OF INJURY CAUSATION ANALYSIS
                    </TableCell>
                    <TableCell>Current Work Stats</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Area of Injury</TableCell>
                    <TableCell>
                      <Grid md={10}>
                        <TextField
                          required
                          fullWidth
                          id="area_injury"
                          name="area_injury"
                          value={examData.area_injury}
                          onChange={updateExamData}
                        ></TextField>
                      </Grid>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Pain</TableCell>
                    <TableCell>
                      <Grid container md={10}>
                        <Grid md={5}>
                          <FormControl fullWidth>
                            <Select
                              id="pain"
                              name="pain"
                              onChange={updateExamData}
                              value={examData.pain}
                            >
                              <MenuItem value="Yes">Yes</MenuItem>
                              <MenuItem value="No">No</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid md={5}>
                          <FormControl fullWidth>
                            <InputLabel id="pain-level-label">
                              Pain Level
                            </InputLabel>
                            <Select
                              id="pain"
                              labelId="pain-level-label"
                              name="pain"
                              label="Pain Level"
                              onChange={updateExamData}
                              value={examData.pain}
                            >
                              <MenuItem value="1">1</MenuItem>
                              <MenuItem value="2">2</MenuItem>
                              <MenuItem value="3">3</MenuItem>
                              <MenuItem value="4">4</MenuItem>
                              <MenuItem value="5">5</MenuItem>
                              <MenuItem value="6">6</MenuItem>
                              <MenuItem value="7">7</MenuItem>
                              <MenuItem value="8">8</MenuItem>
                              <MenuItem value="9">9</MenuItem>
                              <MenuItem value="10">10</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <FormControlLabel
                        control={<Checkbox />}
                        id="RTW_nores"
                        name="RTW_nores"
                        label="RTW No Restrictions"
                        value={examData.RTW_nores}
                        onChange={updateExamData}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
