import * as React from "react";
import { GlobalContext } from "./globalContext";
import { Link } from "react-router-dom";
import GroupIcon from "@mui/icons-material/Group";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOffIcon from "@mui/icons-material/FolderOff";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import DvrIcon from "@mui/icons-material/Dvr";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

const ClientList = () => {
  const { userRole } = React.useContext(GlobalContext);

  const Clients = [
    ...(userRole === 1 || userRole === 2 || userRole === 4
      ? [{ id: 1, name: "Clients", route: "Clients", icon: <GroupIcon /> }]
      : []),
    ,
    /*...(userRole === 1 || userRole === 2 || userRole === 4
      ? [
          {
            id: 2,
            name: "New Document",
            route: "New_Document",
            icon: <NoteAddIcon />,
          },
        ]
      : []),
    ...(userRole === 1 || userRole === 2 || userRole === 4
      ? [
          {
            id: 3,
            name: "Reports",
            route: "Reports",
            icon: <AssignmentIcon />,
          },
        ]
      : []),*/
    ...(userRole === 1 || userRole === 2 || userRole === 4
      ? [
          {
            id: 4,
            name: "Schedule",
            route: "Schedule",
            icon: <CalendarMonthIcon />,
          },
        ]
      : []),
    ...(userRole === 1 || userRole === 2 || userRole === 3 || userRole === 4
      ? [
          {
            id: 5,
            name: "Patient Queue",
            route: "Patient_Queue",
            icon: <FormatListNumberedIcon />,
          },
        ]
      : []),
    /*...(userRole === 1 || userRole === 2 || userRole === 3 || userRole === 4
      ? [
          {
            id: 6,
            name: "Scan Queue",
            route: "Scan_Queue",
            icon: <FormatListNumberedIcon />,
          },
        ]
      : []),*/
  ];

  return Clients.map((clientList) => (
    <Link
      to={`/${clientList.route}`}
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <ListItem key={clientList.id} disablePadding>
        <ListItemButton>
          <ListItemIcon>{clientList.icon}</ListItemIcon>
          <ListItemText primary={clientList.name} />
        </ListItemButton>
      </ListItem>
    </Link>
  ));
};

const AuthList = () => {
  const Auth = [
    {
      id: 7,
      name: "New Authorization",
      route: "New_Authorization",
      icon: <NoteAddIcon />,
    },
    {
      id: 8,
      name: "Open Authorizations",
      route: "Open_Authorizations",
      icon: <FolderIcon />,
    },
    {
      id: 9,
      name: "Closed Authorization",
      route: "Closed_Authorizations",
      icon: <FolderOffIcon />,
    },
  ];

  return Auth.map((authList) => (
    <Link
      to={`/${authList.route}`}
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <ListItem key={authList.id} disablePadding>
        <ListItemButton>
          <ListItemIcon>{authList.icon}</ListItemIcon>
          <ListItemText primary={authList.name} />
        </ListItemButton>
      </ListItem>
    </Link>
  ));
};

const AdminList = () => {
  const Admin = [
    {
      id: 10,
      name: "Services",
      route: "Services",
      icon: <RoomServiceIcon />,
    },
    {
      id: 11,
      name: "Injury Status Report",
      route: "Injury_status_report",
      icon: <MedicalServicesIcon />,
    },
    {
      id: 12,
      name: "Report Builder",
      route: "Report_Builder",
      icon: <DvrIcon />,
    },
    {
      id: 13,
      name: "Daily Billing",
      route: "Daily_Billing",
      icon: <RequestQuoteIcon />,
    },
    {
      id: 14,
      name: "Invoices",
      route: "Invoice",
      icon: <RequestQuoteIcon />,
    },
  ];

  return Admin.map((adminList) => (
    <Link
      to={`/${adminList.route}`}
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <ListItem key={adminList.id} disablePadding>
        <ListItemButton>
          <ListItemIcon>{adminList.icon}</ListItemIcon>
          <ListItemText primary={adminList.name} />
        </ListItemButton>
      </ListItem>
    </Link>
  ));
};

export { ClientList, AuthList, AdminList };
