import { Outlet, Link, Navigate, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

const patientList = () => {
  const columns = [
    { field: "id", headerName: "ID", width: 60 },
    { field: "name", headerName: "Name", width: 320 },
    { field: "date", headerName: "Date", width: 160 },
    { field: "company", headerName: "Company", width: 180},
    {
      field: "view",
      headerName: "More Info",
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Link
            to="/Patient_Services"
            state={{ id: params.row.id }}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Button variant="outlined" size="small" color="secondary">
              View
            </Button>
          </Link>
        );
      },
    },
  ];

  const patients = { columns: columns };

  return patients;
};

export default patientList;
