import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { TabList, TabContext, TabPanel } from "@mui/lab";

export default function DynamicTabs(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          {props.list.map((tabs) => (
            <Tab label={tabs.value} value={tabs.id} />
          ))}
        </TabList>
      </Box>
      {props.list.map((tabs) => (
        <TabPanel value={tabs.id}>
          {tabs.component}
        </TabPanel>
      ))}
    </TabContext>
  );
}
