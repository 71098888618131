import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { GlobalContext } from "../../utilities/globalContext";
import ClientDocuments from "../clientDocuments";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Paper from "@mui/material/Paper";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Unstable_Grid2";
import getFiles from "../../utilities/Queries/getFiles";

export default function ClientDocuments(props) {
  const { userId, URL } = useContext(GlobalContext);
  const [file, setFile] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [employee, setEmployee] = useState("");
  const [fileName, setFileName] = useState("");
  const [files, setFiles] = useState([]);
  const [deleteStatus, setDeleteStatus] = useState(0);
  const [insertStatus, setInsertStatus] = useState(0);
  const [updateStatus, setUpdateStatus] = useState(0);

  //Function to get employees
  const employeesURL = `${URL}ThePortClinic-API/getClientEmployees.php?client_id=${props.clientId}`;
  const getEmployees = (apiURL) => {
    axios
      .get(apiURL, {
        responseType: "json",
      })
      .then((res) => {
        if (res.status && res.status === false) {
          setEmployees([
            {
              status: false,
              id: "0",
              first_name: "No data",
              middle_name: "",
              last_name: "",
            },
          ]);
        }
        setEmployees(res.data);
        console.log(employees);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Function to get files
  const getFileURL = `${URL}ThePortClinic-API/getClientFiles.php?client_id=${props.clientId}`;
  const getFileAuthURL = `${URL}ThePortClinic-API/getAuthorizationFiles.php?auth_id=${props.authId}`
  /*const getFiles = (apiURL) => {
    axios
      .get(apiURL, {
        responseType: "json",
      })
      .then((res) => {
        if (res.data.status === false) {
          return;
        }
        setFiles(res.data);
        console.log(files);
      })
      .catch((error) => {
        console.log(error);
      });
  };*/

  //Function to send file
  const fileURL = `${URL}ThePortClinic-API/insertNewFile.php`;
  const sendFile = (form) => {
    axios
      .post(fileURL, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status === false) {
          console.log("No se pudo");
          alert(res.data.message);
          return;
        } else if (res.data.status === true) {
          console.log("Si se pudo");
          alert("Success!");
          setInsertStatus(insertStatus + 1);
          return;
        } else {
          console.log(res);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFileNameChange = (event) => {
    setFileName(event.target.value);
  };

  const handleEmployeeChange = (event) => {
    setEmployee(event.target.value);
  };

  const selectEmployeesOption = () => {
    try {
      return employees.map((item) => (
        <MenuItem value={item.id}>
          {item.middle_name != null
            ? `${item.first_name} ${item.middle_name} ${item.last_name}`
            : `${item.first_name} ${item.last_name}`}
        </MenuItem>
      ));
    } catch {
      return <MenuItem value="No data">No data</MenuItem>;
    }
  };

  const handleDocs = (event) => {
    setFile(event.target.files[0]);
  };

  const cleanInputs = () => {
    setFileName("");
    setEmployee("");
  };

  //Submit file
  const handleSubmitDoc = () => {
    const formData = new FormData();
    formData.append("client_id", props.clientId);
    formData.append("user_id", userId);
    formData.append("file", file);
    formData.append("file_name", fileName);
    if (!props.employeeId) {
      formData.append("employee_id", employee);
    } else {
      formData.append("employee_id", props.employeeId);
    }
    if (!props.authStatus) {
      formData.append("authorization", 0);
      formData.append("authorization_id", null);
    } else {
      formData.append("authorization", 1);
      formData.append("authorization_id", props.authId);
    }

    sendFile(formData);
  };

  useEffect(() => {
    getEmployees(employeesURL);
    console.log(props.authStatus);
    if(props.authStatus === true){
      getFiles(getFileAuthURL, setFiles);
    }else{
      getFiles(getFileURL, setFiles);
    }
    cleanInputs();
  }, [insertStatus, deleteStatus]);

  return (
    <Box>
      <Paper elevation={5} sx={{ p: 3, mt: 2 }}>
        <Grid container columns={8} spacing={2}>
          <Grid md={8}>
            <Typography variant="h5">Select a document to upload</Typography>
          </Grid>
          <Grid md={2}>
            <input type="file" name="document" onChange={handleDocs} />
          </Grid>
          <Grid md={2}>
            <TextField
              fullWidth
              id="file_name"
              name="file_name"
              onChange={handleFileNameChange}
              value={fileName}
              label="Document name"
            ></TextField>
          </Grid>
          {!props.employeeId ? (
            <Grid md={2}>
              <FormControl fullWidth>
                <InputLabel id="employees-label">Employees</InputLabel>
                <Select
                  labelId="employees-label"
                  id="employees"
                  value={employee}
                  label="Employees"
                  onChange={handleEmployeeChange}
                >
                  {selectEmployeesOption()}
                </Select>
              </FormControl>
            </Grid>
          ) : null}
          <Grid md={2}>
            <Button
              variant="outlined"
              size="large"
              color="secondary"
              onClick={handleSubmitDoc}
            >
              Upload
            </Button>
          </Grid>
          <Grid md={8}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>File Uploaded</TableCell>
                    <TableCell>Upload date</TableCell>
                    <TableCell>Employee</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <ClientDocuments
                    files={files}
                    employees={employees}
                    url={URL}
                    deleteStatus={deleteStatus}
                    setDeleteStatus={setDeleteStatus}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
