import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { GlobalContext } from "../utilities/globalContext";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export default function ClientDocuments(props) {
  const { URL } = useContext(GlobalContext);

  const deleteURL = `${props.url}ThePortClinic-API/deleteFile.php`;
  const deleteFile = (id) => {
    if (
      confirm(
        "You are about to delete this document, do you want to continue?"
      ) === true
    ) {
      axios
        .post(deleteURL, { id: id })
        .then((response) => {
          if (response.data.status === true) {
            alert("Success!");
            props.setDeleteStatus(props.deleteStatus + 1);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return;
    }
  };

  if (Array.isArray(props.files)) {
    return props.files.map((file) => {
      let employee = { employee: "No employee..." };
      try {
        employee = props.employees.find(
          (item) => Number(item.id) === file.employee_id
        );
      } catch (error) {
        console.log(error);
      }
      return (
        <TableRow>
          <TableCell>{file.document_name}</TableCell>
          <TableCell>{file.entry_date}</TableCell>
          <TableCell>
            {employee
              ? `${employee.first_name} ${employee.last_name}`
              : "No employee"}
          </TableCell>
          <TableCell>
            <a
              href={`${URL}ThePortClinic-API/files/${file.document_name}`}
              target="_blank"
            >
              <Button variant="contained">Open</Button>
            </a>
          </TableCell>
          <TableCell>
            <Button
              variant="contained"
              onClick={() => {
                deleteFile(file.id);
              }}
            >
              <DeleteForeverIcon />
            </Button>
          </TableCell>
        </TableRow>
      );
    });
  } else {
    return (
      <TableRow>
        <TableCell>No documents...</TableCell>
      </TableRow>
    );
  }
}
