import React, { useState, useEffect, useContext } from "react";
import { Outlet, Link, Navigate, useNavigate } from "react-router-dom";
import { GlobalContext } from "../utilities/globalContext";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import clientList from "../utilities/clientList";
import Grid from "@mui/material/Unstable_Grid2";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";

export default function Clients() {
  const { URL } = useContext(GlobalContext);
  const [clientData, setClientData] = useState([]);
  const [deleteStatus, setDeleteStatus] = useState(0);
  const clients = clientList(deleteStatus, setDeleteStatus);

  useEffect(() => {
    axios
      .get(`${URL}ThePortClinic-API/getClients.php`)
      .then((res) => {
        res.data.forEach((element) => {
          setClientData(res.data);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [deleteStatus]);

  let rows = clientData.map((item) => ({
    id: item.id,
    companyName: item.company_name,
  }));

  return (
    <Box sx={{ flexGrow: 1, height: "100%" }}>
      <Grid container sx={{ height: "100%" }} spacing={2} columns={4}>
        <Grid md={4}>
          <Typography variant="h4">Client List</Typography>
        </Grid>
        <Grid mdOffset="auto">
          <Link
            to="/New_Client"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Button variant="contained" startIcon={<AddCircleIcon />}>
              Add new client
            </Button>
          </Link>
        </Grid>
        <Grid md={4}>
          <Paper elevation={5} sx={{ p: 3, height: "70vh" }}>
            <DataGrid
              rows={rows}
              columns={clients.columns}
              pageSize={15}
              rowsPerPageOptions={[15]}
              checkboxSelection
              components={{
                Toolbar: GridToolbar,
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: "companyName", sort: "asc" }],
                },
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
