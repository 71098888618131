import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../utilities/globalContext";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import DOTPhysical from "../components/Forms/DOTPhysical";
import NonDOTPhysical from "../components/Forms/Physical";
import WorkersComp from "../components/Forms/WorkersComp";
import ReleaseMedInf from "../components/Forms/ReleaseMedInf";
import Audiogram from "../components/Forms/Audiogram";
import InjectionConsent from "../components/Forms/InjectionConsent";
import Tuberculosis from "../components/Forms/Tuberculosis";
import RapidDrugScreen from "../components/Forms/RapidDrugScreen";
import axios from "axios";

export default function ServiceForm() {
  const { URL } = useContext(GlobalContext);
  const location = useLocation();
  const { id, formName } = location.state;
  const [formData, setFormData] = useState({});
  const [formInput, setformInput] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [loadData, setLoadData] = useState(true);

  useEffect(() => {
    axios
      .get(`${URL}ThePortClinic-API/getForm.php?id=${id}`)
      .then((res) => {
        setFormData({ ...formData, ...res.data });
        let jsonData = JSON.parse(res.data.form_data);
        setformInput({ ...formInput, ...jsonData });
        setIsLoading(false);
        console.log(jsonData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  if (isLoading) {
    return (
      <Box sx={{ flexGrow: 1, height: "100%" }}>
        <Grid container sx={{ height: "100%" }} spacing={2} columns={8}>
          <Grid md={8}>
            <Typography>Loading...</Typography>
          </Grid>
        </Grid>
      </Box>
    );
  }
  return (
    <Box sx={{ flexGrow: 1, height: "100%" }}>
      <Grid container sx={{ height: "100%" }} spacing={2} columns={8}>
        <Grid md={8}>
          <Typography variant="h4">Forms</Typography>
        </Grid>
        {formName === "DOTPhysical" && (
          <Grid md={8}>
            <DOTPhysical
              employeeId={formData.employee_id}
              clientId={formData.client_id}
              authId={formData.authorization_id}
              url={URL}
              formInput={formInput}
              setformInput={setformInput}
              loadData={loadData}
            />
          </Grid>
        )}
        {formName === "NonDOTPhysical" && (
          <Grid md={8}>
            <NonDOTPhysical
              employeeId={formData.employee_id}
              clientId={formData.client_id}
              authId={formData.authorization_id}
              url={URL}
              formInput={formInput}
              setformInput={setformInput}
              loadData={loadData}
            />
          </Grid>
        )}
        {formName === "WorkersComp" && (
          <Grid md={8}>
            <WorkersComp
              employeeId={formData.employee_id}
              clientId={formData.client_id}
              authId={formData.authorization_id}
              url={URL}
              formInput={formInput}
              setformInput={setformInput}
              loadData={loadData}
            />
          </Grid>
        )}
        {formName === "ReleaseMedInf" && (
          <Grid md={8}>
            <ReleaseMedInf
              employeeId={formData.employee_id}
              clientId={formData.client_id}
              authId={formData.authorization_id}
              url={URL}
              formInput={formInput}
              setformInput={setformInput}
              loadData={loadData}
            />
          </Grid>
        )}
        {formName === "Audiogram" && (
          <Grid md={8}>
            <Audiogram
              employeeId={formData.employee_id}
              clientId={formData.client_id}
              authId={formData.authorization_id}
              url={URL}
              formInput={formInput}
              setformInput={setformInput}
              loadData={loadData}
            />
          </Grid>
        )}
        {formName === "InjectionConsent" && (
          <Grid md={8}>
            <InjectionConsent
              employeeId={formData.employee_id}
              clientId={formData.client_id}
              authId={formData.authorization_id}
              url={URL}
              formInput={formInput}
              setformInput={setformInput}
              loadData={loadData}
            />
          </Grid>
        )}
        {formName === "Tuberculosis" && (
          <Grid md={8}>
            <Tuberculosis
              employeeId={formData.employee_id}
              clientId={formData.client_id}
              authId={formData.authorization_id}
              url={URL}
              formInput={formInput}
              setformInput={setformInput}
              loadData={loadData}
            />
          </Grid>
        )}
        {(formName === "RapidDrugScreen5" ||
          formName === "RapidDrugScreen10" ||
          formName === "RapidDrugScreen12" ||
          formName === "RapidDrugScreen16") && (
          <Grid md={8}>
            <RapidDrugScreen
              employeeId={formData.employee_id}
              clientId={formData.client_id}
              authId={formData.authorization_id}
              url={URL}
              formInput={formInput}
              setformInput={setformInput}
              loadData={loadData}
              panels={
                (formName === "RapidDrugScreen5" && "5") ||
                (formName === "RapidDrugScreen10" && "10") ||
                (formName === "RapidDrugScreen12" && "12") ||
                (formName === "RapidDrugScreen16" && "16")
              }
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
