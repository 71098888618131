import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { TabList, TabContext, TabPanel } from "@mui/lab";
import Typography from "@mui/material/Typography";
import NewClientForm from "../components/Clients/NewClientForm";
import ClientEmployees from "../components/Clients/ClientEmployees";
import ClientDocuments from "../components/Clients/ClientDocuments";
import ClientPrices from "../components/Clients/ClientPrices";
import ClinicalExamination from "../components/Clients/ClinicalExamination";

export default function NewClient() {
  //Client form state
  const [clientFormInput, setClientFormInput] = useState({
    contactType: "",
    contactAccess: "",
  });
  const [clientId, setClientId] = useState(0);
  const handleStateChange = (newState) => {
    setClientId(newState);
  };

  //Tabs state
  const [value, setValue] = useState("0");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Typography variant="h4">New Client</Typography>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Info" value="0"></Tab>
            <Tab label="Employees" value="1"></Tab>
            <Tab label="Documents" value="2"></Tab>
            <Tab label="Special Pricing" value="3"></Tab>
          </TabList>
        </Box>
        <TabPanel value="0">
          <NewClientForm
            dataInput={clientFormInput}
            setDataInput={setClientFormInput}
            clientId={clientId}
            changeClientId={handleStateChange}
          />
        </TabPanel>
        <TabPanel value="1">
          <ClientEmployees
            clientId={clientId}
            changeClientId={handleStateChange}
          />
        </TabPanel>
        <TabPanel value="2">
          <ClientDocuments
            clientId={clientId}
            changeClientId={handleStateChange}
          />
        </TabPanel>
        <TabPanel value="3">
          <ClientPrices
            clientId={clientId}
            changeClientId={handleStateChange}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
