const serviceList = () => {
  const columns = [
    { field: "id", headerName: "ID", width: 40 },
    { field: "service", headerName: "Service", width: 250 },
    { field: "fee", headerName: "Fee", width: 150 },
    { field: "name", headerName: "Name", width: 150 },
  ];

  const rows = [
    {
      id: 1,
      service: "Audiogram",
      fee: 76.00
    },
    {
      id: 2,
      service: "Breath Alcohol Test/BAT",
      fee: 35.00
    },
    {
      id: 3,
      service: "Ceftriaxone Injection 1 Gram",
      fee: 60.00
    },
    {
      id: 4,
      service: "Chest X-ray 2 Views with Radiology Reading",
      fee: 65.00
    },
    {
      id: 5,
      service: "Chest X-ray 1 View with Radiology Reading",
      fee: 55.00
    },
    {
      id: 6,
      service: "COVID Vaccine",
      fee: 0
    },
    {
      id: 7,
      service: "Dexamethasone Injection 4 MG",
      fee: 50.00
    },
    {
      id: 8,
      service: "Drug Screen - Collection Only",
      fee: 40.00
    },
    {
      id: 9,
      service: "Drug Screen - DOT",
      fee: 45.00
    },
    {
      id: 10,
      service: "Drug Screen - Non-DOT",
      fee: 45.00
    },
    {
      id: 11,
      service: "Electrocardiogram / EKG",
      fee: 60.00
    },
    {
      id: 12,
      service: "Hepatitis A Vaccine",
      fee: 103.92
    },
    {
      id: 13,
      service: "Hepatitis B Vaccine",
      fee: 82.33
    },
    {
      id: 14,
      service: "Influenza Test (Flu Test)",
      fee: 60.00
    },
    {
      id: 15,
      service: "Influenza Vaccine",
      fee: 30.00
    },
    {
      id: 16,
      service: "Osha Questionnaire",
      fee: 18.00
    },
    {
      id: 17,
      service: "PCR COVID Test (Sent to Lab)",
      fee: 150.00
    },
    {
      id: 18,
      service: "Phenegran Injection 12.5MG",
      fee: 25.00
    },
    {
      id: 19,
      service: "Phenegran Injection 25MG",
      fee: 40.00
    },
    {
      id: 20,
      service: "Physicals - DOT",
      fee: 85.00
    },
    {
      id: 21,
      service: "Physicals - NON-DOT",
      fee: 75.00
    },
    {
      id: 22,
      service: "Physicals - Pre-Employment",
      fee: 75.00
    },
    {
      id: 23,
      service: "Rapid Drug Screen 10 Panel",
      fee: 25.00
    },
    {
      id: 24,
      service: "Rapid Drug Screen 16 Panel",
      fee: 45.00
    },
    {
      id: 25,
      service: "Respirator Fit Test - Qualitative (Taste Test)",
      fee: 50.00
    },
    {
      id: 26,
      service: "Respirator Fit Test - Quantitative (Card is issued)",
      fee: 50.00
    },
    {
      id: 27,
      service: "SARS COVID Rapid Test",
      fee: 110.00
    },
    {
      id: 28,
      service: "Spirometry / PFT",
      fee: 85.00
    },
    {
      id: 29,
      service: "Strep Screen",
      fee: 25.00
    },
    {
      id: 30,
      service: "TB Test",
      fee: 25.00
    },
    {
      id: 31,
      service: "TB X-Ray (Positive Result) 1 View with Radiology Reading",
      fee: 55.00
    },
    {
      id: 32,
      service: "TDAP Vaccine",
      fee: 63.31
    },
    {
      id: 33,
      service: "Toradol 30MG Injection",
      fee: 25.00
    },
    {
      id: 34,
      service: "Toradol 60MG Injection",
      fee: 50.00
    },
    {
      id: 35,
      service: "Twinrix (Hep A/Hep B Together)",
      fee: 132.68
    },
    {
      id: 36,
      service: "Typhoid Vaccine",
      fee: 180.00
    },
    {
      id: 37,
      service: "Urinalyss",
      fee: 15.00
    },
    {
      id: 38,
      service: "Urine Pregnancy Test",
      fee: 30.00
    },
    {
      id: 39,
      service: "Vision Test",
      fee: 35.00
    },
    {
      id: 40,
      service: "Yellow Fever Vaccine",
      fee: 230.00
    },
  ];

  const services = { columns: columns, rows: rows };

  return services;
};

export default serviceList;
