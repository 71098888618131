import ReactDOM from "react-dom/client";
import App from "./App";
import GlobalContextPovider from "./utilities/globalContext";

const app = document.getElementById("app");
const root = ReactDOM.createRoot(app);
root.render(
  <GlobalContextPovider>
    <App />
  </GlobalContextPovider>
);
