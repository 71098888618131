import React, { useState, useContext } from "react";
import { GlobalContext } from "../utilities/globalContext";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { TabList, TabContext, TabPanel } from "@mui/lab";
import Typography from "@mui/material/Typography";
import ClientForm from "../components/Clients/ClientForm";
import ClientEmployees from "../components/Clients/ClientEmployees";
import ClientDocuments from "../components/Clients/ClientDocuments";
import ClientPrices from "../components/Clients/ClientPrices";
import DWC073 from "../components/Forms/DWC073";

export default function Client(props) {
  const location = useLocation();
  const { id } = location.state;
  const [clientId, setClientId] = useState(0);
  const handleStateChange = (newState) => {
    setClientId(newState);
  };
  const { userRole } = useContext(GlobalContext);

  //Tabs state
  const [value, setValue] = useState("0");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box>
      <Typography variant="h4">Client</Typography>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Info" value="0"></Tab>
            <Tab label="Employees" value="1"></Tab>
            <Tab label="Documents" value="2"></Tab>
            {userRole === 1 || userRole === 2 ? (
              <Tab label="Special Pricing" value="3"></Tab>
            ) : null}
          </TabList>
        </Box>
        <TabPanel value="0">
          <ClientForm
            id={id}
            type={true}
            clientId={clientId}
            changeClientId={handleStateChange}
          />
        </TabPanel>
        <TabPanel value="1">
          <ClientEmployees
            clientId={clientId}
            changeClientId={handleStateChange}
          />
        </TabPanel>
        <TabPanel value="2">
          <ClientDocuments
            clientId={clientId}
            changeClientId={handleStateChange}
          />
        </TabPanel>
        {userRole === 1 || userRole === 2 ? (
          <TabPanel value="3">
            <ClientPrices
              clientId={clientId}
              changeClientId={handleStateChange}
            />
          </TabPanel>
        ) : null}
      </TabContext>
    </Box>
  );
}
