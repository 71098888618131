import React, { useState, useContext, useEffect } from "react";
import { GlobalContext } from "./utilities/globalContext";
import { HashRouter, Routes, Route } from "react-router-dom";
import axios from "axios";
import Navigation from "./components/Navigation";
import Login from "./pages/Login";
import PasswordChange from "./pages/PasswordChange";
import Dashboard from "./pages/Dashboard";
import Clients from "./pages/Clients";
import Client from "./pages/Client";
import NewClient from "./pages/NewClient";
import NewDocument from "./pages/NewDocument";
import Reports from "./pages/Reports";
import Report from "./pages/Report";
import Schdule from "./pages/Schedule";
import NewAuthorization from "./pages/NewAuthorization";
import NewAuthorizationInfo from "./pages/NewAuthorizationInfo";
import OpenAuthorizations from "./pages/OpenAuthorizations";
import ClosedAuthorizations from "./pages/ClosedAuthorizations";
import Authorization from "./pages/Authorization";
import Services from "./pages/Services";
import PatientProfile from "./pages/PatientProfile";
import PatientServices from "./pages/PatientServices";
import ServiceForm from "./pages/ServiceForm";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./components/theme";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

function App() {
  const {
    login,
    setLogin,
    user,
    userRole,
    setUserRole,
    firstLogin,
    setFirstLogin,
    URL,
  } = useContext(GlobalContext);
  useEffect(() => {
    axios
      .get(`${URL}ThePortClinic-API/checkLogin.php`)
      .then((res) => {
        if (res.data.status === false) {
          setLogin(false);
          console.log(res);
        } else if (res.data.status === true) {
          setLogin(true);
          setUserRole(res.data.userRole);
          console.log("Yeh");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <HashRouter>
        <Routes>
          {!login && <Route path="*" element={<Login />}></Route>}
          {login && (
            <Route
              path="/*"
              element={firstLogin === 1 ? <PasswordChange /> : <Navigation />}
            >
              <Route
                index
                element={userRole === 3 ? <PatientProfile /> : <Clients />}
              ></Route>
              <Route path="Clients" element={<Clients />}></Route>
              <Route path="Client" element={<Client />}></Route>
              <Route path="New_Client" element={<NewClient />}></Route>
              <Route path="" element={<NewDocument />}></Route>
              <Route path="" element={<Reports />}></Route>
              <Route path="" element={<Report />}></Route>
              <Route path="Schedule" element={<Schdule />}></Route>
              <Route path="Patient_Queue" element={<PatientProfile />}></Route>
              <Route
                path="Patient_Services"
                element={<PatientServices />}
              ></Route>
              <Route path="Service_Form" element={<ServiceForm />}></Route>
              <Route
                path="New_Authorization"
                element={<NewAuthorization />}
              ></Route>
              <Route
                path="New_Authorization_Info"
                element={<NewAuthorizationInfo />}
              ></Route>
              <Route
                path="Open_Authorizations"
                element={<OpenAuthorizations />}
              ></Route>
              <Route
                path="Closed_Authorizations"
                element={<ClosedAuthorizations />}
              ></Route>
              <Route
                path="Open_Authorization_Info"
                element={<Authorization />}
              ></Route>
              <Route path="Services" element={<Services />}></Route>
            </Route>
          )}
        </Routes>
      </HashRouter>
    </ThemeProvider>
  );
}

export default App;
