import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../utilities/globalContext";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPLugin from "@fullcalendar/timegrid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import getAuthorizations from "../utilities/Queries/getAuthorizations";

export default function Clients() {
  const { URL } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [authorizationsList, setAuthorizationsList] = useState([]);
  const [events, setEvents] = useState([]);
  const [eventData, setEvenData] = useState({});

  useEffect(() => {
    let url = `${URL}ThePortClinic-API/getAuthorizations.php`;
    getAuthorizations(url, setAuthorizationsList);
  }, []);

  useEffect(() => {
    if (authorizationsList.length > 0 && Array.isArray(authorizationsList)) {
      let eventsList = [];

      authorizationsList.map((item) => {
        if (item?.entry_date) {
          const date = item.entry_date;
          const dateObj = new Date(date);

          function formatDate(dateObj) {
            const year = dateObj.toLocaleString("default", { year: "numeric" });
            const month = dateObj.toLocaleString("default", {
              month: "2-digit",
            });
            const day = dateObj.toLocaleString("default", { day: "2-digit" });

            return [year, month, day].join("-");
          }

          let currentEvent = {
            id: item.id,
            title: `Test ${item.id}`,
            start: dateObj,
          };
          eventsList.push(currentEvent);
        }
      });

      setEvents(eventsList);
    } else {
      console.log(authorizationsList);
    }
  }, [authorizationsList]);

  useEffect(() => {
    console.log(eventData)
    const keys = Object.keys(eventData)
    if (keys.length > 0) {
      navigate("/Open_Authorization_Info", { state: eventData });
    }
  }, [eventData]);

  handleEventClick = ({ event }) => {
    setEvenData({ id: event.id, title: event.title });
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="h4">Schedule</Typography>
      <Paper elevation={5} sx={{ p: 3, mt: 2 }}>
        <Grid container spacing={2} columns={8}>
          <Grid md={8}>
            <FullCalendar
              initialView="timeGridDay"
              headerToolbar={{
                left: "title",
                right: "prev,next,today,dayGridMonth,timeGridWeek,timeGridDay",
              }}
              plugins={[dayGridPlugin, timeGridPLugin]}
              events={events}
              eventClick={handleEventClick}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
