import React, { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../utilities/globalContext";
import axios from "axios";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { setFormValues } from "../../utilities/Forms/formFunctions";
import insertForm from "../../utilities/Queries/insertNewForm";

export default function InjectionConsent(props) {
  //Context
  const { URL } = useContext(GlobalContext);
  //Form related states
  const [formInput, setFormInput] = useState({});
  const [dob, setDob] = useState("");
  const [date, setDate] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [signatureDate, setSignatureDate] = useState("");
  const [formData, setFormData] = useState({
    employee_id: props.employeeId,
    client_id: props.clientId,
    authorization_id: props.authId,
    form_name: "InjectionConsent",
  });

  const handleChange = (event) => {
    setFormValues(formInput, setFormInput, formData, setFormData, event);
  };

  const handleInsert = () => {
    insertForm(formData, URL);
  };

  return (
    <Box>
      <Paper elevation={5} sx={{ p: 3, mt: 2 }}>
        <Grid container spacing={2} columns={9}>
          <Typography variant="h5">Injection Consent Form</Typography>
          <Grid md={9}>
            <hr></hr>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              I authorize The Port Occupational and Medical Clinic to administer
              the following injection:
            </Typography>
          </Grid>
          <Grid md={7}>
            <TextField
              required
              fullWidth
              id="injection"
              name="injection"
              onChange={handleChange}
              value={
                props.loadData ? props.formInput.injection : formInput.injection
              }
            ></TextField>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              I understand the benefits and the risks (including, but not
              limited to, possible local reaction, infection, bleeding, injury
              to nerves, blood vessels or muscle tissues, anaphylactic reaction
              to death) of the injection. My signature indicates that I hereby
              release The Port Occupational and Medical Clinic from any and all
              liabilities of this injection.
            </Typography>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              I understand that a 20-minute observation period following an
              injection is required.
            </Typography>
          </Grid>
          <Grid md={9}>
            <Typography variant="h6">
              Information on Person to Recieve the Injection
            </Typography>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="lastName"
              name="lastName"
              onChange={handleChange}
              value={
                props.loadData ? props.formInput.lastName : formInput.lastName
              }
              label="Last Name"
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="firstName"
              name="firstName"
              onChange={handleChange}
              value={
                props.loadData ? props.formInput.firstName : formInput.firstName
              }
              label="First Name"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="middleInitial"
              name="middleInitial"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.middleInitial
                  : formInput.middleInitial
              }
              label="Middle Initial"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="dob"
                name="dob"
                label="Date of Birth"
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setDob(newDate);
                  setFormInput({ ...formInput, dob: newDate });
                  handleChange;
                }}
                value={props.loadData ? props.formInput.dob : dob}
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="streetAddress"
              name="streetAddress"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.streetAddress
                  : formInput.streetAddress
              }
              label="Street Address"
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="phoneNumber"
              name="phoneNumber"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.phoneNumber
                  : formInput.phoneNumber
              }
              label="Phone Number"
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="gender"
              name="gender"
              onChange={handleChange}
              value={props.loadData ? props.formInput.gender : formInput.gender}
              label="Gender"
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="city"
              name="city"
              onChange={handleChange}
              value={props.loadData ? props.formInput.city : formInput.city}
              label="City"
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="state"
              name="state"
              onChange={handleChange}
              value={props.loadData ? props.formInput.state : formInput.state}
              label="State"
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="zip"
              name="zip"
              onChange={handleChange}
              value={props.loadData ? props.formInput.zip : formInput.zip}
              label="Zip Code"
            ></TextField>
          </Grid>
          <Grid md={7}>
            <TextField
              required
              fullWidth
              id="orderingPhysician"
              name="orderingPhysician"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.orderingPhysician
                  : formInput.orderingPhysician
              }
              label="Ordering Physician/Physician Assistant"
            ></TextField>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              Patient/Parent/Legal Guardian Signature:
            </Typography>
          </Grid>
          <Grid md={7}>
            <TextField
              required
              fullWidth
              id="patientSignature"
              name="patientSignature"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.patientSignature
                  : formInput.patientSignature
              }
            ></TextField>
          </Grid>
          <Grid md={7}>
            <TextField
              required
              fullWidth
              id="nameOfSignature"
              name="nameOfSignature"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.nameOfSignature
                  : formInput.nameOfSignature
              }
              label="Name of Signature"
            ></TextField>
          </Grid>
          <Grid md={9}>
            <hr></hr>
          </Grid>
          <Grid md={9}>
            <Typography variant="h6">For Clinic Use Only</Typography>
          </Grid>
          <Grid md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="date"
                name="date"
                label="Date"
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setDate(newDate);
                  setFormInput({ ...formInput, date: newDate });
                  handleChange;
                }}
                value={props.loadData ? props.formInput.date : date}
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="time"
              name="time"
              onChange={handleChange}
              value={props.loadData ? props.formInput.time : formInput.time}
              label="Time"
            ></TextField>
          </Grid>
          <Grid md={5}></Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="medication"
              name="medication"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.medication
                  : formInput.medication
              }
              label="Medication"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="dose"
              name="dose"
              onChange={handleChange}
              value={props.loadData ? props.formInput.dose : formInput.dose}
              label="Dose"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="route"
              name="route"
              onChange={handleChange}
              value={props.loadData ? props.formInput.route : formInput.route}
              label="Route"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="site"
              name="site"
              onChange={handleChange}
              value={props.loadData ? props.formInput.site : formInput.site}
              label="Site"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="manufacturer"
              name="manufacturer"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.manufacturer
                  : formInput.manufacturer
              }
              label="Manufacturer"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="lotNumber"
              name="lotNumber"
              onChange={handleChange}
              value={
                props.loadData ? props.formInput.lotNumber : formInput.lotNumber
              }
              label="Lot #"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="expirationDate"
                name="expirationDate"
                label="Expiration Date"
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setExpirationDate(newDate);
                  setFormInput({ ...formInput, expirationDate: newDate });
                  handleChange;
                }}
                value={
                  props.loadData
                    ? props.formInput.expirationDate
                    : expirationDate
                }
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">Signature of MA</Typography>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="signatureMA"
              name="signatureMA"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.signatureMA
                  : formInput.signatureMA
              }
            ></TextField>
          </Grid>
          <Grid md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="signatureDate"
                name="signatureDate"
                label="Date"
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setSignatureDate(newDate);
                  setFormInput({ ...formInput, signatureDate: newDate });
                  handleChange;
                }}
                value={
                  props.loadData ? props.formInput.signatureDate : signatureDate
                }
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="nameMA"
              name="nameMA"
              onChange={handleChange}
              value={props.loadData ? props.formInput.nameMA : formInput.nameMA}
              label="Name of MA"
            ></TextField>
          </Grid>
          <Grid md={9}>
            <Button variant="contained" onClick={handleInsert}>
              Send
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
