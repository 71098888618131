import React, { useState, useContext } from "react";
import { GlobalContext } from "../utilities/globalContext";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import theme from "../components/theme";
import Logo from "../../public/The-Port-Clinic-Logo.png";

export default function Login() {
  //Context
  const {
    URL,
    setUser,
    setUserId,
    setLogin,
    setUserRole,
    setFirstLogin,
    setUserStatus,
  } = useContext(GlobalContext);

  //Login input state
  const [loginInput, setLoginInput] = useState({});
  const updateLoginInput = (e) => {
    setLoginInput({
      ...loginInput,
      [e.target.name]: e.target.value,
    });
  };

  //Send login data function
  const loginURL = `${URL}ThePortClinic-API/login.php`;
  const sendLogin = (data, apiURL) => {
    axios({
      method: "POST",
      url: apiURL,
      data: data,
      headers: {
        "Content-Type": "text/plain;charset=utf-8",
      },
    })
      .then((res) => {
        if (res.data.status === false) {
          console.log("No se pudo");
          return;
        } else if (res.data.status === true) {
          setUser(res.data.user);
          setUserId(res.data.userId);
          setLogin(res.data.status);
          setUserRole(res.data.userRole);
          setFirstLogin(res.data.firstLogin);
          setUserStatus(res.data.userStatus);
          console.log("Si se pudo");
        } else {
          console.log(res);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  };

  const handleLogin = () => {
    sendLogin(loginInput, loginURL);
  };

  const handleEnter = (event) => {
    if ((event.key === "Enter")) {
      sendLogin(loginInput, loginURL);
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={Logo} alt="The Port Clinic" width={150} height="auto" />
          <Typography component="h4" variant="h4">
            Sign in
          </Typography>
          <Box sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              onChange={updateLoginInput}
              value={loginInput.username}
              onKeyDown={handleEnter}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={updateLoginInput}
              value={loginInput.password}
              onKeyDown={handleEnter}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleLogin}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
