import * as React from "react";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2D2D2D",
    },
    secondary: {
      main: "#00A5EA",
    },
  },
});

export default theme;
