import React, { useState, useEffect } from "react";
import axios from "axios";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export default function ClientContacts(props) {
  const [updateContactId, setUpdateContactId] = useState(0);
  const handleUpdate = (contact) => {
    setUpdateContactId(contact.id);
    setInput({
      ...contact,
    });
  };

  //Handle input update
  const [input, setInput] = useState({});
  const updateInput = (event) => {
    setInput({
      ...input,
      [event.target.name]: event.target.value,
      contactId: updateContactId,
      clientId: props.clientId
    });
  };

  //Delete service
  const deleteURL = `${props.url}ThePortClinic-API/deleteContact.php`;
  const deleteContact = (id) => {
    if (
      confirm(
        "You are about to delete this contact, do you want to continue?"
      ) === true
    ) {
      axios
        .post(deleteURL, { id: id })
        .then((response) => {
          console.log(response.data);
          if (response.data.status === true) {
            alert("Success!");
            props.setDeleteStatus(props.deleteStatus + 1);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return;
    }
  };

  //Update service
  const updateURL = `${props.url}ThePortClinic-API/updateContact.php`;
  const updateContact = (apiURL) => {
    axios
      .post(apiURL, input)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === true) {
          alert("Success!");
        }
        setUpdateContactId(0);
        props.setUpdateStatus(props.updateStatus + 1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log(props.contacts);
  if (Array.isArray(props.contacts)) {
    return props.contacts.map((contact) => {
      if (contact.id === updateContactId) {
        return (
          <TableRow>
            <TableCell>
              <TextField
                fullWidth
                id="contact_name"
                name="contact_name"
                label="contact_name"
                onChange={updateInput}
                value={input.contact_name}
              ></TextField>
            </TableCell>
            <TableCell>
              <TextField
                fullWidth
                id="contact_email"
                name="contact_email"
                label="contact_email"
                onChange={updateInput}
                value={input.contact_email}
              ></TextField>
            </TableCell>
            <TableCell>
              <TextField
                fullWidth
                id="contact_phone"
                name="contact_phone"
                label="contact_phone"
                onChange={updateInput}
                value={input.contact_phone}
              ></TextField>
            </TableCell>
            <TableCell>
              <FormControl fullWidth>
                <InputLabel id="contact_type">Contact Type</InputLabel>
                <Select
                  labelId="contact_type"
                  id="contact_type"
                  name="contact_type"
                  label="Contact Type"
                  onChange={updateInput}
                  defaultValue={input.contact_type}
                >
                  <MenuItem value="Default">Default</MenuItem>
                  <MenuItem value="Primary">Primary</MenuItem>
                  <MenuItem value="Billing">Billing</MenuItem>
                </Select>
              </FormControl>
            </TableCell>
            <TableCell>
              <FormControl fullWidth>
                <InputLabel id="contact_access">Access Type</InputLabel>
                <Select
                  labelId="contact_access"
                  id="contact_access"
                  name="contact_access"
                  label="Access Type"
                  onChange={updateInput}
                  defaultValue={input.contact_access}
                >
                  <MenuItem value="Billing">Billing</MenuItem>
                  <MenuItem value="Default Contact">Default Contact</MenuItem>
                  <MenuItem value="Read Only">Read Only</MenuItem>
                </Select>
              </FormControl>
            </TableCell>
            <TableCell>
              <Button
                variant="contained"
                onClick={() => {
                  updateContact(updateURL);
                }}
              >
                <CheckCircleOutlineIcon />
              </Button>
            </TableCell>
          </TableRow>
        );
      }
      return (
        <TableRow>
          <TableCell>{contact.contact_name}</TableCell>
          <TableCell>{contact.contact_email}</TableCell>
          <TableCell>{contact.contact_phone}</TableCell>
          <TableCell>{contact.contact_type}</TableCell>
          <TableCell>{contact.contact_access}</TableCell>
          <TableCell>
            <Button
              variant="contained"
              onClick={() => {
                handleUpdate(contact);
              }}
            >
              <EditIcon />
            </Button>
          </TableCell>
          <TableCell>
            <Button
              variant="contained"
              onClick={() => {
                deleteContact(contact.id);
              }}
            >
              <DeleteForeverIcon />
            </Button>
          </TableCell>
        </TableRow>
      );
    });
  } else {
    return (
      <TableRow>
        <TableCell>No contacts...</TableCell>
      </TableRow>
    );
  }
}
