import React, { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../utilities/globalContext";
import axios from "axios";
import ClientPrices from "../clientPrices";
import { Outlet, Link, Navigate, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import serviceList from "../../utilities/serviceList";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export default function ClientPrices(props) {
  //Context
  const { URL } = useContext(GlobalContext);
  const [deleteStatus, setDeleteStatus] = useState(0);
  const [insertStatus, setInsertStatus] = useState(0);
  const [updateStatus, setUpdateStatus] = useState(0);

  //Prices state
  const [priceInput, setPriceInput] = useState({});
  const [service, setService] = useState(0);

  const updatePriceInput = (e) => {
    setPriceInput({
      ...priceInput,
      [e.target.name]: e.target.value,
      clientId: props.clientId,
      serviceId: service,
    });
    console.log(priceInput);
  };

  //Insert price function
  const priceURL = `${URL}ThePortClinic-API/insertNewPrice.php`;
  const insertNewData = (data, apiURL) => {
    axios
      .post(apiURL, data)
      .then((res) => {
        if (res.data.status === false) {
          console.log(res.data);
          return;
        } else if (res.data.status === true) {
          console.log("Si se pudo");
          alert("Success!");
          setInsertStatus(insertStatus + 1);
          return;
        } else {
          console.log(res);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  };

  //Get prices function
  const getPricesURL = `${URL}ThePortClinic-API/getClientPrices.php?client_id=${props.clientId}`;
  const [priceData, setPricetData] = useState([]);
  const getPrices = (apiURL) => {
    axios
      .get(apiURL)
      .then((response) => {
        setPricetData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Get services
  const getServicesURL = `${URL}ThePortClinic-API/getServices.php`;
  const [serviceData, setServicetData] = useState([]);
  const getServices = (apiURL) => {
    axios
      .get(apiURL)
      .then((response) => {
        setServicetData(response.data);
        console.log(serviceData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getPrices(getPricesURL);
    getServices(getServicesURL);
    console.log(serviceData);
  }, [insertStatus, deleteStatus, updateStatus]);

  const handleChange = (event) => {
    updatePriceInput(event);
  };

  const handleChangeService = (event) => {
    setService(event.target.value);
    updatePriceInput(event);
  };

  const handleDataSubmit = (event) => {
    insertNewData(priceInput, priceURL);
  };

  const selectOptions = () => {
    try {
      return serviceData.map((item) => (
        <MenuItem value={item.id}>{item.service}</MenuItem>
      ));
    } catch {
      return <MenuItem value={0}>No services...</MenuItem>;
    }
  };

  return (
    <Box>
      <Paper elevation={5} sx={{ p: 3, mt: 2 }}>
        <Grid container spacing={2} columns={8}>
          <Grid md={4}>
            <FormControl fullWidth>
              <InputLabel id="service-label">Service</InputLabel>
              <Select
                labelId="service-label"
                id="service"
                name="service"
                onChange={handleChangeService}
                value={service}
                label="Service"
              >
                {selectOptions()}
              </Select>
            </FormControl>
          </Grid>
          <Grid md={2}>
            <TextField
              fullWidth
              id="specialPrice"
              name="specialPrice"
              onChange={handleChange}
              value={priceInput.specialPrice}
              label="Special Price"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <Button size="large" variant="contained" onClick={handleDataSubmit}>
              Add
            </Button>
          </Grid>
          <Grid md={8}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Service</TableCell>
                    <TableCell>Special Price</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <ClientPrices
                    services={serviceData}
                    prices={priceData}
                    url={URL}
                    deleteStatus={deleteStatus}
                    setDeleteStatus={setDeleteStatus}
                    updateStatus={updateStatus}
                    setUpdateStatus={setUpdateStatus}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
