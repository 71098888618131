import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { setFormValues } from "../../../../utilities/Forms/formFunctions";

export default function DriverHealthHistory(props) {
  const handleForm = (event) => {
    setFormValues(
      props.formInput,
      props.setFormInput,
      props.formData,
      props.setFormData,
      event
    );
  };

  const handleInsert = () => {
    props.insertForm(props.formData, props.URL, props.formId, props.setFormId);
  };
  return (
    <Box component="form">
      <Paper elevation={5} sx={{ p: 3, mt: 2 }}>
        <Grid container columns={8} spacing={2}>
          <Grid md={8}>
            <FormControl>
              <FormLabel id="DHH_A1">
                Have you ever had surgery? If "yes," please list and explain
                below.
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_A1"
                name="DHH_A1"
                value={props.formInput.DHH_A1}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={8}>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="Write your description..."
              style={{ width: 400 }}
              name="DHH_AN1"
              value={props.formInput.DHH_AN1}
              onChange={handleForm}
            />
          </Grid>
          <Grid md={8}>
            <FormControl>
              <FormLabel id="DHH_A2">
                Are you currently taking medications (prescription,
                over-the-counter, herbal remedies, diet supplements)? If "yes,"
                please describe below.
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_A2"
                name="DHH_A2"
                value={props.formInput.DHH_A2}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={8}>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="Write your description..."
              style={{ width: 400 }}
              name="DHH_AN2"
              value={props.formInput.DHH_AN2}
              onChange={handleForm}
            />
          </Grid>
          <Grid md={8}>
            <Typography variant="subtitle">
              Do you have or have you ever had:
            </Typography>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B1">
                1. Head/brain injuries or illnesses (e.g., concussion)
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B1"
                name="DHH_B1"
                value={props.formInput.DHH_B1}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B2">2. Seizures, epilepsy</FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B2"
                name="DHH_B2"
                value={props.formInput.DHH_B2}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B3">
                3. Eye problems (except glasses or contacts)
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B3"
                name="DHH_B3"
                value={props.formInput.DHH_B3}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B4">4. Ear and/or hearing problems</FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B4"
                name="DHH_B4"
                value={props.formInput.DHH_B4}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B5">
                5. Heart disease, heart attack, bypass, or other heart problems
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B5"
                name="DHH_B5"
                value={props.formInput.DHH_B5}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B6">
                6. Pacemaker,stents, implantable devices, or other heart
                procedures
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B6"
                name="DHH_B6"
                value={props.formInput.DHH_B6}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B7">7. High blood pressure</FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B7"
                name="DHH_B7"
                value={props.formInput.DHH_B7}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B8">8. High cholesterol</FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B8"
                name="DHH_B8"
                value={props.formInput.DHH_B8}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B9">
                9. Chronic (long-term) cough, shortness of breath, or other
                breathing problems
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B9"
                name="DHH_B9"
                value={props.formInput.DHH_B9}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B10">
                10. Lung disease (e.g., asthma)
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B10"
                name="DHH_B10"
                value={props.formInput.DHH_B10}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B11">
                11. Kidney problems, kidney stones, or pain/problems with
                urination
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B11"
                name="DHH_B11"
                value={props.formInput.DHH_B11}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B12">
                12. Stomach, liver, or digestive problems
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B12"
                name="DHH_B12"
                value={props.formInput.DHH_B12}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B13">
                13. Diabetes or blood sugar problems
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B13"
                name="DHH_B13"
                value={props.formInput.DHH_B13}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_BIn">Insulin Used</FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_BIn"
                name="DHH_BIn"
                value={props.formInput.DHH_BIn}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B14">
                14. Anxiety, depression, nervousness, other mental health
                problems
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B14"
                name="DHH_B14"
                value={props.formInput.DHH_B14}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B15">15. Fainting or passing out</FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B15"
                name="DHH_B15"
                value={props.formInput.DHH_B15}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B16">
                16. Dizziness, headaches, numbness, tingling, or memory loss
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B16"
                name="DHH_B16"
                value={props.formInput.DHH_B16}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B17">17. Unexplained weight loss</FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B17"
                name="DHH_B17"
                value={props.formInput.DHH_B17}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B18">
                18. Stroke, mini-stroke (TIA), paralysis, or weakness
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B18"
                name="DHH_B18"
                value={props.formInput.DHH_B18}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B19">
                19. Missing or limited use of arm, hand, finger, leg, foot, toe
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B19"
                name="DHH_B19"
                value={props.formInput.DHH_B19}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B20">20. Neck or back problems</FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B20"
                name="DHH_B20"
                value={props.formInput.DHH_B20}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B21">
                21. Bone, muscle, joint, or nerve problems
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B21"
                name="DHH_B21"
                value={props.formInput.DHH_B21}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B22">
                22. Blood clots or bleeding problems
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B22"
                name="DHH_B22"
                value={props.formInput.DHH_B22}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B23">23. Cancer</FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B23"
                name="DHH_B23"
                value={props.formInput.DHH_B23}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B24">
                24. Chronic (long-term) infection or other chronic diseases
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B24"
                name="DHH_B24"
                value={props.formInput.DHH_B24}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B25">
                25. Sleep disorders, pauses in breathing while asleep, daytime
                sleepiness, loud snoring
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B25"
                name="DHH_B25"
                value={props.formInput.DHH_B25}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B26">
                26. Have you ever had a sleep test (e.g., sleep apnea)?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B26"
                name="DHH_B26"
                value={props.formInput.DHH_B26}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B27">
                27. Have you ever spent a night in the hospital?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B27"
                name="DHH_B27"
                value={props.formInput.DHH_B27}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B28">
                28. Have you ever had a broken bone?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B28"
                name="DHH_B28"
                value={props.formInput.DHH_B28}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B29">
                29. Have you ever used or do you now use tobacco?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B29"
                name="DHH_B29"
                value={props.formInput.DHH_B29}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B30">
                30. Do you currently drink alcohol?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B30"
                name="DHH_B30"
                value={props.formInput.DHH_B30}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B31">
                31. Have you used an illegal substance within the past two
                years?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B31"
                name="DHH_B31"
                value={props.formInput.DHH_B31}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="DHH_B32">
                32. Have you ever failed a drug test or been dependent on an
                illegal substance?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_B32"
                name="DHH_B32"
                value={props.formInput.DHH_B32}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={8}>
            <FormControl>
              <FormLabel id="DHH_C1">
                Other health condition(s) not described above:
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_C1"
                name="DHH_C1"
                value={props.formInput.DHH_C1}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={8}>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="Write your description..."
              style={{ width: 400 }}
              name="DHH_CN1"
              value={props.formInput.DHH_CN1}
              onChange={handleForm}
            />
          </Grid>
          <Grid md={8}>
            <FormControl>
              <FormLabel id="DHH_C2">
                Did you answer "yes" to any of questions 1-32? If so, please
                comment further on those health conditions below.
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="DHH_C2"
                name="DHH_C2"
                value={props.formInput.DHH_C2}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Not sure"
                  control={<Radio />}
                  label="Not sure"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={8}>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="Write your description..."
              style={{ width: 400 }}
              name="DHH_CN2"
              value={props.formInput.DHH_CN2}
              onChange={handleForm}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
