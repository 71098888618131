export function setFormValues(
  stateInput,
  setterInput,
  stateData,
  setterData,
  event
) {
  setterInput({
    ...stateInput,
    [event.target.name]: event.target.value,
  });
  setterData({
    ...stateData,
    form: { ...stateInput },
  });
}
