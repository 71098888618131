import React, {useState, useEffect} from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import patientList from "../utilities/patientList";
import axios from "axios";

export default function NewDocument() {
  const [clients, setClients] = useState([]);
  const Employees = patientList();
  const [company, setCompany] = useState('');
  const [employee, setEmployee] = useState('');
  const [auth, setAuth] = useState('');

  useEffect(()=>{
    axios.get("https://localhost/ThePortClinic-API/getClients.php")
    .then((res)=>{
      res.data.forEach(element => {
        setClients(res.data);
      })  
    })
    .catch((error)=>{
      console.log(error);
    });
  },[]);

  const handleChangeCompany = (event) => {
    setCompany(event.target.value);
  };
  const handleChangeEmployee = (event) => {
    setEmployee(event.target.value);
  };
  const handleChangeAuth = (event) => {
    setAuth(event.target.value);
  };

  const companyOptions = () => {
    return (
      clients.map((item) => (
        <MenuItem value={item.id}>{item.company_name}</MenuItem>
      ))
    )
  }

  const employeeOptions = () => {
    return (
      Employees.rows.map((item) => (
        <MenuItem value={item.id}>{item.name}</MenuItem>
      ))
    )
  }

  return (
    <Box>
      <Typography variant="h4">New Document</Typography>
      <Paper elevation={5} sx={{ p: 3, mt: 2 }}>
        <Grid container spacing={2} columns={8}>
          <Grid md={4}>
            <FormControl fullWidth>
              <InputLabel id="company-label">Company</InputLabel>
              <Select
                labelId="company-label"
                id="company"
                value={company}
                label="Company"
                onChange={handleChangeCompany}
              >
                <MenuItem value="empty">--Not Assigned to Company--</MenuItem>
                {companyOptions()}
              </Select>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl fullWidth>
              <InputLabel id="employee-label">Employee</InputLabel>
              <Select
                labelId="employee-label"
                id="employee"
                value={employee}
                label="Employee"
                onChange={handleChangeEmployee}
              >
                <MenuItem value="empty">--Not Assigned to Specific Employee--</MenuItem>
                {employeeOptions()}
              </Select>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl fullWidth>
              <InputLabel id="auth-label">Authorization</InputLabel>
              <Select
                labelId="auth-label"
                id="auth"
                value={auth}
                label="Auth"
                onChange={handleChangeAuth}
              >
                <MenuItem value="empty">--Not Assigned to Specific Authorization--</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="DocType"
              label="Document Type"
            ></TextField>
          </Grid>
          <Grid md={8}>
            <FormGroup>
              <FormControlLabel control={<Checkbox />} label="Internal Document (Do Not Send to Client)" />
              <FormControlLabel control={<Checkbox />} label="Mark Service Complete" />
            </FormGroup>
          </Grid>
          <Grid md={8}>
            <Button variant="contained">Print Document Barcode</Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}
