import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { setFormValues } from "../../../../utilities/Forms/formFunctions";

export default function Testing(props) {
  const handleForm = (event) => {
    setFormValues(
      props.formInput,
      props.setFormInput,
      props.formData,
      props.setFormData,
      event
    );
    console.log(props.formInput);
  };
  return (
    <Box component="form">
      <Paper elevation={5} sx={{ p: 3, mt: 2 }}>
        <Grid container columns={10} spacing={2}>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="pulseRate"
              label="Pulse Rate"
              name="pulseRate"
              value={props.formInput.pulseRate}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <FormControl>
              <FormLabel id="pulseRythmRegular">
                Pulse rhythm regular.
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="pulseRythmRegular"
                name="pulseRythmRegular"
                value={props.formInput.pulseRythmRegular}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="heightFeet"
              label="Height (Feet)"
              name="heightFeet"
              value={props.formInput.heightFeet}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="heigtInches"
              label="Height (Inches)"
              name="heigtInches"
              value={props.formInput.heigtInches}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="weightPounds"
              label="Weight (Pounds)"
              name="weightPounds"
              value={props.formInput.weightPounds}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={10}>
            <hr></hr>
          </Grid>
          <Grid md={4}>
            <Typography variant="h6">Blood Pressure</Typography>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">Systolic</Typography>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">Diastolic</Typography>
          </Grid>
          <Grid md={4}>
            <Typography variant="subtitle">Sitting</Typography>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="systolic1"
              name="systolic1"
              value={props.formInput.systolic1}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="diastolic1"
              name="diastolic1"
              value={props.formInput.diastolic1}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={4}>
            <Typography variant="subtitle">
              Second Reading (optional)
            </Typography>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="systolic2"
              name="systolic2"
              value={props.formInput.systolic2}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="diastolic2"
              name="diastolic2"
              value={props.formInput.diastolic2}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={10}>
            <hr></hr>
          </Grid>
          <Grid md={2}>
            <Typography variant="h6">Urinalysis</Typography>
          </Grid>
          <Grid md={2}>
            <Typography variant="subtitle">Sp. Gr.</Typography>
          </Grid>
          <Grid md={2}>
            <Typography variant="subtitle">Protein</Typography>
          </Grid>
          <Grid md={2}>
            <Typography variant="subtitle">Blood</Typography>
          </Grid>
          <Grid md={2}>
            <Typography variant="subtitle">Sugar</Typography>
          </Grid>
          <Grid md={2}>
            <Typography variant="subtitle">
              Urinalysis is required. Numerical readings must be recorded.
            </Typography>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="spGr"
              name="spGr"
              value={props.formInput.spGr}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="protein"
              name="protein"
              value={props.formInput.protein}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="blood"
              name="blood"
              value={props.formInput.blood}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="sugar"
              name="sugar"
              value={props.formInput.sugar}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={10} sx={{ fontStyle: "italic" }}>
            <Typography variant="subtitle">
              Protein, blood, or sugar in the urine may be an indication for
              further testing to rule out any underlying medical problem.
            </Typography>
          </Grid>
          <Grid md={10}>
            <Typography variant="subtitle">
              Other testing if indicated.
            </Typography>
          </Grid>
          <Grid md={10}>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="Notes..."
              style={{ width: 400 }}
              name="otherTests"
              value={props.formInput.otherTests}
              onChange={handleForm}
            />
          </Grid>
          <Grid md={10}>
            <hr></hr>
          </Grid>
          <Grid md={10}>
            <Typography variant="h6">Vision</Typography>
          </Grid>
          <Grid md={10} sx={{ fontStyle: "italic" }}>
            <Typography variant="subtitle">
              Standard is at least 20/40 acuity (Snellen) in each eye with or
              without correction. At least 70° field of vision in horizontal
              meridian measured in each eye. The use of corrective lenses should
              be noted on the Medical Examiner's Certificate.
            </Typography>
          </Grid>
          <Grid md={2.5}>
            <Typography variant="h6">Acuity</Typography>
          </Grid>
          <Grid md={2.5}>
            <Typography variant="subtitle">Uncorrected</Typography>
          </Grid>
          <Grid md={2.5}>
            <Typography variant="subtitle">Corrected</Typography>
          </Grid>
          <Grid md={2.5}>
            <Typography variant="subtitle">
              Horizontal Field of Vision
            </Typography>
          </Grid>
          <Grid md={2.5}>
            <Typography variant="subtitle">Right Eye</Typography>
          </Grid>
          <Grid md={2.5}>
            <TextField
              required
              fullWidth
              id="uncorrectedRight"
              label="20/__"
              name="uncorrectedRight"
              value={props.formInput.uncorrectedRight}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={2.5}>
            <TextField
              required
              fullWidth
              id="correctedRight"
              label="20/__"
              name="correctedRight"
              value={props.formInput.correctedRight}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={2.5}>
            <TextField
              required
              fullWidth
              id="horizontalRight"
              label="__degrees"
              name="horizontalRight"
              value={props.formInput.horizontalRight}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={2.5}>
            <Typography variant="subtitle">Left Eye</Typography>
          </Grid>
          <Grid md={2.5}>
            <TextField
              required
              fullWidth
              id="uncorrectedLeft"
              label="20/__"
              name="uncorrectedLeft"
              value={props.formInput.uncorrectedLeft}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={2.5}>
            <TextField
              required
              fullWidth
              id="correctedLeft"
              label="20/__"
              name="correctedLeft"
              value={props.formInput.correctedLeft}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={2.5}>
            <TextField
              required
              fullWidth
              id="horizontalLeft"
              label="__degrees"
              name="horizontalLeft"
              value={props.formInput.horizontalLeft}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={2.5}>
            <Typography variant="subtitle">Both Eyes</Typography>
          </Grid>
          <Grid md={2.5}>
            <TextField
              required
              fullWidth
              id="uncorrectedBoth"
              label="20/__"
              name="uncorrectedBoth"
              value={props.formInput.uncorrectedBoth}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={2.5}>
            <TextField
              required
              fullWidth
              id="correctedBoth"
              label="20/__"
              name="correctedBoth"
              value={props.formInput.correctedBoth}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={5}>
            <FormControl>
              <FormLabel id="Vision_Q1">
                Applicant can recognize and distinguish among traffic control
                signals and devices showing red, green, and amber colors.
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="Vision_Q1"
                name="Vision_Q1"
                value={props.formInput.Vision_Q1}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={5}>
            <FormControl>
              <FormLabel id="Vision_Q2">Monocular vision.</FormLabel>
              <RadioGroup
                row
                aria-labelledby="Vision_Q2"
                name="Vision_Q2"
                value={props.formInput.Vision_Q2}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={5}>
            <FormControl>
              <FormLabel id="Vision_Q3">
                Referred to ophthalmologist or optometrist?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="Vision_Q3"
                name="Vision_Q3"
                value={props.formInput.Vision_Q3}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={5}>
            <FormControl>
              <FormLabel id="Vision_Q4">
                Received documentation from ophthalmologist or optometrist?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="Vision_Q4"
                name="Vision_Q4"
                value={props.formInput.Vision_Q4}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={10}>
            <hr></hr>
          </Grid>
          <Grid md={10}>
            <Typography variant="h6">Hearing</Typography>
          </Grid>
          <Grid md={10} sx={{ fontStyle: "italic" }}>
            <Typography variant="subtitle">
              Standard: Must first perceive whispered voice at not less than 5
              feet OR average hearing loss of less than or equal to 40 dB, in
              better ear (with or without hearing aid)
            </Typography>
          </Grid>
          <Grid md={4}>
            <Typography variant="subtitle">
              Check if hearing aid used for test:
            </Typography>
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox checked={props.formInput.HA_Right ? true : false} onChange={(e)=>{
                  const value = e.target.checked ? "on" : "";
                  props.setFormInput({...props.formInput, HA_Right: value});
                }}/>
              }
              label="Right Ear"
              name="HA_Right"
              value={props.formInput.HA_Right}
              onChange={handleForm}
            />
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox checked={props.formInput.HA_Left ? true : false} onChange={(e)=>{
                  const value = e.target.checked ? "on" : "";
                  props.setFormInput({...props.formInput, HA_Left: value});
                }}/>
              }
              label="Left Ear"
              name="HA_Left"
              value={props.formInput.HA_Left}
              onChange={handleForm}
            />
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox checked={props.formInput.HA_Neither ? true : false} onChange={(e)=>{
                  const value = e.target.checked ? "on" : "";
                  props.setFormInput({...props.formInput, HA_Neither: value});
                }}/>
              }
              label="Neither"
              name="HA_Neither"
              value={props.formInput.HA_Neither}
              onChange={handleForm}
            />
          </Grid>
          <Grid md={10}>
            <Typography variant="subtitle">
              <b>Whisper Test Results</b>
            </Typography>
          </Grid>
          <Grid md={6}>
            <Typography variant="subtitle">
              Record distance (in feet) from driver at which a forced whispered
              voice can first be heard.
            </Typography>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="WT_Right"
              label="Right Eear"
              name="WT_Right"
              value={props.formInput.WT_Right}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="WT_Left"
              label="Left Ear"
              name="WT_Left"
              value={props.formInput.WT_Left}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={10}>
            <Typography variant="h6">OR</Typography>
          </Grid>
          <Grid md={10}>
            <Typography variant="subtitle">
              <b>Audiometric Test Results</b>
            </Typography>
          </Grid>
          <Grid md={5}>
            <Typography variant="subtitle">Right Ear</Typography>
          </Grid>
          <Grid md={5}>
            <Typography variant="subtitle">Left Ear</Typography>
          </Grid>
          <Grid md={1.6}>
            <TextField
              required
              fullWidth
              id="AT_500Right"
              label="500 Hz"
              name="AT_500Right"
              value={props.formInput.AT_500Right}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={1.6}>
            <TextField
              required
              fullWidth
              id="AT_1000Right"
              label="1000 Hz"
              name="AT_1000Right"
              value={props.formInput.AT_1000Right}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={1.6}>
            <TextField
              required
              fullWidth
              id="AT_2000Right"
              label="2000 Hz"
              name="AT_2000Right"
              value={props.formInput.AT_2000Right}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={1.6}>
            <TextField
              required
              fullWidth
              id="AT_500Left"
              label="500 Hz"
              name="AT_500Left"
              value={props.formInput.AT_500Left}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={1.6}>
            <TextField
              required
              fullWidth
              id="AT_1000Left"
              label="1000 Hz"
              name="AT_1000Left"
              value={props.formInput.AT_1000Left}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={1.6}>
            <TextField
              required
              fullWidth
              id="AT_2000Left"
              label="2000 Hz"
              name="AT_2000Left"
              value={props.formInput.AT_2000Left}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={5}>
            <TextField
              required
              fullWidth
              id="AT_AvarageRight"
              label="Average (Right)"
              name="AT_AvarageRight"
              value={props.formInput.AT_AvarageRight}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={5}>
            <TextField
              required
              fullWidth
              id="AT_AvarageLeft"
              label="Average (Left)"
              name="AT_AvarageLeft"
              value={props.formInput.AT_AvarageLeft}
              onChange={handleForm}
            ></TextField>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
