import React, { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../utilities/globalContext";
import axios from "axios";
import Services from "../components/services";
import { Outlet, Link, Navigate, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTab } from "@mui/base";

export default function Services() {
  const { URL } = useContext(GlobalContext);
  const [serviceValue, setServiceValue] = useState("");
  const [priceValue, setPriceValue] = useState(0);
  const [deleteStatus, setDeleteStatus] = useState(0);
  const [insertStatus, setInsertStatus] = useState(0);
  const [updateStatus, setUpdateStatus] = useState(0);

  //Handle input update
  const [inputService, setInputService] = useState({});
  const updateService = (event) => {
    setInputService({
      ...inputService,
      [event.target.name]: event.target.value,
    });
    console.log(inputService);
  };

  //Insert service
  const insertServiceURL = `${URL}ThePortClinic-API/insertService.php`;
  const insertService = (apiURL) => {
    axios
      .post(apiURL, inputService)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === true) {
          alert("Success");
          setInsertStatus(insertStatus+1);
          setInputService({service:"", price:""});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Get services
  const getServicesURL = `${URL}ThePortClinic-API/getServices.php`;
  const [serviceData, setServicetData] = useState([]);
  const getServices = (apiURL) => {
    axios
      .get(apiURL)
      .then((response) => {
        setServicetData(response.data);
        console.log(serviceData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getServices(getServicesURL);
  }, [updateStatus ,deleteStatus, insertStatus]);

  return (
    <Box>
      <Paper elevation={5} sx={{ p: 3, mt: 2 }}>
        <Grid container spacing={2} columns={8}>
          <Grid md={3}>
            <TextField
              fullWidth
              id="service"
              name="service"
              label="Service"
              onChange={updateService}
              value={inputService.service}
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              fullWidth
              id="price"
              name="price"
              label="Price"
              onChange={updateService}
              value={inputService.price}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <Button
              size="large"
              variant="contained"
              onClick={() => {
                insertService(insertServiceURL);
              }}
            >
              Add
            </Button>
          </Grid>
          <Grid md={8}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Service</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <Services
                    services={serviceData}
                    url={URL}
                    updateStatus={updateStatus}
                    setUpdateStatus={setUpdateStatus}
                    deleteStatus={deleteStatus}
                    setDeleteStatus={setDeleteStatus}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
