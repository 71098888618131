import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { TabList, TabContext, TabPanel } from "@mui/lab";
import Typography from "@mui/material/Typography";
import AuthorizationForm from "../components/Authorizations/AuthorizationForm";
import EmployeeInformation from "../components/Authorizations/EmployeeInformation";
import ClientDocuments from "../components/Clients/ClientDocuments";
import DOTPhysical from "../components/Forms/DOTPhysical";
import DWC073 from "../components/Forms/DWC073";
import Physical from "../components/Forms/Physical";
import WorkersComp from "../components/Forms/WorkersComp";
import ReleaseMedInf from "../components/Forms/ReleaseMedInf";
import Audiogram from "../components/Forms/Audiogram";
import InjectionConsent from "../components/Forms/InjectionConsent";
import Tuberculosis from "../components/Forms/Tuberculosis";
import RapidDrugScreen from "../components/Forms/RapidDrugScreen";
import ClientPopUp from "../components/clientPopUp";

export default function NewAuthorizationInfo() {
  const location = useLocation();
  const { client_id, employee_id } = location.state;
  const [clientId, setClientId] = useState(client_id);
  const [employeeId, setEmployeeId] = useState(employee_id);
  const [authId, setAuthId] = useState("");
  const [authDate, setAuthDate] = useState("");
  const [authInput, setAuthInput] = useState({
    company_contact: "",
    billing_type: "",
  });
  const [selectedServices, setSelectedServices] = useState([]);
  const [authForms, setAuthForms] = useState({});
  const [formInput, setFormInput] = useState({});
  const [serviceCounter, setServiceCounter] = useState(5);

  const handleClientStateChange = (newState) => {
    setClientId(newState);
  };

  const handleEmployeeStateChange = (newState) => {
    setEmployeeId(newState);
  };

  //Tabs state
  const [value, setValue] = useState("0");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleServiceTabs = () => {
    return selectedServices.map((item, index) => {
      const tabValue = 6 + index;
      console.log("Tab render function");
      return <Tab label={item.service} value={tabValue}></Tab>;
    });
  };

  const handleServiceDocs = () => {
    return selectedServices.map((item, index) => {
      const tabValue = 6 + index;
      return (
        <TabPanel value={tabValue}>
          <ClientDocuments
            authStatus={true}
            authId={authId}
            clientId={clientId}
            employeeId={employeeId}
          />
        </TabPanel>
      );
    });
  };

  useEffect(() => {
    console.log(`Form: ${authForms.DOTPhysical}`);
  }, [authForms]);
  return (
    <Box>
      <Typography variant="h4">New Authorization</Typography>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Details" value="0"></Tab>
            <Tab label="Employee Information" value="1"></Tab>
            <Tab label="Documents" value="2"></Tab>
            {authForms.DOTPhysical && (
              <Tab label="DOT Physical" value="3"></Tab>
            )}
            {authForms.NonDOTPhysical && <Tab label="Physical" value="4"></Tab>}
            {authForms.WorkersComp && (
              <Tab label="Workers Comp" value="5"></Tab>
            )}
            {(authForms.RapidDrugScreen5 ||
              authForms.RapidDrugScreen10 ||
              authForms.RapidDrugScreen12 ||
              authForms.RapidDrugScreen16) && (
              <Tab label="Rapid Drug Screen" value="6"></Tab>
            )}
            {authForms.InjectionConsent && (
              <Tab label="Injection Consent Form" value="7"></Tab>
            )}
            {authForms.ReleaseMedInf && (
              <Tab label="Authorization to Release" value="8"></Tab>
            )}
            {authForms.Tuberculosis && (
              <Tab label="Tuberculosis Exam Form" value="9"></Tab>
            )}
            {authForms.Audiogram && <Tab label="Audiogram" value="10"></Tab>}
            {selectedServices.length > 0 && handleServiceTabs()}
          </TabList>
        </Box>
        <TabPanel value="0">
          <AuthorizationForm
            clientId={clientId}
            changeClientId={handleClientStateChange}
            employeeId={employeeId}
            changeEmployeeId={handleEmployeeStateChange}
            authInput={authInput}
            setAuthInput={setAuthInput}
            authId={authId}
            setAuthId={setAuthId}
            selectedServices={selectedServices}
            setSelectedServices={setSelectedServices}
            authDate={authDate}
            setAuthDate={setAuthDate}
            authForms={authForms}
            setAuthForms={setAuthForms}
          />
        </TabPanel>
        <TabPanel value="1">
          <EmployeeInformation
            clientId={clientId}
            changeClientId={handleClientStateChange}
            employeeId={employeeId}
            changeEmployeeId={handleEmployeeStateChange}
          />
        </TabPanel>
        <TabPanel value="2">
          <ClientDocuments
            authStatus={true}
            authId={authId}
            clientId={clientId}
            employeeId={employeeId}
          />
        </TabPanel>
        {authForms.DOTPhysical && (
          <TabPanel value="3">
            <ClientPopUp buttonText="Client Screen">
              <DOTPhysical
                clientId={clientId}
                employeeId={employeeId}
                authId={authId}
                formInput={formInput}
                setFormInput={setFormInput}
                url={URL}
                clientScreen={true}
              />
            </ClientPopUp>
            <DOTPhysical
              clientId={clientId}
              employeeId={employeeId}
              authId={authId}
              formInput={formInput}
              setFormInput={setFormInput}
              url={URL}
            />
          </TabPanel>
        )}
        {authForms.NonDOTPhysical && (
          <TabPanel value="4">
            <Physical
              clientId={clientId}
              employeeId={employeeId}
              authId={authId}
              formInput={formInput}
              setFormInput={setFormInput}
              url={URL}
            />
          </TabPanel>
        )}
        {authForms.WorkersComp && (
          <TabPanel value="5">
            <WorkersComp
              clientId={clientId}
              employeeId={employeeId}
              authId={authId}
            />
          </TabPanel>
        )}
        {(authForms.RapidDrugScreen5 ||
          authForms.RapidDrugScreen10 ||
          authForms.RapidDrugScreen12 ||
          authForms.RapidDrugScreen16) && (
          <TabPanel value="6">
            <RapidDrugScreen
              clientId={clientId}
              employeeId={employeeId}
              authId={authId}
              panels={
                (authForms.RapidDrugScreen5 && "5") ||
                (authForms.RapidDrugScreen10 && "10") ||
                (authForms.RapidDrugScreen12 && "12") ||
                (authForms.RapidDrugScreen16 && "16")
              }
            />
          </TabPanel>
        )}
        {authForms.InjectionConsent && (
          <TabPanel value="7">
            <InjectionConsent
              clientId={clientId}
              employeeId={employeeId}
              authId={authId}
            />
          </TabPanel>
        )}
        {authForms.ReleaseMedInf && (
          <TabPanel value="8">
            <ReleaseMedInf
              clientId={clientId}
              employeeId={employeeId}
              authId={authId}
            />
          </TabPanel>
        )}
        {authForms.Tuberculosis && (
          <TabPanel value="9">
            <Tuberculosis
              clientId={clientId}
              employeeId={employeeId}
              authId={authId}
            />
          </TabPanel>
        )}
        {authForms.Audiogram && (
          <TabPanel value="10">
            <Audiogram
              clientId={clientId}
              employeeId={employeeId}
              authId={authId}
            />
          </TabPanel>
        )}
        {selectedServices.length > 0 && handleServiceDocs()}
      </TabContext>
    </Box>
  );
}
