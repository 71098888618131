import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import patientList from "../utilities/patientList";
import scanList from "../utilities/scanList";

const patients = patientList();
const scans = scanList();

export default function Dashboard() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3} columns={6}>
        <Grid xs={6}>
          <Typography variant="h4">Dashboard</Typography>
        </Grid>
        <Grid xs={3} container spacing={1} columns={6}>
          <Grid xs={3}>
            <Paper elevation={5} sx={{ p: 3 }}>
              <Typography variant="h5">
                Today's Patients: {patients.rows.length}
              </Typography>
            </Paper>
          </Grid>
          <Grid xs={6}>
            <Paper elevation={5} sx={{ height: 400 }}>
              <DataGrid
                rows={patients.rows}
                columns={patients.columns}
                key={patients.rows.id}
                rowHeight={30}
                pageSize={10}
                rowsPerPageOptions={[5]}
                checkboxSelection
                components={{
                  Toolbar: GridToolbar,
                }}
              />
            </Paper>
          </Grid>
        </Grid>
        <Grid xs={3} container spacing={1} columns={6}>
          <Grid xs={3}>
            <Paper elevation={5} sx={{ p: 3 }}>
              <Typography variant="h5">
                Today's Scans: {scans.rows.length}
              </Typography>
            </Paper>
          </Grid>
          <Grid xs={6}>
            <Paper elevation={5} sx={{ height: 400 }}>
              <DataGrid
                rows={scans.rows}
                columns={scans.columns}
                rowHeight={30}
                pageSize={10}
                rowsPerPageOptions={[5]}
                checkboxSelection
                components={{
                  Toolbar: GridToolbar,
                }}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
