import React, { createContext, useState } from "react";

export const GlobalContext = createContext();
const GlobalContextPovider = (props) => {
  const [login, setLogin] = useState(false);
  const [user, setUser] = useState("");
  const [userId, setUserId] = useState(0);
  const [userRole, setUserRole] = useState(0);
  const [firstLogin, setFirstLogin] = useState(0);
  const [userStatus, setUserStatus] = useState(0);
  const URL = "https://clients.theportclinic.com/";

  return (
    <GlobalContext.Provider
      value={{
        login,
        setLogin,
        user,
        setUser,
        userId,
        setUserId,
        userRole,
        setUserRole,
        firstLogin,
        setFirstLogin,
        userStatus,
        setUserStatus,
        URL,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextPovider;
