import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { setFormValues } from "../../../../utilities/Forms/formFunctions";

export default function PersonalInfo(props) {

  const handleForm = (event)=>{
    setFormValues(props.formInput, props.setFormInput, props.formData, props.setFormData, event);
    console.log(props.formInput);
  }
  return (
    <Box component="form">
      <Paper elevation={5} sx={{ p: 3, mt: 2 }}>
        <Grid container columns={8} spacing={2}>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="driversLicenseNumber"
              label="Drivers License Number"
              name="driversLicenseNumber"
              value={props.formInput.driversLicenseNumber}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="stateProvince"
              label="Issuing Sate/Province"
              name="stateProvince"
              value={props.formInput.stateProvince}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="gender">
                Gender
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="gender"
                name="gender"
                value={props.formInput.gender}
                onChange={handleForm}
              >
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label="Female"
                />
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label="Other"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="clpCdl">
                CLP/CDL Applicant/Holder
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="clpCdl"
                name="clpCdl"
                value={props.formInput.clpCdl}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="driverIdVerified"
              label="Driver ID Verified By"
              name="driverIdVerified"
              value={props.formInput.driverIdVerified}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={4}>
            <FormControl>
              <FormLabel id="medCert">
                Has your USDOT/FMCSA medical certificate ever been denied or
                issued for less than 2 years?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="medCert"
                name="medCert"
                value={props.formInput.medCert}
                onChange={handleForm}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel value="Not sure" control={<Radio />} label="Not Sure" />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
