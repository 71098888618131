import axios from "axios";

function insertForm(data, URL){
  return axios
    .post(`${URL}ThePortClinic-API/insertNewForm.php`, data)
    .then((response) => {
      if (response.data.status === true) {
        alert("Success!");
      } else {
        alert("Error inserting form");
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export default insertForm;
