import React, { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../utilities/globalContext";
import axios from "axios";
import ClientEmployees from "../clientEmployees";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Modal from "@mui/material/Modal";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import ClientPopUp from "../clientPopUp";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function ClientEmployees(props) {
  //Context
  const { URL } = useContext(GlobalContext);
  const [deleteStatus, setDeleteStatus] = useState(0);
  const [insertStatus, setInsertStatus] = useState(0);
  const [updateStatus, setUpdateStatus] = useState(0);
  const [employeeId, setEmployeeId] = useState(0);
  const [edit, setEdit] = useState(false);
  const [date, setDate] = useState("");

  //Employee form state
  const [employeeInput, setEmployeeInput] = useState({});
  const updateEmployeeInput = (e) => {
    setEmployeeInput({
      ...employeeInput,
      [e.target.name]: e.target.value,
      clientId: props.clientId,
      dob: date,
    });
  };

  //Insert employees function
  const employeeURL = `${URL}ThePortClinic-API/insertNewEmployee.php`;
  const insertNewData = (data, apiURL) => {
    axios
      .post(apiURL, data)
      .then((res) => {
        if (res.data.status === false) {
          console.log("No se pudo");
          return;
        } else if (res.data.status === true) {
          console.log("Si se pudo");
          alert("Success!");
          setInsertStatus(insertStatus + 1);
          setOpen(false);
          return;
        } else {
          console.log(res);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  };

  //Update employee info
  const updateURL = `${URL}ThePortClinic-API/updateEmployee.php`;
  const updateEmployee = (data, apiURL) => {
    console.log(data);
    axios
      .post(apiURL, data)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === true) {
          alert("Success!");
          setUpdateStatus(updateStatus + 1);
          setOpen(false);
          setEdit(false);
          setDate("");
          setEmployeeInput(initialFormState);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Get employees contacts function
  const getEmployeesURL = `${URL}ThePortClinic-API/getClientEmployees.php?client_id=${props.clientId}`;
  const [employeeData, setEmployeetData] = useState([]);
  const getEmployees = (apiURL) => {
    axios
      .get(apiURL)
      .then((response) => {
        setEmployeetData(response.data);
        console.log(employeeData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Get employee
  const getEmployeeURL = `${URL}ThePortClinic-API/getEmployee.php?id=${employeeId}`;
  const getEmployee = (apiURL) => {
    axios
      .get(apiURL)
      .then((response) => {
        setEmployeeInput({
          ...response.data,
        });
        console.log(employeeInput);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Get states
  const getStatesURL = `${URL}ThePortClinic-API/getStates.php`;
  const [statesData, setStatestData] = useState([]);
  const getStates = (apiURL) => {
    axios
      .get(apiURL)
      .then((response) => {
        setStatestData(response.data);
        console.log(serviceData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Modal toggle
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    setEdit(false);
    setDate("");
    setEmployeeInput(initialFormState);
  };
  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    setEmployeeInput(initialFormState);
    console.log(date._d);
  };

  //Input data collecting
  const handleChange = (event) => {
    updateEmployeeInput(event);
  };

  //State options
  const selectOptions = () => {
    try {
      return statesData.map((item) => (
        <MenuItem value={item.state}>{item.state}</MenuItem>
      ));
    } catch {
      return <MenuItem value={0}>No states...</MenuItem>;
    }
  };

  const initialFormState = {};
  Array.from(document.querySelectorAll("input")).forEach((input) => {
    initialFormState[input.name] = "";
  });

  //Gets data from db after any change happens to employee table
  useEffect(() => {
    getEmployees(getEmployeesURL);
    getStates(getStatesURL);
  }, [insertStatus, deleteStatus, updateStatus]);

  //Gets selected employee when edit is active
  useEffect(() => {
    if (edit === true) {
      getEmployee(getEmployeeURL);
    }
  }, [edit]);

  return (
    <Box>
      <Paper elevation={5} sx={{ p: 3, mt: 2 }}>
        <Grid container spacing={2} columns={8}>
          <Grid mdOffset="auto">
            <ClientPopUp buttonText="Add Employee">
              <Box component="form" sx={style}>
                <Grid container columns={8} spacing={2}>
                  <Grid md={8}>
                    <Typography variant="h4">
                      Enter employee information
                    </Typography>
                  </Grid>
                  <Grid md={3}>
                    <TextField
                      required
                      fullWidth
                      id="first_name"
                      name="first_name"
                      label="Employee First Name"
                      onChange={handleChange}
                      value={employeeInput.first_name}
                    ></TextField>
                  </Grid>
                  <Grid md={2}>
                    <TextField
                      required
                      fullWidth
                      id="middle_name"
                      name="middle_name"
                      label="Employee Middle Name"
                      onChange={handleChange}
                      value={employeeInput.middle_name}
                    ></TextField>
                  </Grid>
                  <Grid md={3}>
                    <TextField
                      required
                      fullWidth
                      id="last_name"
                      name="last_name"
                      label="Employee Last Name"
                      onChange={handleChange}
                      value={employeeInput.last_name}
                    ></TextField>
                  </Grid>
                  <Grid md={2}>
                    <FormControl fullWidth>
                      <InputLabel id="gender-label">Gender</InputLabel>
                      <Select
                        labelId="gender-label"
                        id="gender"
                        name="gender"
                        onChange={handleChange}
                        value={
                          !employeeInput.gender ? "" : employeeInput.gender
                        }
                        label="Gender"
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid md={4}>
                    <TextField
                      required
                      fullWidth
                      id="address"
                      name="address"
                      label="Employee Adress"
                      onChange={handleChange}
                      value={employeeInput.address}
                    ></TextField>
                  </Grid>
                  <Grid md={2}>
                    <TextField
                      required
                      fullWidth
                      id="suite"
                      name="suite"
                      label="Suite"
                      onChange={handleChange}
                      value={employeeInput.suite}
                    ></TextField>
                  </Grid>
                  <Grid md={4}>
                    <TextField
                      required
                      fullWidth
                      id="city"
                      name="city"
                      onChange={handleChange}
                      value={employeeInput.city}
                      label="City"
                    ></TextField>
                  </Grid>
                  <Grid md={2}>
                    <FormControl fullWidth>
                      <InputLabel id="state-label">State</InputLabel>
                      <Select
                        labelId="state-label"
                        id="state"
                        name="state"
                        onChange={handleChange}
                        value={employeeInput.state}
                        label="State"
                      >
                        {selectOptions()}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid md={2}>
                    <TextField
                      required
                      fullWidth
                      id="zip"
                      name="zip"
                      onChange={handleChange}
                      value={employeeInput.zip}
                      label="Zip"
                    ></TextField>
                  </Grid>
                  <Grid md={3}>
                    <TextField
                      required
                      fullWidth
                      id="phone"
                      name="phone"
                      onChange={handleChange}
                      value={employeeInput.phone}
                      label="Phone"
                    ></TextField>
                  </Grid>
                  <Grid md={3}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      name="email"
                      onChange={handleChange}
                      value={employeeInput.email}
                      label="Email"
                    ></TextField>
                  </Grid>
                  <Grid md={2}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        id="dob"
                        name="dob"
                        label="Date of birth"
                        onChange={(newDate) => {
                          setDate(newDate);
                          handleChange;
                        }}
                        value={date}
                        renderInput={(params) => (
                          <TextField {...params} required />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid md={4}>
                    <TextField
                      required
                      fullWidth
                      id="ssn"
                      name="ssn"
                      onChange={handleChange}
                      value={employeeInput.ssn}
                      label="Social Security Number"
                    ></TextField>
                  </Grid>
                  <Grid md={4}>
                    <TextField
                      required
                      fullWidth
                      id="dln"
                      name="dln"
                      onChange={handleChange}
                      value={employeeInput.dln}
                      label="Drivers License Number"
                    ></TextField>
                  </Grid>
                  <Grid md={8}>
                    <Typography variant="h">Add extra notes:</Typography>
                  </Grid>
                  <Grid md={8}>
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={3}
                      placeholder="Notes..."
                      style={{ width: 400 }}
                      id="notes"
                      name="notes"
                      onChange={handleChange}
                      value={employeeInput.notes}
                    />
                  </Grid>
                  <Grid md={2}>
                    {edit === false ? (
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() => {
                          insertNewData(employeeInput, employeeURL);
                        }}
                      >
                        Save employee
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() => {
                          updateEmployee(employeeInput, updateURL);
                        }}
                      >
                        Update employee
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </ClientPopUp>
          </Grid>
          <Grid md={8}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Phone No.</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Social</TableCell>
                    <TableCell>DOB</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <ClientEmployees
                    employees={employeeData}
                    url={URL}
                    deleteStatus={deleteStatus}
                    setDeleteStatus={setDeleteStatus}
                    setEdit={setEdit}
                    setOpen={setOpen}
                    setEmployeeId={setEmployeeId}
                    setDate={setDate}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box component="form" sx={style}>
                <Grid container columns={8} spacing={2}>
                  <Grid md={8}>
                    <Typography variant="h4">
                      Enter employee information
                    </Typography>
                  </Grid>
                  <Grid md={3}>
                    <TextField
                      required
                      fullWidth
                      id="first_name"
                      name="first_name"
                      label="Employee First Name"
                      onChange={handleChange}
                      value={employeeInput.first_name}
                    ></TextField>
                  </Grid>
                  <Grid md={2}>
                    <TextField
                      required
                      fullWidth
                      id="middle_name"
                      name="middle_name"
                      label="Employee Middle Name"
                      onChange={handleChange}
                      value={employeeInput.middle_name}
                    ></TextField>
                  </Grid>
                  <Grid md={3}>
                    <TextField
                      required
                      fullWidth
                      id="last_name"
                      name="last_name"
                      label="Employee Last Name"
                      onChange={handleChange}
                      value={employeeInput.last_name}
                    ></TextField>
                  </Grid>
                  <Grid md={2}>
                    <FormControl fullWidth>
                      <InputLabel id="gender-label">Gender</InputLabel>
                      <Select
                        labelId="gender-label"
                        id="gender"
                        name="gender"
                        onChange={handleChange}
                        value={
                          !employeeInput.gender ? "" : employeeInput.gender
                        }
                        label="Gender"
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid md={4}>
                    <TextField
                      required
                      fullWidth
                      id="address"
                      name="address"
                      label="Employee Adress"
                      onChange={handleChange}
                      value={employeeInput.address}
                    ></TextField>
                  </Grid>
                  <Grid md={2}>
                    <TextField
                      required
                      fullWidth
                      id="suite"
                      name="suite"
                      label="Suite"
                      onChange={handleChange}
                      value={employeeInput.suite}
                    ></TextField>
                  </Grid>
                  <Grid md={4}>
                    <TextField
                      required
                      fullWidth
                      id="city"
                      name="city"
                      onChange={handleChange}
                      value={employeeInput.city}
                      label="City"
                    ></TextField>
                  </Grid>
                  <Grid md={2}>
                    <FormControl fullWidth>
                      <InputLabel id="state-label">State</InputLabel>
                      <Select
                        labelId="state-label"
                        id="state"
                        name="state"
                        onChange={handleChange}
                        value={employeeInput.state}
                        label="State"
                      >
                        {selectOptions()}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid md={2}>
                    <TextField
                      required
                      fullWidth
                      id="zip"
                      name="zip"
                      onChange={handleChange}
                      value={employeeInput.zip}
                      label="Zip"
                    ></TextField>
                  </Grid>
                  <Grid md={3}>
                    <TextField
                      required
                      fullWidth
                      id="phone"
                      name="phone"
                      onChange={handleChange}
                      value={employeeInput.phone}
                      label="Phone"
                    ></TextField>
                  </Grid>
                  <Grid md={3}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      name="email"
                      onChange={handleChange}
                      value={employeeInput.email}
                      label="Email"
                    ></TextField>
                  </Grid>
                  <Grid md={2}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        id="dob"
                        name="dob"
                        label="Date of birth"
                        onChange={(newDate) => {
                          setDate(newDate);
                          handleChange;
                        }}
                        value={date}
                        renderInput={(params) => (
                          <TextField {...params} required />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid md={4}>
                    <TextField
                      required
                      fullWidth
                      id="ssn"
                      name="ssn"
                      onChange={handleChange}
                      value={employeeInput.ssn}
                      label="Social Security Number"
                    ></TextField>
                  </Grid>
                  <Grid md={4}>
                    <TextField
                      required
                      fullWidth
                      id="dln"
                      name="dln"
                      onChange={handleChange}
                      value={employeeInput.dln}
                      label="Drivers License Number"
                    ></TextField>
                  </Grid>
                  <Grid md={8}>
                    <Typography variant="h">Add extra notes:</Typography>
                  </Grid>
                  <Grid md={8}>
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={3}
                      placeholder="Notes..."
                      style={{ width: 400 }}
                      id="notes"
                      name="notes"
                      onChange={handleChange}
                      value={employeeInput.notes}
                    />
                  </Grid>
                  <Grid md={2}>
                    {edit === false ? (
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() => {
                          insertNewData(employeeInput, employeeURL);
                        }}
                      >
                        Save employee
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() => {
                          updateEmployee(employeeInput, updateURL);
                        }}
                      >
                        Update employee
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Modal>
          </div>
        </Grid>
      </Paper>
    </Box>
  );
}
