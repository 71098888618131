import React, { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../utilities/globalContext";
import axios from "axios";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function DWC073() {
  //Context
  const { URL } = useContext(GlobalContext);
  //Form related states
  const [formInput, setFormInput] = useState({});
  const [dateSent, setDateSent] = useState("");
  const [dateInjury, setDateInjury] = useState("");
  const [dateReturn, setDateReturn] = useState("(date to be determined...)");
  const [dateReturnEx, setDateReturnEx] = useState(
    "(date to be determined...)"
  );
  const [dateFollowupServices1, setDateFollowupServices1] = useState("");
  const [dateFollowupServices2, setDateFollowupServices2] = useState("");
  const [dateFollowupServices3, setDateFollowupServices3] = useState("");
  const [dateFollowupServices4, setDateFollowupServices4] = useState("");
  const [dateOfVisit, setDateOfVisit] = useState("");

  //Update form input
  const updateFormInput = (event) => {
    setFormInput({
      ...formInput,
      [event.target.name]: event.target.value,
      dateSent: dateSent,
      dateInjury: dateInjury,
      dateReturn: dateReturn,
      dateReturnEx: dateReturnEx,
      dateFollowupServices1: dateFollowupServices1,
      dateFollowupServices2: dateFollowupServices2,
      dateFollowupServices3: dateFollowupServices3,
      dateFollowupServices4: dateFollowupServices4,
      dateOfVisit: dateOfVisit,
    });
    console.log(formInput);
  };

  const handleChange = (event) => {
    updateFormInput(event);
  };

  //Handles date change in section II
  useEffect(() => {
    setDateReturn(dateReturn);
    setDateReturnEx(dateReturnEx);
  }, [dateReturn, dateReturnEx]);

  return (
    <Box>
      <Paper elevation={5} sx={{ p: 3, mt: 2 }}>
        <Grid container spacing={2} columns={9}>
          <Typography variant="h5">
            Texas Workers’ Compensation Work Status Report
          </Typography>
          <Grid md={9}>
            <hr></hr>
          </Grid>
          <Grid md={7}>
            <Typography variant="h6">I. General Information</Typography>
          </Grid>
          <Grid md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="dateSent"
                name="dateSent"
                label="Date sent"
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setDateSent(newDate);
                  handleChange;
                }}
                value={dateSent}
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="injuredEmployeeName"
              name="injuredEmployeeName"
              onChange={handleChange}
              value={formInput.injuredEmployeeName}
              label="1. Injured Employee's Name"
            ></TextField>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="doctorNameDegree"
              name="doctorNameDegree"
              onChange={handleChange}
              value={formInput.doctorNameDegree}
              label="5a. Doctor’s Name and Degree or Delegating Doctor and Degree "
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="assistantName"
              name="assistantName"
              onChange={handleChange}
              value={formInput.assistantName}
              label="5b. Physician Assistant Name (if completing form)"
            ></TextField>
          </Grid>
          <Grid md={3}>
            <Stack spacing={2}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  id="dateInjury"
                  name="dateInjury"
                  label="2. Date of injury"
                  inputFormat="DD/MM/YYYY"
                  onChange={(newDate) => {
                    setDateInjury(newDate);
                    handleChange;
                  }}
                  value={dateInjury}
                  renderInput={(params) => <TextField {...params} required />}
                />
              </LocalizationProvider>
              <TextField
                required
                fullWidth
                id="ssn"
                name="ssn"
                onChange={handleChange}
                value={formInput.ssn}
                label="3. Social Security Number (last four)"
              ></TextField>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={8}
                placeholder="4. Employee’s Description of Injury/Accident"
                id="descriptionInjury"
                name="descriptionInjury"
                onChange={handleChange}
                value={formInput.descriptionInjury}
              />
            </Stack>
          </Grid>
          <Grid md={3}>
            <Stack spacing={2}>
              <TextField
                required
                fullWidth
                id="facilityName"
                name="facilityName"
                onChange={handleChange}
                value={formInput.facilityName}
                label="6. Facility Name"
              ></TextField>
              <TextField
                required
                fullWidth
                id="facilityDoctorPhone"
                name="facilityDoctorPhone"
                onChange={handleChange}
                value={formInput.facilityDoctorPhone}
                label="7. Facility/Doctor Phone and Fax Numbers"
              ></TextField>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={8}
                placeholder="8. Facility/Doctor Address (Street, City, State, ZIP Code)"
                id="facilityDoctorAdress"
                name="facilityDoctorAdress"
                onChange={handleChange}
                value={formInput.facilityDoctorAdress}
              />
            </Stack>
          </Grid>
          <Grid md={3}>
            <Stack spacing={2}>
              <TextField
                required
                fullWidth
                id="employerName"
                name="employerName"
                onChange={handleChange}
                value={formInput.employerName}
                label="9. Employer's Name"
              ></TextField>
              <TextField
                required
                fullWidth
                id="employerFaxEmail"
                name="employerFaxEmail"
                onChange={handleChange}
                value={formInput.employerFaxEmail}
                label="10. Employer’s Fax Number or Email Address (if known)"
              ></TextField>
              <TextField
                required
                fullWidth
                id="insuranceCarrier"
                name="insuranceCarrier"
                onChange={handleChange}
                value={formInput.insuranceCarrier}
                label="11. Insurance Carrier"
              ></TextField>
              <TextField
                required
                fullWidth
                id="carrierFaxEmail"
                name="carrierFaxEmail"
                onChange={handleChange}
                value={formInput.carrierFaxEmail}
                label="12. Carrier’s Fax Number or Email Address (if known)"
              ></TextField>
            </Stack>
          </Grid>
          <Grid md={9}>
            <hr></hr>
          </Grid>
          <Grid md={9}>
            <Typography variant="h6">
              II. Work Status Information
              <Typography variant="subtitle1">
                (Fully complete one box including estimated dates, and a
                description in 13c, if applicable)
              </Typography>
            </Typography>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              <b>
                13. The injured employee’s medical condition resulting from the
                workers’ compensation injury:
              </b>
            </Typography>
          </Grid>
          <Grid md={7}>
            <Stack spacing={2}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="return-date-label"
                  onChange={handleChange}
                  value={formInput.returnDate}
                  name="returnDate"
                >
                  <FormControlLabel
                    value="A"
                    control={<Radio />}
                    label={`a) will allow the employee to return to work as of ${dateReturn} without restrictions; OR`}
                  />
                  <FormControlLabel
                    value="B"
                    control={<Radio />}
                    label={`b) will allow the employee to return to work as of ${dateReturn} with the restrictions identified in PART III, which are expected to last through
										${dateReturnEx}; OR`}
                  />
                  <FormControlLabel
                    value="C"
                    control={<Radio />}
                    label={`c) has prevented and still prevents the employee from returning to work as of ${dateReturn} and is expected to continue through ${dateReturnEx}. `}
                  />
                </RadioGroup>
              </FormControl>
              <Typography variant="subtitle1">
                <b>
                  The following describes how this injury prevents the employee
                  from returning to work:
                </b>
              </Typography>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={8}
                placeholder="Write a description here..."
                id="injuryDescription"
                name="injuryDescription"
                onChange={handleChange}
                value={formInput.injuryDescription}
              />
            </Stack>
          </Grid>
          <Grid md={2}>
            <Stack spacing={2}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  id="dateReturn"
                  name="dateReturn"
                  label="Return Date"
                  inputFormat="DD/MM/YYYY"
                  onChange={(newDate) => {
                    setDateReturn(newDate);
                    handleChange;
                  }}
                  value={dateReturn}
                  renderInput={(params) => <TextField {...params} required />}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  id="dateReturnEx"
                  name="dateReturnEx"
                  label="Restrictions/Extended return"
                  inputFormat="DD/MM/YYYY"
                  onChange={(newDate) => {
                    setDateReturnEx(newDate);
                    handleChange;
                  }}
                  value={dateReturnEx}
                  renderInput={(params) => <TextField {...params} required />}
                />
              </LocalizationProvider>
            </Stack>
          </Grid>
          <Grid md={9}>
            <hr></hr>
          </Grid>
          <Grid md={9}>
            <Typography variant="h6">
              III. Activity Restrictions
              <Typography variant="subtitle1">
                (Only complete if box 13b is checked)
              </Typography>
            </Typography>
          </Grid>

          <Grid md={9}>
            <Typography variant="subtitle1">
              <b>14. Posture Restrictions (if any):</b>
            </Typography>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              <b>Max hours per day</b>
            </Typography>
          </Grid>
          <Grid md={2}>
            <Typography variant="subtitle1">Standing</Typography>
          </Grid>
          <Grid md={3}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="Standing"
                name="standing"
                onChange={handleChange}
                value={formInput.standing}
              >
                <FormControlLabel value="0" control={<Radio />} label="0" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="6" control={<Radio />} label="6" />
                <FormControlLabel value="8" control={<Radio />} label="8" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="otherStanding"
              name="otherStanding"
              onChange={handleChange}
              value={formInput.otherStanding}
              label="Other"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <Typography variant="subtitle1">Sitting</Typography>
          </Grid>
          <Grid md={3}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="Sitting"
                name="sitting"
                onChange={handleChange}
                value={formInput.sitting}
              >
                <FormControlLabel value="0" control={<Radio />} label="0" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="6" control={<Radio />} label="6" />
                <FormControlLabel value="8" control={<Radio />} label="8" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="otherSitting"
              name="otherSitting"
              onChange={handleChange}
              value={formInput.otherSitting}
              label="Other"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <Typography variant="subtitle1">Kneeling/squatting</Typography>
          </Grid>
          <Grid md={3}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="Kneeling/squatting"
                name="kneeling"
                onChange={handleChange}
                value={formInput.kneeling}
              >
                <FormControlLabel value="0" control={<Radio />} label="0" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="6" control={<Radio />} label="6" />
                <FormControlLabel value="8" control={<Radio />} label="8" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="otherKneeling"
              name="otherKneeling"
              onChange={handleChange}
              value={formInput.otherKneeling}
              label="Other"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <Typography variant="subtitle1">Bending/stooping</Typography>
          </Grid>
          <Grid md={3}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="Bending/stooping"
                name="bending"
                onChange={handleChange}
                value={formInput.bending}
              >
                <FormControlLabel value="0" control={<Radio />} label="0" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="6" control={<Radio />} label="6" />
                <FormControlLabel value="8" control={<Radio />} label="8" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="otherBending"
              name="otherBending"
              onChange={handleChange}
              value={formInput.otherBending}
              label="Other"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <Typography variant="subtitle1">Pushing/pulling</Typography>
          </Grid>
          <Grid md={3}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="Pushing/pulling"
                name="pushing"
                onChange={handleChange}
                value={formInput.pushing}
              >
                <FormControlLabel value="0" control={<Radio />} label="0" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="6" control={<Radio />} label="6" />
                <FormControlLabel value="8" control={<Radio />} label="8" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="otherPushing"
              name="otherPushing"
              onChange={handleChange}
              value={formInput.otherPushing}
              label="Other"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <Typography variant="subtitle1">Twisting</Typography>
          </Grid>
          <Grid md={3}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="Twisting"
                name="twisting"
                onChange={handleChange}
                value={formInput.twisting}
              >
                <FormControlLabel value="0" control={<Radio />} label="0" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="6" control={<Radio />} label="6" />
                <FormControlLabel value="8" control={<Radio />} label="8" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="otherTwisting"
              name="otherTwisting"
              onChange={handleChange}
              value={formInput.otherTwisting}
              label="Other"
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="other14"
              name="other14"
              onChange={handleChange}
              value={formInput.other14}
              label="Other"
            ></TextField>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              <b>15. Restrictions Specific To (if applicable):</b>
            </Typography>
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.leftHandWrist ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      leftHandWrist: value,
                    });
                  }}
                />
              }
              label="Left hand/wrist"
              name="leftHandWrist"
              value={formInput.leftHandWrist}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.rightHandWrist ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      rightHandWrist: value,
                    });
                  }}
                />
              }
              label="Right hand/wrist"
              name="rightHandWrist"
              value={formInput.rightHandWrist}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.leftArm ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      leftArm: value,
                    });
                  }}
                />
              }
              label="Left arm"
              name="leftArm"
              value={formInput.leftArm}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.rightArm ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      rightArm: value,
                    });
                  }}
                />
              }
              label="Right arm"
              name="rightArm"
              value={formInput.rightArm}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.neck ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      neck: value,
                    });
                  }}
                />
              }
              label="Neck"
              name="neck"
              value={formInput.neck}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.leftLeg ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      leftLeg: value,
                    });
                  }}
                />
              }
              label="Left leg"
              name="leftLeg"
              value={formInput.leftLeg}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.rightLeg ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      rightLeg: value,
                    });
                  }}
                />
              }
              label="Right leg"
              name="rightLeg"
              value={formInput.rightLeg}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.back ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      back: value,
                    });
                  }}
                />
              }
              label="Back"
              name="back"
              value={formInput.back}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.leftFootAnkle ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      leftFootAnkle: value,
                    });
                  }}
                />
              }
              label="Left foot/ankle"
              name="leftFootAnkle"
              value={formInput.leftFootAnkle}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.rightFootAnkle ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      rightFootAnkle: value,
                    });
                  }}
                />
              }
              label="Right foot/ankle"
              name="rightFootAnkle"
              value={formInput.rightFootAnkle}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={9}>
            <TextField
              required
              fullWidth
              id="otherRestrictions"
              name="otherRestrictions"
              onChange={handleChange}
              value={formInput.otherRestrictions}
              label="16. Other Restrictions (if any)"
            ></TextField>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              <b>17. Motion Restrictions (if any):</b>
            </Typography>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              <b>Max hours per day</b>
            </Typography>
          </Grid>
          <Grid md={2}>
            <Typography variant="subtitle1">Walking</Typography>
          </Grid>
          <Grid md={3}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="Walking"
                name="walking"
                onChange={handleChange}
                value={formInput.walking}
              >
                <FormControlLabel value="0" control={<Radio />} label="0" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="6" control={<Radio />} label="6" />
                <FormControlLabel value="8" control={<Radio />} label="8" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="otherWalking"
              name="otherWalking"
              onChange={handleChange}
              value={formInput.otherWalking}
              label="Other"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <Typography variant="subtitle1">Climbing stairs/ladders</Typography>
          </Grid>
          <Grid md={3}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="Climbing stairs/ladders"
                name="climbingStairsLadders"
                onChange={handleChange}
                value={formInput.climbingStairsLadders}
              >
                <FormControlLabel value="0" control={<Radio />} label="0" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="6" control={<Radio />} label="6" />
                <FormControlLabel value="8" control={<Radio />} label="8" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="otherClimbingStairsLadders"
              name="otherClimbingStairsLadders"
              onChange={handleChange}
              value={formInput.otherClimbingStairsLadders}
              label="Other"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <Typography variant="subtitle1">Grasping/squeezing</Typography>
          </Grid>
          <Grid md={3}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="Grasping/squeezing"
                name="graspingSqueezing"
                onChange={handleChange}
                value={formInput.graspingSqueezing}
              >
                <FormControlLabel value="0" control={<Radio />} label="0" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="6" control={<Radio />} label="6" />
                <FormControlLabel value="8" control={<Radio />} label="8" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="otherGraspingSqueezing"
              name="otherGraspingSqueezing"
              onChange={handleChange}
              value={formInput.otherGraspingSqueezing}
              label="Other"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <Typography variant="subtitle1">Wrist flexion/extension</Typography>
          </Grid>
          <Grid md={3}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="Wrist flexion/extension"
                name="wristFlexionExtension"
                onChange={handleChange}
                value={formInput.wristFlexionExtension}
              >
                <FormControlLabel value="0" control={<Radio />} label="0" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="6" control={<Radio />} label="6" />
                <FormControlLabel value="8" control={<Radio />} label="8" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="otherWristFlexionExtension"
              name="otherWristFlexionExtension"
              onChange={handleChange}
              value={formInput.otherWristFlexionExtension}
              label="Other"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <Typography variant="subtitle1">Reaching</Typography>
          </Grid>
          <Grid md={3}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="Reaching"
                name="reaching"
                onChange={handleChange}
                value={formInput.reaching}
              >
                <FormControlLabel value="0" control={<Radio />} label="0" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="6" control={<Radio />} label="6" />
                <FormControlLabel value="8" control={<Radio />} label="8" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="otherReaching"
              name="otherReaching"
              onChange={handleChange}
              value={formInput.otherReaching}
              label="Other"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <Typography variant="subtitle1">Overhead reaching</Typography>
          </Grid>
          <Grid md={3}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="Overhead reaching"
                name="overheadReaching"
                onChange={handleChange}
                value={formInput.overheadReaching}
              >
                <FormControlLabel value="0" control={<Radio />} label="0" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="6" control={<Radio />} label="6" />
                <FormControlLabel value="8" control={<Radio />} label="8" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="otherOverheadReaching"
              name="otherOverheadReaching"
              onChange={handleChange}
              value={formInput.otherOverheadReaching}
              label="Other"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <Typography variant="subtitle1">Keyboarding</Typography>
          </Grid>
          <Grid md={3}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="Keyboarding"
                name="keyboarding"
                onChange={handleChange}
                value={formInput.keyboarding}
              >
                <FormControlLabel value="0" control={<Radio />} label="0" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="6" control={<Radio />} label="6" />
                <FormControlLabel value="8" control={<Radio />} label="8" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="otherKeyboarding"
              name="otherKeyboarding"
              onChange={handleChange}
              value={formInput.otherKeyboarding}
              label="Other"
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="other17"
              name="other17"
              onChange={handleChange}
              value={formInput.other17}
              label="Other"
            ></TextField>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              <b>18. Lift/Carry Restrictions (if any):</b>
            </Typography>
          </Grid>
          <Grid md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.liftCarryRestrictions1 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      liftCarryRestrictions1: value,
                    });
                  }}
                />
              }
              label="May not lift/carry objects more than _____ lbs. for more
              than _____ hours per day."
              name="liftCarryRestrictions1"
              value={formInput.liftCarryRestrictions1}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={1}>
            <TextField
              required
              fullWidth
              id="liftCarry1"
              name="liftCarry1"
              onChange={handleChange}
              value={formInput.liftCarry1}
              label="lbs"
            ></TextField>
          </Grid>
          <Grid md={1}>
            <TextField
              required
              fullWidth
              id="liftCarry2"
              name="liftCarry2"
              onChange={handleChange}
              value={formInput.liftCarry2}
              label="hours per day"
            ></TextField>
          </Grid>
          <Grid md={9}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.liftCarryRestrictions2 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      liftCarryRestrictions2: value,
                    });
                  }}
                />
              }
              label="May not perform any lifting/carrying."
              name="liftCarryRestrictions2"
              value={formInput.liftCarryRestrictions2}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="other18"
              name="other18"
              onChange={handleChange}
              value={formInput.other18}
              label="Other"
            ></TextField>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              <b>19. Misc. Restrictions (if any):</b>
            </Typography>
          </Grid>
          <Grid md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.miscRestrictions1 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      miscRestrictions1: value,
                    });
                  }}
                />
              }
              label="Max hours per day of work:"
              name="miscRestrictions1"
              value={formInput.miscRestrictions1}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="miscRestrictions1Data"
              name="miscRestrictions1Data"
              onChange={handleChange}
              value={formInput.miscRestrictions1Data}
              label="Hours"
            ></TextField>
          </Grid>
          <Grid md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.miscRestrictions2 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      miscRestrictions2: value,
                    });
                  }}
                />
              }
              label="Sit/stretch breaks of ______ per ________"
              name="miscRestrictions2"
              value={formInput.miscRestrictions2}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="miscRestrictions2DataA"
              name="miscRestrictions2DataA"
              onChange={handleChange}
              value={formInput.miscRestrictions2DataA}
              label=""
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="miscRestrictions2DataB"
              name="miscRestrictions2DataB"
              onChange={handleChange}
              value={formInput.miscRestrictions2DataB}
              label=""
            ></TextField>
          </Grid>
          <Grid md={9}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.miscRestrictions3 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      miscRestrictions3: value,
                    });
                  }}
                />
              }
              label="Must wear splint/cast at work"
              name="miscRestrictions3"
              value={formInput.miscRestrictions3}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={9}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.miscRestrictions4 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      miscRestrictions4: value,
                    });
                  }}
                />
              }
              label="Must use crutches at all times"
              name="miscRestrictions4"
              value={formInput.miscRestrictions4}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={9}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.miscRestrictions5 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      miscRestrictions5: value,
                    });
                  }}
                />
              }
              label="No driving/operating heavy equipment"
              name="miscRestrictions5"
              value={formInput.miscRestrictions5}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={9}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.miscRestrictions6 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      miscRestrictions6: value,
                    });
                  }}
                />
              }
              label="Can only drive automatic transmission"
              name="miscRestrictions6"
              value={formInput.miscRestrictions6}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.miscRestrictions7 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      miscRestrictions7: value,
                    });
                  }}
                />
              }
              label="No skin contact with:"
              name="miscRestrictions7"
              value={formInput.miscRestrictions7}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="miscRestrictions7Data"
              name="miscRestrictions7Data"
              onChange={handleChange}
              value={formInput.miscRestrictions7Data}
              label=""
            ></TextField>
          </Grid>
          <Grid md={9}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.miscRestrictions8 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      miscRestrictions8: value,
                    });
                  }}
                />
              }
              label="No running"
              name="miscRestrictions8"
              value={formInput.miscRestrictions8}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={9}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.miscRestrictions9 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      miscRestrictions9: value,
                    });
                  }}
                />
              }
              label="Dressing changes necessary at work"
              name="miscRestrictions9"
              value={formInput.miscRestrictions9}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.miscRestrictions10 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      miscRestrictions10: value,
                    });
                  }}
                />
              }
              label="No work /________ hours/day work:"
              name="miscRestrictions10"
              value={formInput.miscRestrictions10}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="miscRestrictions10Data"
              name="miscRestrictions10Data"
              onChange={handleChange}
              value={formInput.miscRestrictions10Data}
              label="Hours/day"
            ></TextField>
          </Grid>
          <Grid md={3}></Grid>
          <Grid md={1}></Grid>
          <Grid md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.miscRestrictions10A ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      miscRestrictions10A: value,
                    });
                  }}
                />
              }
              label="in extreme hot/cold environments"
              name="miscRestrictions10A"
              value={formInput.miscRestrictions10A}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.miscRestrictions10B ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      miscRestrictions10B: value,
                    });
                  }}
                />
              }
              label="at heights or on scaffolding"
              name="miscRestrictions10B"
              value={formInput.miscRestrictions10B}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.miscRestrictions11 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      miscRestrictions11: value,
                    });
                  }}
                />
              }
              label="Must keep_____________________________"
              name="miscRestrictions11"
              value={formInput.miscRestrictions11}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="miscRestrictions11Data"
              name="miscRestrictions11Data"
              onChange={handleChange}
              value={formInput.miscRestrictions11Data}
              label=""
            ></TextField>
          </Grid>
          <Grid md={3}></Grid>
          <Grid md={1}></Grid>
          <Grid md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.miscRestrictions11A ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      miscRestrictions11A: value,
                    });
                  }}
                />
              }
              label="elevated"
              name="miscRestrictions11A"
              value={formInput.miscRestrictions11A}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.miscRestrictions11B ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      miscRestrictions11B: value,
                    });
                  }}
                />
              }
              label="clean & dry"
              name="miscRestrictions11B"
              value={formInput.miscRestrictions11B}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              <b>20. Medication Restrictions (if any):</b>
            </Typography>
          </Grid>
          <Grid md={9}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.medRestrictions1 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      medRestrictions1: value,
                    });
                  }}
                />
              }
              label="Must take prescription medication(s)"
              name="medRestrictions1"
              value={formInput.medRestrictions1}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={9}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.medRestrictions2 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      medRestrictions2: value,
                    });
                  }}
                />
              }
              label="Advised to take over-the-counter meds"
              name="medRestrictions2"
              value={formInput.medRestrictions2}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={9}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.medRestrictions3 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      medRestrictions3: value,
                    });
                  }}
                />
              }
              label="Medication may make drowsy (possible safety/driving issues)"
              name="medRestrictions3"
              value={formInput.medRestrictions3}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={9}>
            <hr></hr>
          </Grid>
          <Grid md={9}>
            <Typography variant="h6">
              IV. Treatment/Follow-up Appointment Information
            </Typography>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              <b>21. Work Injury Diagnosis Information:</b>
            </Typography>
          </Grid>
          <Grid md={9}>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={8}
              id="WIDInfo"
              name="WIDInfo"
              onChange={handleChange}
              value={formInput.WIDInfo}
            />
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              <b>22. Expected Follow-up Services Include:</b>
            </Typography>
          </Grid>
          <Grid md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.followupServices1 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      followupServices1: value,
                    });
                  }}
                />
              }
              label="Evaluation by the treating doctor on _____/ _____/ __________ at _____:_____ a.m./p.m."
              name="followupServices1"
              value={formInput.followupServices1}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="dateFollowupServices1"
                name="dateFollowupServices1"
                label="Date"
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setDateFollowupServices1(newDate);
                  handleChange;
                }}
                value={dateFollowupServices1}
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="timeFollowupServices1"
              name="timeFollowupServices1"
              onChange={handleChange}
              value={formInput.timeFollowupServices1}
              label="Time"
            ></TextField>
          </Grid>
          <Grid md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.followupServices2 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      followupServices2: value,
                    });
                  }}
                />
              }
              label="Referral to/consult with ______________________________ on _____/ _____/ _________ at _____:_____ a.m./p.m."
              name="followupServices2"
              value={formInput.followupServices2}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="followupServices2Data"
              name="followupServices2Data"
              onChange={handleChange}
              value={formInput.followupServices2Data}
              label="Time"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="dateFollowupServices2"
                name="dateFollowupServices2"
                label="Date"
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setDateFollowupServices2(newDate);
                  handleChange;
                }}
                value={dateFollowupServices2}
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={1}>
            <TextField
              required
              fullWidth
              id="timeFollowupServices2"
              name="timeFollowupServices2"
              onChange={handleChange}
              value={formInput.timeFollowupServices2}
              label="Time"
            ></TextField>
          </Grid>
          <Grid md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.followupServices3 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      followupServices3: value,
                    });
                  }}
                />
              }
              label="Physical medicine _____ X per week for _____ weeks starting on _____/ _____/ _________ at _____:_____ a.m./p.m."
              name="followupServices3"
              value={formInput.followupServices3}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={1}>
            <TextField
              required
              fullWidth
              id="followupServices3DataA"
              name="followupServices3DataA"
              onChange={handleChange}
              value={formInput.followupServices3DataA}
              label="Times/week"
            ></TextField>
          </Grid>
          <Grid md={1}>
            <TextField
              required
              fullWidth
              id="followupServices3DataB"
              name="followupServices3DataB"
              onChange={handleChange}
              value={formInput.followupServices3DataB}
              label="Weeks"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="dateFollowupServices3"
                name="dateFollowupServices3"
                label="Date"
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setDateFollowupServices3(newDate);
                  handleChange;
                }}
                value={dateFollowupServices3}
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={1}>
            <TextField
              required
              fullWidth
              id="timeFollowupServices3"
              name="timeFollowupServices3"
              onChange={handleChange}
              value={formInput.timeFollowupServices3}
              label="Time"
            ></TextField>
          </Grid>
          <Grid md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.followupServices4 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      followupServices4: value,
                    });
                  }}
                />
              }
              label="Special studies (list): _______________________________ on _____/ _____/ __________ at _____:_____ a.m./p.m."
              name="followupServices4"
              value={formInput.followupServices4}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="followupServices4Data"
              name="followupServices4Data"
              onChange={handleChange}
              value={formInput.followupServices4Data}
              label="Time"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="dateFollowupServices4"
                name="dateFollowupServices4"
                label="Date"
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setDateFollowupServices4(newDate);
                  handleChange;
                }}
                value={dateFollowupServices4}
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={1}>
            <TextField
              required
              fullWidth
              id="timeFollowupServices4"
              name="timeFollowupServices4"
              onChange={handleChange}
              value={formInput.timeFollowupServices4}
              label="Time"
            ></TextField>
          </Grid>
          <Grid md={9}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.followupServices5 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      followupServices5: value,
                    });
                  }}
                />
              }
              label="None. This is the last scheduled visit for this problem. At this time, no further medical care is anticipated."
              name="followupServices5"
              value={formInput.followupServices5}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="dateOfVisit"
                name="dateOfVisit"
                label="Date of visit"
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setDateOfVisit(newDate);
                  handleChange;
                }}
                value={dateOfVisit}
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="timeOfVisit"
              name="timeOfVisit"
              onChange={handleChange}
              value={formInput.timeOfVisit}
              label="Time of visit"
            ></TextField>
          </Grid>
          <Grid md={5}>
            <TextField
              required
              fullWidth
              id="employeeSignature"
              name="employeeSignature"
              onChange={handleChange}
              value={formInput.employeeSignature}
              label="Employee's Signature"
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="timeOfDicharge"
              name="timeOfDicharge"
              onChange={handleChange}
              value={formInput.timeOfDicharge}
              label="Time of discharge"
            ></TextField>
          </Grid>
          <Grid md={5}>
            <TextField
              required
              fullWidth
              id="practSignatureLicense"
              name="practSignatureLicense"
              onChange={handleChange}
              value={formInput.practSignatureLicense}
              label="Health Care Practitioner's Signature / License #"
            ></TextField>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              <b>Visit Type:</b>
            </Typography>
          </Grid>
          <Grid md={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.visitType1 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      visitType1: value,
                    });
                  }}
                />
              }
              label="Initia"
              name="visitType1"
              value={formInput.visitType1}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.visitType2 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      visitType2: value,
                    });
                  }}
                />
              }
              label="Follow-up"
              name="visitType2"
              value={formInput.visitType2}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              <b>Role of Health Care Practitioner:</b>
            </Typography>
          </Grid>
          <Grid md={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.practitionerRole1 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      practitionerRole1: value,
                    });
                  }}
                />
              }
              label="Treating doctor"
              name="practitionerRole1"
              value={formInput.practitionerRole1}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.practitionerRole2 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      practitionerRole2: value,
                    });
                  }}
                />
              }
              label="Referral doctor"
              name="practitionerRole2"
              value={formInput.practitionerRole2}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.practitionerRole3 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      practitionerRole3: value,
                    });
                  }}
                />
              }
              label="RME Doctor"
              name="practitionerRole3"
              value={formInput.practitionerRole3}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.practitionerRole4 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      practitionerRole4: value,
                    });
                  }}
                />
              }
              label="Consulting doctor"
              name="practitionerRole4"
              value={formInput.practitionerRole4}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.practitionerRole5 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      practitionerRole5: value,
                    });
                  }}
                />
              }
              label="Physician assistant"
              name="practitionerRole5"
              value={formInput.practitionerRole5}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.practitionerRole6 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      practitionerRole6: value,
                    });
                  }}
                />
              }
              label="Designated doctor"
              name="practitionerRole6"
              value={formInput.practitionerRole6}
              onChange={handleChange}
            />
          </Grid>
          <Grid md={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formInput.practitionerRole7 ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    setFormInput({
                      ...formInput,
                      practitionerRole7: value,
                    });
                  }}
                />
              }
              label="Other doctor"
              name="practitionerRole7"
              value={formInput.practitionerRole7}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
