import axios from "axios";

const getFiles = (apiURL, setFiles) => {
  axios
    .get(apiURL, {
      responseType: "json",
    })
    .then((res) => {
      if (res.data.status === false) {
        return;
      }
      setFiles(res.data);
      console.log(files);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default getFiles;
