import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { setFormValues } from "../../../../utilities/Forms/formFunctions";

export default function PhysicalExamination(props) {
  const handleForm = (event) => {
    setFormValues(props.formInput, props.setFormInput, props.formData, props.setFormData, event);
  };
  return (
    <Box component="form">
      <Paper elevation={5} sx={{ p: 3, mt: 2 }}>
        <Grid container columns={9} spacing={2}>
          <Grid md={9}>
            <Typography variant="subtitle">
              The presence of a certain condition may not necessarily disqualify
              a driver, particularly if the condition is controlled adequately,
              is not likely to worsen, or is readily amenable to treatment. Even
              if a condition does not disqualify a driver, the Medical Examiner
              may consider deferring the driver temporarily. Also, the driver
              should be advised to take the necessary steps to correct the
              condition as soon as possible, particularly if neglecting the
              condition could result in a more serious illness that might affect
              driving. Check the body systems for abnormalities.
            </Typography>
          </Grid>
          <Grid md={3}>
            <Typography variant="h6">Body System</Typography>
          </Grid>
          <Grid md={9}>
            <hr></hr>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">1. General</Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="General"
                name="General"
                value={props.formInput.General}
                onChange={handleForm}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">2. Skin</Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="Skin"
                name="Skin"
                value={props.formInput.Skin}
                onChange={handleForm}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">3. Eyes</Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="Eyes"
                name="Eyes"
                value={props.formInput.Eyes}
                onChange={handleForm}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">4. Ears</Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="Ears"
                name="Ears"
                value={props.formInput.Ears}
                onChange={handleForm}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">5. Mouth/Throat</Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="mouthThroat"
                name="mouthThroat"
                value={props.formInput.mouthThroat}
                onChange={handleForm}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">6. Cardiovascular</Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="Cardio"
                name="Cardio"
                value={props.formInput.Cardio}
                onChange={handleForm}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">7. Lungs/Chest</Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="lungsChest"
                name="lungsChest"
                value={props.formInput.lungsChest}
                onChange={handleForm}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">8. Abdomen</Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="Abdomen"
                name="Abdomen"
                value={props.formInput.Abdomen}
                onChange={handleForm}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">
              9. Genito-urinary system including hernias
            </Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="GUSystem"
                name="GUSystem"
                value={props.formInput.GUSystem}
                onChange={handleForm}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">10. Back/Spine</Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="backSpine"
                name="backSpine"
                value={props.formInput.backSpine}
                onChange={handleForm}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">11. Extremities/Joints</Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="extJoints"
                name="extJoints"
                value={props.formInput.extJoints}
                onChange={handleForm}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">
              12. Neurological system including reflexes
            </Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="neuroSystem"
                name="neuroSystem"
                value={props.formInput.neuroSystem}
                onChange={handleForm}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">13. Gait</Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="Gait"
                name="Gait"
                value={props.formInput.Gait}
                onChange={handleForm}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">14. Vascular System</Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="Vascular"
                name="Vascular"
                value={props.formInput.Vascular}
                onChange={handleForm}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={9} sx={{ fontStyle: "italic" }}>
            <Typography variant="subtitle">
              Discuss any abnormal answers in detail in the space below and
              indicate whether it would affect the driver's ability to operate a
              CMV. Enter applicable item number before each comment.
            </Typography>
          </Grid>
          <Grid md={9}>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="Write your description..."
              style={{ width: 400 }}
              name="physComment"
              value={props.formInput.physComment}
              onChange={handleForm}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
