import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { setFormValues } from "../../../../utilities/Forms/formFunctions";

export default function PersonalInfoWC(props) {
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [dateOfInjury, setDateOfInjury] = useState("");
  const [dateOfExam, setDateOfExam] = useState("");
  const handleForm = (event) => {
    setFormValues(
      props.formInput,
      props.setFormInput,
      props.formData,
      props.setFormData,
      event
    );
    console.log(props.formInput);
  };
  return (
    <Box component="form">
      <Paper elevation={5} sx={{ p: 3, mt: 2 }}>
        <Grid container columns={8} spacing={2}>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              value={props.formInput.name}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="dateOfExam"
                name="dateOfExam"
                label="Date of exam"
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setDateOfBirth(newDate);
                  setFormInput({ ...formData, dateOfExam: newDate });
                  handleChange;
                }}
                value={
                  props.loadData ? props.formInput.dateOfBirth : dateOfBirth
                }
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="ssn"
              label="SSN"
              name="ssn"
              value={props.formInput.ssn}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="dateOfExam"
                name="dateOfExam"
                label="Date of exam"
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setDateOfInjury(newDate);
                  setFormInput({ ...formData, dateOfInjury: newDate });
                  handleChange;
                }}
                value={
                  props.loadData ? props.formInput.dateOfInjury : dateOfInjury
                }
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="employer"
              label="Employer"
              name="employer"
              value={props.formInput.employer}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="occ"
              label="OCC"
              name="nocc"
              value={props.formInput.occ}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="pdc"
              label="PDC"
              name="pdc"
              value={props.formInput.pdc}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="dateOfExam"
                name="dateOfExam"
                label="Date of exam"
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setDateOfExam(newDate);
                  setFormInput({ ...formData, dateOfExam: newDate });
                  handleChange;
                }}
                value={props.loadData ? props.formInput.dateOfExam : dateOfExam}
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={1}>
            <TextField
              required
              fullWidth
              id="age"
              label="Age"
              name="age"
              value={props.formInput.age}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="sex"
              label="Sex"
              name="sex"
              value={props.formInput.sex}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={1}>
            <TextField
              required
              fullWidth
              id="ht"
              label="HT"
              name="ht"
              value={props.formInput.ht}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={1}>
            <TextField
              required
              fullWidth
              id="wt"
              label="WT"
              name="wt"
              value={props.formInput.wt}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={1}>
            <TextField
              required
              fullWidth
              id="bp"
              label="BP"
              name="bp"
              value={props.formInput.bp}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="hr"
              label="HR"
              name="hr"
              value={props.formInput.hr}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={8}>
            <TextField
              required
              fullWidth
              id="diagnosis"
              label="Diagnosis"
              name="diagnosis"
              value={props.formInput.diagnosis}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={8}>
            <Stack direction="row" spacing={2}>
              <Typography variant="subtitle1">Comorbidities:</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.formInput.na ? true : false}
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      props.setFormInput({
                        ...props.formInput,
                        na: value,
                      });
                    }}
                  />
                }
                label="NA"
                name="na"
                value={props.formInput.na}
                onChange={handleForm}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.formInput.cardiovascular ? true : false}
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      props.setFormInput({
                        ...props.formInput,
                        cardiovascular: value,
                      });
                    }}
                  />
                }
                label="Cardiovascular"
                name="cardiovascular"
                value={props.formInput.cardiovascularr}
                onChange={handleForm}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.formInput.diabetes ? true : false}
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      props.setFormInput({
                        ...props.formInput,
                        diabetes: value,
                      });
                    }}
                  />
                }
                label="Diabetes"
                name="diabetes"
                value={props.formInput.diabetes}
                onChange={handleForm}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.formInput.respiratory ? true : false}
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      props.setFormInput({
                        ...props.formInput,
                        respiratory: value,
                      });
                    }}
                  />
                }
                label="Respiratory"
                name="respiratory"
                value={props.formInput.respiratory}
                onChange={handleForm}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.formInput.arthritis ? true : false}
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      props.setFormInput({
                        ...props.formInput,
                        arthritis: value,
                      });
                    }}
                  />
                }
                label="Arthritis"
                name="arthritis"
                value={props.formInput.arthritis}
                onChange={handleForm}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.formInput.other ? true : false}
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      props.setFormInput({
                        ...props.formInput,
                        other: value,
                      });
                    }}
                  />
                }
                label="Other"
                name="other"
                value={props.formInput.other}
                onChange={handleForm}
              />
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
