import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Modal from "@mui/material/Modal";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "axios";

const styleInfo = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const stylePhysical = {
  position: "absolute",
  top: "240%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function employeeForm(props) {
  const [employeeId, setEmployeeId] = useState(0)
  const employeeURL = `${props.URL}ThePortClinic-API/insertNewEmployee.php`;
  const insertNewData = (data, apiURL) => {
    axios
      .post(apiURL, data)
      .then((res) => {
        if (res.data.status === false) {
          console.log("No se pudo");
          return;
        } else if (res.data.status === true) {
          console.log("Si se pudo");
          alert("Success!");
          props.setInsertStatus(props.insertStatus + 1);
          setEmployeeId(res.data.id)
          //props.setOpen(false);
          return;
        } else {
          console.log(res);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  };

  //Update employee info
  const updateURL = `${props.URL}ThePortClinic-API/updateEmployee.php`;
  const updateEmployee = (data, apiURL) => {
    console.log(data);
    axios
      .post(apiURL, data)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === true) {
          alert("Success!");
          props.setUpdateStatus(props.updateStatus + 1);
          props.setOpen(false);
          props.setEdit(false);
          props.setDate("");
          props.setprops.EmployeeInput(initialFormState);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Get states
  const getStatesURL = `${props.URL}ThePortClinic-API/getStates.php`;
  const [statesData, setStatestData] = useState([]);
  const getStates = (apiURL) => {
    axios
      .get(apiURL)
      .then((response) => {
        setStatestData(response.data);
        console.log(serviceData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePhysicalUpdate = ()=>{
    props.setEmployeeInput({
      ...props.employeeInput,
      id: employeeId,
      form_data: {...props.employeePhysical}
    })
    updateEmployee(props.employeeInput, updateURL);
  }

  useEffect(() => {
    getStates(getStatesURL);
  }, []);
  const selectOptions = () => {
    try {
      return statesData.map((item) => (
        <MenuItem value={item.state}>{item.state}</MenuItem>
      ));
    } catch {
      return <MenuItem value={0}>No states...</MenuItem>;
    }
  };

  return (
    <Box>
      <Paper>
        <Grid container spacing={2} columns={8}>
          <div>
            <Modal
              open={props.open}
              onClose={props.handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ overflow: "scroll" }}
            >
              {props.insertStatus <= 0 ? (
                <Box component="form" sx={styleInfo}>
                  <Grid container columns={8} spacing={2}>
                    <Grid md={8}>
                      <Typography variant="h4">
                        Enter employee information
                      </Typography>
                    </Grid>
                    <Grid md={3}>
                      <TextField
                        required
                        fullWidth
                        id="first_name"
                        name="first_name"
                        label="Employee First Name"
                        onChange={props.handleChange}
                        value={props.employeeInput.first_name}
                      ></TextField>
                    </Grid>
                    <Grid md={2}>
                      <TextField
                        required
                        fullWidth
                        id="middle_name"
                        name="middle_name"
                        label="Employee Middle Name"
                        onChange={props.handleChange}
                        value={props.employeeInput.middle_name}
                      ></TextField>
                    </Grid>
                    <Grid md={3}>
                      <TextField
                        required
                        fullWidth
                        id="last_name"
                        name="last_name"
                        label="Employee Last Name"
                        onChange={props.handleChange}
                        value={props.employeeInput.last_name}
                      ></TextField>
                    </Grid>
                    <Grid md={2}>
                      <FormControl fullWidth>
                        <InputLabel id="gender-label">Gender</InputLabel>
                        <Select
                          labelId="gender-label"
                          id="gender"
                          name="gender"
                          onChange={props.handleChange}
                          value={
                            !props.employeeInput.gender
                              ? ""
                              : props.employeeInput.gender
                          }
                          label="Gender"
                        >
                          <MenuItem value="Male">Male</MenuItem>
                          <MenuItem value="Female">Female</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid md={4}>
                      <TextField
                        required
                        fullWidth
                        id="address"
                        name="address"
                        label="Employee Adress"
                        onChange={props.handleChange}
                        value={props.employeeInput.address}
                      ></TextField>
                    </Grid>
                    <Grid md={2}>
                      <TextField
                        required
                        fullWidth
                        id="suite"
                        name="suite"
                        label="Suite"
                        onChange={props.handleChange}
                        value={props.employeeInput.suite}
                      ></TextField>
                    </Grid>
                    <Grid md={4}>
                      <TextField
                        required
                        fullWidth
                        id="city"
                        name="city"
                        onChange={props.handleChange}
                        value={props.employeeInput.city}
                        label="City"
                      ></TextField>
                    </Grid>
                    <Grid md={2}>
                      <FormControl fullWidth>
                        <InputLabel id="state-label">State</InputLabel>
                        <Select
                          labelId="state-label"
                          id="state"
                          name="state"
                          onChange={props.handleChange}
                          value={props.employeeInput.state}
                          label="State"
                        >
                          {selectOptions()}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid md={2}>
                      <TextField
                        required
                        fullWidth
                        id="zip"
                        name="zip"
                        onChange={props.handleChange}
                        value={props.employeeInput.zip}
                        label="Zip"
                      ></TextField>
                    </Grid>
                    <Grid md={3}>
                      <TextField
                        required
                        fullWidth
                        id="phone"
                        name="phone"
                        onChange={props.handleChange}
                        value={props.employeeInput.phone}
                        label="Phone"
                      ></TextField>
                    </Grid>
                    <Grid md={3}>
                      <TextField
                        required
                        fullWidth
                        id="email"
                        name="email"
                        onChange={props.handleChange}
                        value={props.employeeInput.email}
                        label="Email"
                      ></TextField>
                    </Grid>
                    <Grid md={2}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          id="dob"
                          name="dob"
                          label="Date of birth"
                          onChange={(newDate) => {
                            props.setDate(newDate);
                            props.handleChange;
                          }}
                          value={props.date}
                          renderInput={(params) => (
                            <TextField {...params} required />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid md={4}>
                      <TextField
                        required
                        fullWidth
                        id="ssn"
                        name="ssn"
                        onChange={props.handleChange}
                        value={props.employeeInput.ssn}
                        label="Social Security Number"
                      ></TextField>
                    </Grid>
                    <Grid md={4}>
                      <TextField
                        required
                        fullWidth
                        id="dln"
                        name="dln"
                        onChange={props.handleChange}
                        value={props.employeeInput.dln}
                        label="Drivers License Number"
                      ></TextField>
                    </Grid>
                    <Grid md={8}>
                      <Typography variant="h">Add extra notes:</Typography>
                    </Grid>
                    <Grid md={8}>
                      <TextareaAutosize
                        aria-label="minimum height"
                        minRows={3}
                        placeholder="Notes..."
                        style={{ width: 400 }}
                        id="notes"
                        name="notes"
                        onChange={props.handleChange}
                        value={props.employeeInput.notes}
                      />
                    </Grid>
                    <Grid md={2}>
                      {props.edit === false ? (
                        <Button
                          variant="contained"
                          size="large"
                          onClick={() => {
                            insertNewData(props.employeeInput, employeeURL);
                          }}
                        >
                          Save employee
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          size="large"
                          onClick={() => {
                            updateEmployee(props.employeeInput, updateURL);
                          }}
                        >
                          Update employee
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Box component="form" sx={stylePhysical}>
                  <Grid container columns={9} spacing={2}>
                    <Grid md={9}>
                      <Typography variant="h4">
                        Employee Physical Questionare
                      </Typography>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Asthma or wheezing</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ1"
                          name="physicalQ1"
                          value={props.employeePhysical.physicalQ1}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Pain or pressure in the chest</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ2"
                          name="physicalQ2"
                          value={props.employeePhysical.physicalQ2}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Neck pain</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ3"
                          name="physicalQ3"
                          value={props.employeePhysical.physicalQ3}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Pneumonia</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ4"
                          name="physicalQ4"
                          value={props.employeePhysical.physicalQ4}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Palpitation or pounding heart</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ5"
                          name="physicalQ5"
                          value={props.employeePhysical.physicalQ5}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Back pain or injury</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ6"
                          name="physicalQ6"
                          value={props.employeePhysical.physicalQ6}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Tuberculosis/ other lung disease</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ7"
                          name="physicalQ7"
                          value={props.employeePhysical.physicalQ7}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Heart trouble</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ8"
                          name="physicalQ8"
                          value={props.employeePhysical.physicalQ8}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Amputations</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ9"
                          name="physicalQ9"
                          value={props.employeePhysical.physicalQ9}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Frequent bronchitis</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ10"
                          name="physicalQ10"
                          value={props.employeePhysical.physicalQ10}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>High blood pressure</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ11"
                          name="physicalQ11"
                          value={props.employeePhysical.physicalQ11}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Fractured or broken bones</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ12"
                          name="physicalQ12"
                          value={props.employeePhysical.physicalQ12}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Shortness of breath</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ13"
                          name="physicalQ13"
                          value={props.employeePhysical.physicalQ13}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Diabetes</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ14"
                          name="physicalQ14"
                          value={props.employeePhysical.physicalQ14}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Swollen, stiff, or painful joints</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ15"
                          name="physicalQ15"
                          value={props.employeePhysical.physicalQ15}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Chronic cough</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ16"
                          name="physicalQ16"
                          value={props.employeePhysical.physicalQ16}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Stroke</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ17"
                          name="physicalQ17"
                          value={props.employeePhysical.physicalQ17}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>
                        Deformities of legs, feet, arms, hands, back or joints
                      </Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ18"
                          name="physicalQ18"
                          value={props.employeePhysical.physicalQ18}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Have you coughed up blood</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ19"
                          name="physicalQ19"
                          value={props.employeePhysical.physicalQ19}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Rheumatic fever</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ20"
                          name="physicalQ20"
                          value={props.employeePhysical.physicalQ20}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Loss of appetite</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ21"
                          name="physicalQ21"
                          value={props.employeePhysical.physicalQ21}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>
                        History of sleep apnea, sleep disorders, or loud snoring
                      </Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ22"
                          name="physicalQ22"
                          value={props.employeePhysical.physicalQ22}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Bleeding disorder(s)</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ23"
                          name="physicalQ23"
                          value={props.employeePhysical.physicalQ23}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Unexplained weight change</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ24"
                          name="physicalQ24"
                          value={props.employeePhysical.physicalQ24}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Glasses or contacts</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ25"
                          name="physicalQ25"
                          value={props.employeePhysical.physicalQ25}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Bleeding problems</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ26"
                          name="physicalQ26"
                          value={props.employeePhysical.physicalQ26}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Growth or tumors</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ27"
                          name="physicalQ27"
                          value={props.employeePhysical.physicalQ27}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Glaucoma</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ28"
                          name="physicalQ28"
                          value={props.employeePhysical.physicalQ28}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Inflammation or clots of veins</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ29"
                          name="physicalQ29"
                          value={props.employeePhysical.physicalQ29}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Thyroid problems</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ30"
                          name="physicalQ30"
                          value={props.employeePhysical.physicalQ30}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>
                        Injuries, defects, operation on eye
                      </Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ31"
                          name="physicalQ31"
                          value={props.employeePhysical.physicalQ31}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Enlarged or varicose veins</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ32"
                          name="physicalQ32"
                          value={props.employeePhysical.physicalQ32}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Hernia</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ33"
                          name="physicalQ33"
                          value={props.employeePhysical.physicalQ33}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Loss of Hearing</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ34"
                          name="physicalQ34"
                          value={props.employeePhysical.physicalQ34}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Frequent nausea or vomiting</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ35"
                          name="physicalQ35"
                          value={props.employeePhysical.physicalQ35}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Hemorrhoids</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ36"
                          name="physicalQ36"
                          value={props.employeePhysical.physicalQ36}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Ringing of the ears</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ37"
                          name="physicalQ37"
                          value={props.employeePhysical.physicalQ37}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Vomiting of blood</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ38"
                          name="physicalQ38"
                          value={props.employeePhysical.physicalQ38}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Cancer</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ39"
                          name="physicalQ39"
                          value={props.employeePhysical.physicalQ39}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Head injury</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ40"
                          name="physicalQ40"
                          value={props.employeePhysical.physicalQ40}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Ulcer</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ41"
                          name="physicalQ41"
                          value={props.employeePhysical.physicalQ41}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Venereal disease(s)</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ42"
                          name="physicalQ42"
                          value={props.employeePhysical.physicalQ42}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Headaches, frequent or severe</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ43"
                          name="physicalQ43"
                          value={props.employeePhysical.physicalQ43}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Frequent diarrhea</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ44"
                          name="physicalQ44"
                          value={props.employeePhysical.physicalQ44}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>
                        Allergies (drug, hay fever, food, contact)
                      </Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ45"
                          name="physicalQ45"
                          value={props.employeePhysical.physicalQ45}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Blackout, dizziness, fainting</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ46"
                          name="physicalQ46"
                          value={props.employeePhysical.physicalQ46}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Frequent constipation, severe</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ47"
                          name="physicalQ47"
                          value={props.employeePhysical.physicalQ47}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>
                        Illness of injuries not listed on this page
                      </Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ48"
                          name="physicalQ48"
                          value={props.employeePhysical.physicalQ48}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Epilepsy, seizures, convulsions</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ49"
                          name="physicalQ49"
                          value={props.employeePhysical.physicalQ49}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Blood in bowel movement</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ50"
                          name="physicalQ50"
                          value={props.employeePhysical.physicalQ50}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Previous surgery(s)</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ51"
                          name="physicalQ51"
                          value={props.employeePhysical.physicalQ51}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Nervous disorders</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ52"
                          name="physicalQ52"
                          value={props.employeePhysical.physicalQ52}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Gallbladder trouble</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ53"
                          name="physicalQ53"
                          value={props.employeePhysical.physicalQ53}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Hospitalized for any reason</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ54"
                          name="physicalQ54"
                          value={props.employeePhysical.physicalQ54}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>
                        Fear of heights or confined spaces
                      </Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ55"
                          name="physicalQ55"
                          value={props.employeePhysical.physicalQ55}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Kidney or bladder problems</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ56"
                          name="physicalQ56"
                          value={props.employeePhysical.physicalQ56}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Current medications</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ57"
                          name="physicalQ57"
                          value={props.employeePhysical.physicalQ57}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Skin conditions</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ58"
                          name="physicalQ58"
                          value={props.employeePhysical.physicalQ57}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Liver disease</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ59"
                          name="physicalQ59"
                          value={props.employeePhysical.physicalQ59}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Other:</Typography>
                      <TextField
                        required
                        fullWidth
                        id="physicalQ60"
                        name="physicalQ60"
                        onChange={props.handlePhysicalChange}
                        value={props.employeePhysical.physicalQ60}
                      ></TextField>
                    </Grid>
                    <Grid md={9}>
                      <hr></hr>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Current Smoker/Former Smoker</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ61"
                          name="physicalQ61"
                          value={props.employeePhysical.physicalQ61}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>If "YES", how many packs a day?</Typography>
                      <TextField
                        required
                        fullWidth
                        id="physicalQ62"
                        name="physicalQ62"
                        onChange={props.handlePhysicalChange}
                        value={props.employeePhysical.physicalQ62}
                      ></TextField>
                    </Grid>
                    <Grid md={3}>
                      <Typography>For how many years?</Typography>
                      <TextField
                        required
                        fullWidth
                        id="physicalQ63"
                        name="physicalQ63"
                        onChange={props.handlePhysicalChange}
                        value={props.employeePhysical.physicalQ63}
                      ></TextField>
                    </Grid>
                    <Grid md={3}>
                      <Typography>
                        If you have quit smoking, how long ago did you quit?
                      </Typography>
                      <TextField
                        required
                        fullWidth
                        id="physicalQ64"
                        name="physicalQ64"
                        onChange={props.handlePhysicalChange}
                        value={props.employeePhysical.physicalQ64}
                      ></TextField>
                    </Grid>
                    <Grid md={9}>
                      <hr></hr>
                    </Grid>
                    <Grid md={3}>
                      <Typography>Do you drink alcoholic beverages</Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ65"
                          name="physicalQ65"
                          value={props.employeePhysical.physicalQ65}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>
                        If "YES", how many drinks in a week?
                      </Typography>
                      <TextField
                        required
                        fullWidth
                        id="physicalQ66"
                        name="physicalQ66"
                        onChange={props.handlePhysicalChange}
                        value={props.employeePhysical.physicalQ66}
                      ></TextField>
                    </Grid>
                    <Grid md={3}>
                      <Typography>What type of beverages?</Typography>
                      <TextField
                        required
                        fullWidth
                        id="physicalQ67"
                        name="physicalQ67"
                        onChange={props.handlePhysicalChange}
                        value={props.employeePhysical.physicalQ67}
                      ></TextField>
                    </Grid>
                    <Grid md={9}>
                      <hr></hr>
                    </Grid>
                    <Grid md={3}>
                      <Typography>
                        Have you ever had a drug or alcohol problem
                      </Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ68"
                          name="physicalQ68"
                          value={props.employeePhysical.physicalQ68}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>If "YES", please explain:</Typography>
                      <TextField
                        required
                        fullWidth
                        id="physicalQ69"
                        name="physicalQ69"
                        onChange={props.handlePhysicalChange}
                        value={props.employeePhysical.physicalQ69}
                      ></TextField>
                    </Grid>
                    <Grid md={9}>
                      <hr></hr>
                    </Grid>
                    <Grid md={3}>
                      <Typography>
                        Have you ever been injured at work?
                      </Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ70"
                          name="physicalQ70"
                          value={props.employeePhysical.physicalQ70}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>If "YES", please explain:</Typography>
                      <TextField
                        required
                        fullWidth
                        id="physicalQ71"
                        name="physicalQ71"
                        onChange={props.handlePhysicalChange}
                        value={props.employeePhysical.physicalQ71}
                      ></TextField>
                    </Grid>
                    <Grid md={9}>
                      <hr></hr>
                    </Grid>
                    <Grid md={3}>
                      <Typography>
                        Ever received workers compensation benefits?
                      </Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ72"
                          name="physicalQ72"
                          value={props.employeePhysical.physicalQ72}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>If "YES", please explain:</Typography>
                      <TextField
                        required
                        fullWidth
                        id="physicalQ73"
                        name="physicalQ73"
                        onChange={props.handlePhysicalChange}
                        value={props.employeePhysical.physicalQ73}
                      ></TextField>
                    </Grid>
                    <Grid md={9}>
                      <hr></hr>
                    </Grid>
                    <Grid md={3}>
                      <Typography>
                        Ever been place on "Light Duty" from an injury?
                      </Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ74"
                          name="physicalQ74"
                          value={props.employeePhysical.physicalQ74}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>If "YES", please explain:</Typography>
                      <TextField
                        required
                        fullWidth
                        id="physicalQ75"
                        name="physicalQ75"
                        onChange={props.handlePhysicalChange}
                        value={props.employeePhysical.physicalQ75}
                      ></TextField>
                    </Grid>
                    <Grid md={9}>
                      <hr></hr>
                    </Grid>
                    <Grid md={3}>
                      <Typography>
                        Has any injury or illness left you with any physical
                        limitation(s)?
                      </Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="physicalQ76"
                          name="physicalQ76"
                          value={props.employeePhysical.physicalQ76}
                          onChange={props.handlePhysicalChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid md={3}>
                      <Typography>If "YES", please explain:</Typography>
                      <TextField
                        required
                        fullWidth
                        id="physicalQ77"
                        name="physicalQ77"
                        onChange={props.handlePhysicalChange}
                        value={props.employeePhysical.physicalQ77}
                      ></TextField>
                    </Grid>
                    <Grid md={9}>
                      <hr></hr>
                    </Grid>
                    <Grid md={6}>
                      <TextField
                        required
                        fullWidth
                        id="physicalQ78"
                        name="physicalQ78"
                        onChange={props.handlePhysicalChange}
                        value={props.employeePhysical.physicalQ78}
                        label="Signature"
                      ></TextField>
                    </Grid>
                    <Grid md={3}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          id="physicalQ79"
                          name="physicalQ79"
                          label="Date"
                          onChange={(newDate) => {
                            props.setDatePhysical(newDate);
                            props.handleChange;
                          }}
                          value={props.datePhysical}
                          renderInput={(params) => (
                            <TextField {...params} required />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid md={4}>
                      <Button
                        variant="contained"
                        size="large"
                        onClick={handlePhysicalUpdate}
                      >
                        Save info
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Modal>
          </div>
        </Grid>
      </Paper>
    </Box>
  );
}
