import React, { useState, useContext } from "react";
import { GlobalContext } from "../utilities/globalContext";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import theme from "../components/theme";

export default function PasswordChange() {
  //Context
  const { URL, user, userId, setFirstLogin } = useContext(GlobalContext);

  //Login input state
  const [passwordInput, setPasswordInput] = useState({});
  const updatePasswordInput = (e) => {
    setPasswordInput({
      ...passwordInput,
      [e.target.name]: e.target.value,
      user: user,
      userId: userId,
    });
  };

  //Send login data function
  const passwordURL = `${URL}ThePortClinic-API/changePassword.php`;
  const sendPassword = (data, apiURL) => {
    if (passwordInput.password1 !== passwordInput.password2) {
      alert("Password doesn't match, please try again");
      return;
    }
    axios({
      method: "POST",
      url: apiURL,
      data: data,
      headers: {
        "Content-Type": "text/plain;charset=utf-8",
      },
    })
      .then((res) => {
        if (res.data.status === false) {
          console.log("No se pudo");
          return;
        } else if (res.data.status === true) {
          alert("Password updated succesfully!");
          setFirstLogin(0);
          console.log("Si se pudo");
        } else {
          console.log(res);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  };

  const handlePassword = () => {
    sendPassword(passwordInput, passwordURL);
  };

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      sendPassword(passwordInput, passwordURL);
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h4" variant="h4">
            Enter new password
          </Typography>
          <Box sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password1"
              label="Password"
              type="password"
              id="password1"
              autoComplete="current-password"
              onChange={updatePasswordInput}
              value={passwordInput.password1}
              onKeyDown={handleEnter}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password2"
              label="Confirm Password"
              type="password"
              id="password2"
              autoComplete="current-password"
              onChange={updatePasswordInput}
              value={passwordInput.password2}
              onKeyDown={handleEnter}
            />
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handlePassword}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
