import React, { useState, useEffect, useContext } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";

export default function SelectDate() {
  const [day, setDay] = useState(0);

  const handleChange = (event) => {
    setDay(event.target.value);
  };

  let days = [];
  useEffect(() => {
    for (let i = 1; i <= 31; i++) {
      days.push(i);
    }

    console.log(days);
  }, []);

  return (
    <Stack spacing={2} direction="row">
      <FormControl fullWidth>
        <InputLabel id="day-label">Day</InputLabel>
        <Select
          labelId="day-label"
          id="day"
          name="day"
          onChange={handleChange}
          value={day}
          label="Gender"
        >
          {days.map((daySelect) => {
            <MenuItem value={daySelect}>{daySelect}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </Stack>
  );
}
