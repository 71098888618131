import React, { useState, useContext, useEffect, useRef } from "react";
import { GlobalContext } from "../../utilities/globalContext";
import axios from "axios";
import ReactToPrint from "react-to-print";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { TabList, TabContext, TabPanel } from "@mui/lab";
import Typography from "@mui/material/Typography";
import PersonalInfo from "../Authorizations/Forms/DOTPhysical/PersonalInfo";
import DriverHealthHistory from "../Authorizations/Forms/DOTPhysical/DriverHealthHistory";
import DriverHealthHistoryReview from "../Authorizations/Forms/DOTPhysical/DriverHealthHistoryReview";
import Testing from "../Authorizations/Forms/DOTPhysical/Testing";
import PhysicalExamination from "../Authorizations/Forms/DOTPhysical/PhysicalExamination";
import MEDFed from "../Authorizations/Forms/DOTPhysical/MEDFed";
import insertForm from "../../utilities/Queries/insertNewForm";
import updateForm from "../../utilities/Queries/updateForm";
import getForm from "../../utilities/Queries/getForm";

export default function DOTPhysical(props) {
  const { URL } = useContext(GlobalContext);
  const [loadedData, setLoadedData] = useState(false);
  const [formData, setFormData] = useState({
    employee_id: props.employeeId,
    client_id: props.clientId,
    authorization_id: props.authId,
    form_name: "DOTPhysical",
  });
  const [formId, setFormId] = useState("");
  const [formInput, setFormInput] = useState({});

  const [value, setValue] = useState("0");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            variant="scrollable"
          >
            <Tab label="Personal Information" value="0"></Tab>
            <Tab label="Driver Health History" value="1"></Tab>
            <Tab label="Driver Health History (Review)" value="2"></Tab>
            <Tab label="Testing" value="3"></Tab>
            <Tab label="Physical Examination" value="4"></Tab>
            <Tab
              label="Medical Examiner Determination (Federal)"
              value="5"
            ></Tab>
          </TabList>
        </Box>
        <TabPanel value="0">
          <PersonalInfo
            formInput={props.loadData ? props.formInput : formInput}
            setFormInput={setFormInput}
            formData={formData}
            setFormData={setFormData}
            loadData={props.loadData}
          />
        </TabPanel>
        <TabPanel value="1">
          <DriverHealthHistory
            formInput={props.loadData ? props.formInput : formInput}
            setFormInput={setFormInput}
            formData={formData}
            setFormData={setFormData}
            loadData={props.loadData}
            URL={URL}
          />
        </TabPanel>
        <TabPanel value="2">
          <DriverHealthHistoryReview
            formInput={props.loadData ? props.formInput : formInput}
            setFormInput={setFormInput}
            formData={formData}
            setFormData={setFormData}
            loadData={props.loadData}
          />
        </TabPanel>
        <TabPanel value="3">
          <Testing
            formInput={props.loadData ? props.formInput : formInput}
            setFormInput={setFormInput}
            formData={formData}
            setFormData={setFormData}
            loadData={props.loadData}
          />
        </TabPanel>
        <TabPanel value="4">
          <PhysicalExamination
            formInput={props.loadData ? props.formInput : formInput}
            setFormInput={setFormInput}
            formData={formData}
            setFormData={setFormData}
            loadData={props.loadData}
          />
        </TabPanel>
        <TabPanel value="5">
          <MEDFed
            formInput={props.loadData ? props.formInput : formInput}
            setFormInput={setFormInput}
            formData={formData}
            setFormData={setFormData}
            URL={URL}
            insertForm={insertForm}
            loadData={props.loadData}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
