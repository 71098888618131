import { Outlet, Link, Navigate, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

const patientServiceList = () => {
  const columns = [
    { field: "id", headerName: "ID", width: 60 },
    { field: "formName", headerName: "Form Name", width: 320 },
    { field: "date", headerName: "Date", width: 160 },
    {
      field: "view",
      headerName: "More Info",
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Link
            to="/Service_Form"
            state={{ id: params.row.id, formName: params.row.formName }}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Button variant="outlined" size="small" color="secondary">
              View
            </Button>
          </Link>
        );
      },
    },
  ];

  const patientServices = { columns: columns };

  return patientServices;
};

export default patientServiceList;