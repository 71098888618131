import React, { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../utilities/globalContext";
import axios from "axios";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { setFormValues } from "../../utilities/Forms/formFunctions";
import insertForm from "../../utilities/Queries/insertNewForm";

export default function Audiogram(props) {
  //Context
  const { URL } = useContext(GlobalContext);
  //Form related states
  const [formInput, setFormInput] = useState({});
  const [dob, setDob] = useState("");
  const [date, setDate] = useState("");
  const [formData, setFormData] = useState({
    employee_id: props.employeeId,
    client_id: props.clientId,
    authorization_id: props.authId,
    form_name: "Audiogram",
  });

  const handleChange = (event) => {
    setFormValues(formInput, setFormInput, formData, setFormData, event);
  };

  const handleInsert = () => {
    insertForm(formData, URL);
  };

  return (
    <Box>
      <Paper elevation={5} sx={{ p: 3, mt: 2 }}>
        <Grid container spacing={2} columns={9}>
          <Typography variant="h5">
            Audiometry Testing Report
          </Typography>
          <Grid md={9}>
            <hr></hr>
          </Grid>
          <Grid md={3}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="date"
                name="date"
                label="Date of Service"
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setDate(newDate);
                  setFormInput({ ...formInput, date: newDate });
                  handleChange;
                }}
                value={props.loadData ? props.formInput.date : date}
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="patienName"
              name="patienName"
              onChange={handleChange}
              value={props.loadData ? props.formInput.patienName : formInput.patienName}
              label="Patient Name"
            ></TextField>
          </Grid>
          <Grid md={3}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="dob"
                name="dob"
                label="Patient's Date of Birth"
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setDob(newDate);
                  handleChange;
                }}
                value={props.loadData ? props.formInput.dob : dob}
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              <b>Patient Questionare:</b>
            </Typography>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              <b>1. Do you wear a hearing aid?</b>
            </Typography>
          </Grid>
          <Grid md={3}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="patientQuestionare1"
                name="patientQuestionare1"
                onChange={handleChange}
                value={props.loadData ? props.formInput.patientQuestionare1 : formInput.patientQuestionare1}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={6}></Grid>
          <Grid md={1}></Grid>
          <Grid md={1}>
            <Typography variant="subtitle1">
              <b>a. If yes, which ear?</b>
            </Typography>
          </Grid>
          <Grid md={7}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="patientQuestionare1A"
                name="patientQuestionare1A"
                onChange={handleChange}
                value={props.loadData ? props.formInput.patientQuestionare1A : formInput.patientQuestionare1A}
              >
                <FormControlLabel
                  value="Left"
                  control={<Radio />}
                  label="Left"
                />
                <FormControlLabel
                  value="Right"
                  control={<Radio />}
                  label="Right"
                />
                <FormControlLabel
                  value="Both"
                  control={<Radio />}
                  label="Both"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              <b>2. Have you had previous exposure to loud noises?</b>
            </Typography>
          </Grid>
          <Grid md={3}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="patientQuestionare2"
                name="patientQuestionare2"
                onChange={handleChange}
                value={props.loadData ? props.formInput.patientQuestionare2 : formInput.patientQuestionare2}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={6}></Grid>
          <Grid md={1}></Grid>
          <Grid md={1}>
            <Typography variant="subtitle1">
              <b>a. If yes, please explain?</b>
            </Typography>
          </Grid>
          <Grid md={7}>
            <TextField
              required
              fullWidth
              id="patientQuestionare2A"
              name="patientQuestionare2A"
              onChange={handleChange}
              value={props.loadData ? props.formInput.patientQuestionare2A : formInput.patientQuestionare2A}
              label=""
            ></TextField>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              <b>2. Have you had previous ear infections?</b>
            </Typography>
          </Grid>
          <Grid md={3}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="patientQuestionare3"
                name="patientQuestionare3"
                onChange={handleChange}
                value={props.loadData ? props.formInput.patientQuestionare3 : formInput.patientQuestionare3}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={6}></Grid>
          <Grid md={1}></Grid>
          <Grid md={1}>
            <Typography variant="subtitle1">
              <b>a. If yes, please explain?</b>
            </Typography>
          </Grid>
          <Grid md={7}>
            <TextField
              required
              fullWidth
              id="patientQuestionare3A"
              name="patientQuestionare3A"
              onChange={handleChange}
              value={props.loadData ? props.formInput.patientQuestionare3A : formInput.patientQuestionare3A}
              label=""
            ></TextField>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              <b>Results of Exam:</b>
            </Typography>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="examResults1"
              name="examResults1"
              onChange={handleChange}
              value={props.loadData ? props.formInput.examResults1 : formInput.examResults1}
              label="Normal Audiometry Exam"
            ></TextField>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="examResults2"
              name="examResults2"
              onChange={handleChange}
              value={props.loadData ? props.formInput.examResults2 : formInput.examResults2}
              label="Abnormal Audiometry Exam"
            ></TextField>
          </Grid>
          <Grid md={8}>
            <TextField
              required
              fullWidth
              id="examResults3"
              name="examResults3"
              onChange={handleChange}
              value={props.loadData ? props.formInput.examResults3 : formInput.examResults3}
              label="Recommendations"
            ></TextField>
          </Grid>
          <Grid md={9}>
            <hr></hr>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="signature1"
              name="signature1"
              onChange={handleChange}
              value={props.loadData ? props.formInput.signature1 : formInput.signature1}
              label="Antonio M. Diaz Jr. M.D., PA."
            ></TextField>
          </Grid>
          <Grid md={1}></Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="signature2"
              name="signature2"
              onChange={handleChange}
              value={props.loadData ? props.formInput.signature2 : formInput.signature2}
              label=""
            ></TextField>
          </Grid>
          <Grid md={5}>
            <Button variant="contained" onClick={handleInsert}>
              Send
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
