import React, { useState, useContext, useEffect, useRef } from "react";
import { GlobalContext } from "../../utilities/globalContext";
import axios from "axios";
import ReactToPrint from "react-to-print";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { TabList, TabContext, TabPanel } from "@mui/lab";
import Typography from "@mui/material/Typography";
import PersonalInfoWC from "../Authorizations/Forms/WorkersComp/PersonalInfoWC";
import InjuryReportWC from "../Authorizations/Forms/WorkersComp/InjuryReportWC";

export default function WorkersComp(props) {
  const { URL } = useContext(GlobalContext);
  const [formData, setFormData] = useState({
    employee_id: props.employeeId,
    client_id: props.clientId,
    authorization_id: props.authId,
    form_name: "WorkersComp",
  });
  const [formInput, setFormInput] = useState({});
  const [value, setValue] = useState("0");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            variant="scrollable"
          >
            <Tab label="Personal Information" value="0"></Tab>
            <Tab label="Injury Report" value="1"></Tab>
          </TabList>
          <TabPanel value="0">
            <PersonalInfoWC
              formInput={props.loadData ? props.formInput : formInput}
              setFormInput={setFormInput}
              formData={formData}
              setFormData={setFormData}
              loadData={props.loadData}
            />
          </TabPanel>
          <TabPanel value="1">
            <InjuryReportWC
              formInput={props.loadData ? props.formInput : formInput}
              setFormInput={setFormInput}
              formData={formData}
              setFormData={setFormData}
              loadData={props.loadData}
            />
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  );
}
