import React, { useState, useEffect } from "react";
import axios from "axios";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export default function ClientEmployees(props) {
  const deleteURL = `${props.url}ThePortClinic-API/deleteEmployee.php`;
  const deleteEmployee = (id) => {
    if (
      confirm(
        "You are about to delete this employee, do you want to continue?"
      ) === true
    ) {
      axios
        .post(deleteURL, { id: id })
        .then((response) => {
          console.log(response.data);
          if (response.data.status === true) {
            alert("Success!");
            props.setDeleteStatus(props.deleteStatus + 1);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return;
    }
  };

  const enableEdit = (id, date)=>{
    props.setEmployeeId(id);
    props.setEdit(true);
    props.setOpen(true);
    props.setDate(date)
  }

  console.log(props.employees);
  if (Array.isArray(props.employees)) {
    return props.employees.map((employee) => {
      return (
        <TableRow>
          <TableCell>
            {employee.middle_name != null
              ? `${employee.first_name} ${employee.middle_name} ${employee.last_name}`
              : `${employee.first_name} ${employee.last_name}`}
          </TableCell>
          <TableCell>{employee.phone}</TableCell>
          <TableCell>{employee.email}</TableCell>
          <TableCell>{employee.ssn}</TableCell>
          <TableCell>{employee.dob.substr(0, 10)}</TableCell>
          <TableCell>
            <Button
              variant="contained"
              onClick={() => {
                enableEdit(employee.id, employee.dob);
              }}
            >
              <EditIcon />
            </Button>
          </TableCell>
          <TableCell>
            <Button
              variant="contained"
              onClick={() => {
                deleteEmployee(employee.id);
              }}
            >
              <DeleteForeverIcon />
            </Button>
          </TableCell>
        </TableRow>
      );
    });
  } else {
    return (
      <TableRow>
        <TableCell>No employees...</TableCell>
      </TableRow>
    );
  }
}
