import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../utilities/globalContext";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Grid from "@mui/material/Unstable_Grid2";
import patientServiceList from "../utilities/patientServiceList";
import patientDocumentList from "../utilities/patientDocumnentList";
import getFiles from "../utilities/Queries/getFiles";
import axios from "axios";

export default function PatientServices() {
  const { URL } = useContext(GlobalContext);
  const location = useLocation();
  const { id } = location.state;
  const [servicesData, setServicesData] = useState([]);
  const patientServices = patientServiceList();
  const [files, setFiles] = useState([]);
  const patientDocuments = patientDocumentList();
  useEffect(() => {
    console.log(id);
    axios
      .get(`${URL}ThePortClinic-API/getForms.php?id=${id}`)
      .then((res) => {
        res.data.forEach((element) => {
          setServicesData(res.data);
        });
      })
      .catch((error) => {
        console.log(error);
      });

    getFiles(
      `${URL}ThePortClinic-API/getAuthorizationFiles.php?auth_id=${id}`,
      setFiles
    );
  }, []);

  let rows = servicesData.map((item) => ({
    id: item.id,
    formName: item.form_name,
    date: item.entry_date,
  }));

  let rowsDocs = files.map((item) => ({
    id: item.id,
    docName: item.document_name,
    date: item.entry_date,
  }))
  return (
    <Box sx={{ flexGrow: 1, height: "100%" }}>
      <Grid container sx={{ height: "100%" }} spacing={2} columns={4}>
        <Grid md={4}>
          <Typography variant="h4">Patient List</Typography>
        </Grid>
        <Grid mdOffset="auto">
          <Link
            to="/Open_Authorization_Info"
            state={{ id: id }}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Button variant="contained" color="primary">
              Go to Authorization
            </Button>
          </Link>
        </Grid>
        <Grid md={4}></Grid>
        <Grid md={2}>
          <Paper elevation={5} sx={{ p: 3, height: "70vh" }}>
            <Typography variant="subtitle1">Forms</Typography>
            <DataGrid
              rows={rows}
              columns={patientServices.columns}
              pageSize={15}
              rowsPerPageOptions={[15]}
              checkboxSelection
              components={{
                Toolbar: GridToolbar,
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: "id", sort: "desc" }],
                },
              }}
            />
          </Paper>
        </Grid>
        <Grid md={2}>
          <Paper elevation={5} sx={{ p: 3, height: "70vh" }}>
            <Typography variant="subtitle1">Documents</Typography>
            <DataGrid
              rows={rowsDocs}
              columns={patientDocuments.columns}
              pageSize={15}
              rowsPerPageOptions={[15]}
              checkboxSelection
              components={{
                Toolbar: GridToolbar,
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: "id", sort: "desc" }],
                },
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
