import * as React from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import { Paper } from "@mui/material";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from "@mui/material/Unstable_Grid2";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import reportList from "../utilities/reportList";


export default function Report(props){
  const location = useLocation();
  const { id } = location.state;
  const rows = reportList().rows;
  let data = rows.find(report => report.id === id);

  return(
    <Box>
      <Typography variant="h4">Report</Typography>
      <Paper elevation={5} sx={{ p: 3, mt: 2 }}>
        <Grid container spacing={2} columns={8}>
          <Grid md={4}>
            <Card variant="outlined">
              <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  Company:
                </Typography>
                <Typography variant="body2">
                  {data.company}
                </Typography>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  Service:
                </Typography>
                <Typography variant="body2">
                  {data.service}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid md={4}>
            <Card variant="outlined">
              <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  Patient:
                </Typography>
                <Typography variant="h5" component="div">
                  {data.name}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid md={8}>
            <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Documents</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody></TableBody>
                </Table>
              </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}