import React, { useState, useContext, useEffect } from "react";
import { Outlet, Link, Navigate, useNavigate } from "react-router-dom";
import { GlobalContext } from "./globalContext";
import axios from "axios";
import Button from "@mui/material/Button";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const clientList = (deleteStatus, setDeleteStatus) => {
  const { URL } = useContext(GlobalContext);
  const deleteURL = `${URL}ThePortClinic-API/deleteClient.php`;
  const deleteClient = (id) => {
    if (
      confirm(
        "You are about to delete this client and all information related to it, do you want to continue?"
      ) === true
    ) {
      axios
        .post(deleteURL, { id: id })
        .then((response) => {
          console.log(response.data);
          if (response.data.status === true) {
            alert("Success!");
            setDeleteStatus(deleteStatus + 1);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return;
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "companyName", headerName: "Company Name", width: 550 },
    {
      field: "view",
      headerName: "More Info",
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Link
            to="/Client"
            state={{ id: params.row.id }}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Button variant="outlined" size="small" color="secondary">
              View
            </Button>
          </Link>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Button
            onClick={() => {
              deleteClient(params.row.id);
            }}
            variant="outlined"
            size="small"
            color="secondary"
          >
            <DeleteForeverIcon />
          </Button>
        );
      },
    },
  ];

  const clients = { columns: columns };

  return clients;
};

export default clientList;
