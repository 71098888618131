import React, { useState, useEffect } from "react";
import axios from "axios";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export default function services(props) {
  const [updateServiceId, setUpdateServiceId] = useState(0);
  const handleUpdate = (service) => {
    setUpdateServiceId(service.id);
    setInput({
      ...service,
    });
  };

  //Handle input update
  const [input, setInput] = useState({});
  const updateInput = (event) => {
    setInput({
      ...input,
      [event.target.name]: event.target.value,
      id: updateServiceId,
    });
  };

  //Delete service
  const deleteURL = `${props.url}ThePortClinic-API/deleteService.php`;
  const deleteService = (id) => {
    if (
      confirm(
        "You are about to delete this service and all special prices related to it, do you want to continue?"
      ) === true
    ) {
      axios
      .post(deleteURL, { id: id })
      .then((response) => {
        console.log(response.data);
        if (response.data.status === true) {
          alert("Success!");
          props.setDeleteStatus(props.deleteStatus + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }else{
      return;
    }
  };

  //Update service
  const updateURL = `${props.url}ThePortClinic-API/updateService.php`;
  const updateService = (apiURL) => {
    axios
      .post(apiURL, input)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === true) {
          alert("Success!");
        }
        setUpdateServiceId(0);
        props.setUpdateStatus(props.updateStatus + 1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (Array.isArray(props.services)) {
    return props.services.map((service) => {
      if (service.id === updateServiceId) {
        return (
          <TableRow>
            <TableCell>
              <TextField
                fullWidth
                id="service"
                name="service"
                label="Service"
                onChange={updateInput}
                value={input.service}
              ></TextField>
            </TableCell>
            <TableCell>
              <TextField
                fullWidth
                id="price"
                name="price"
                label="Price"
                onChange={updateInput}
                value={input.price}
              ></TextField>
            </TableCell>
            <TableCell>
              <Button
                variant="contained"
                onClick={() => {
                  updateService(updateURL);
                }}
              >
                <CheckCircleOutlineIcon />
              </Button>
            </TableCell>
          </TableRow>
        );
      } else {
        return (
          <TableRow>
            <TableCell>{service.service}</TableCell>
            <TableCell>${Number(service.price).toFixed(2)}</TableCell>
            <TableCell>
              <Button
                variant="contained"
                onClick={() => {
                  handleUpdate(service);
                }}
              >
                <EditIcon />
              </Button>
            </TableCell>
            <TableCell>
              <Button
                variant="contained"
                onClick={() => {
                  deleteService(service.id);
                }}
              >
                <DeleteForeverIcon />
              </Button>
            </TableCell>
          </TableRow>
        );
      }
    });
  } else {
    return (
      <TableRow>
        <TableCell>No services...</TableCell>
      </TableRow>
    );
  }
}
