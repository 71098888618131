import React, { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../utilities/globalContext";
import axios from "axios";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { setFormValues } from "../../utilities/Forms/formFunctions";
import insertForm from "../../utilities/Queries/insertNewForm";

export default function Tuberculosis(props) {
  //Context
  const { URL } = useContext(GlobalContext);
  //Form related states
  const [formInput, setFormInput] = useState({});
  const [dob, setDob] = useState("");
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const [date3, setDate3] = useState("");
  const [dateAdministered1, setDateAdministered1] = useState("");
  const [dateRead, setDateRead] = useState("");
  const [dateAdministered2, setDateAdministered2] = useState("");
  const [formData, setFormData] = useState({
    employee_id: props.employeeId,
    client_id: props.clientId,
    authorization_id: props.authId,
    form_name: "Tuberculosis",
  });

  const handleChange = (event) => {
    setFormValues(formInput, setFormInput, formData, setFormData, event);
  };

  const handleInsert = () => {
    insertForm(formData, URL);
  };

  return (
    <Box>
      <Paper elevation={5} sx={{ p: 3, mt: 2 }}>
        <Grid container spacing={2} columns={9}>
          <Typography variant="h5">Tuberculosis (TB) Exam Form</Typography>
          <Grid md={9}>
            <hr></hr>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="injuredEmployeeName"
              name="injuredEmployeeName"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.injuredEmployeeName
                  : formInput.injuredEmployeeName
              }
              label="1. Injured Employee's Name"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="dob"
                name="dob"
                label="Date of Birth"
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setDob(newDate);
                  setFormInput({ ...formInput, dob: newDate });
                  handleChange;
                }}
                value={props.loadData ? props.formInput.dob : dob}
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={5}></Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="employeeId"
              name="employeeId"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.employeeId
                  : formInput.employeeId
              }
              label="Employee ID # (if applicable)"
            ></TextField>
          </Grid>
          <Grid md={7}></Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="parentName"
              name="parentName"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.parentName
                  : formInput.parentName
              }
              label="Parent or Guardian's Name (if applicable)"
            ></TextField>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              I understand that if my TB skin test result is positive, I must
              follow-up with a chest x-ray. I also agree to follow-up with the
              Cameron County Health Department for evaluation and treatment of
              tuberculosis. The Port Occupational and Medical Clinic, LLC., our
              medical provider and Staff will not be held liable for treatment
              of exposure or active tuberculosis.
            </Typography>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="signature1"
              name="signature1"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.signature1
                  : formInput.signature1
              }
              label="Signature"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="date1"
                name="date1"
                label="Date"
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setDate1(newDate);
                  setFormInput({ ...formInput, date1: newDate });
                  handleChange;
                }}
                value={props.loadData ? props.formInput.date1 : date1}
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={9}>
            <hr></hr>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              <b>Please provide the following questions:</b>
            </Typography>
          </Grid>
          <Grid md={1}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="questionare1"
                name="questionare1"
                onChange={handleChange}
                value={
                  props.loadData
                    ? props.formInput.questionare1
                    : formInput.questionare1
                }
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={8}>
            <Typography variant="subtitle1">
              Have you had the tuberculine (TB) test in the past?
            </Typography>
          </Grid>
          <Grid md={1}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="questionare2"
                name="questionare2"
                onChange={handleChange}
                value={
                  props.loadData
                    ? props.formInput.questionare2
                    : formInput.questionare2
                }
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={8}>
            <Typography variant="subtitle1">
              Was the test abnormal (red, swollen, blistered)?
              <Typography variant="subtitle2">
                <b>(If the answer is yes, a chest x-ray will be needed)</b>
              </Typography>
            </Typography>
          </Grid>
          <Grid md={1}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="questionare3"
                name="questionare3"
                onChange={handleChange}
                value={
                  props.loadData
                    ? props.formInput.questionare3
                    : formInput.questionare3
                }
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={8}>
            <Typography variant="subtitle1">
              Have you in the past taken medication to prevent toberculin (TB)
            </Typography>
          </Grid>
          <Grid md={1}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="questionare4"
                name="questionare4"
                onChange={handleChange}
                value={
                  props.loadData
                    ? props.formInput.questionare4
                    : formInput.questionare4
                }
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={8}>
            <Typography variant="subtitle1">
              Abnormal persistent weight loss?
              <Typography variant="subtitle2">
                <b>(If the answer is yes, notify provider)</b>
              </Typography>
            </Typography>
          </Grid>
          <Grid md={1}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="questionare5"
                name="questionare5"
                onChange={handleChange}
                value={
                  props.loadData
                    ? props.formInput.questionare5
                    : formInput.questionare5
                }
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={8}>
            <Typography variant="subtitle1">
              Do you cough up blood?
              <Typography variant="subtitle2">
                <b>(If the answer is yes, notify provider)</b>
              </Typography>
            </Typography>
          </Grid>
          <Grid md={1}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="questionare6"
                name="questionare6"
                onChange={handleChange}
                value={
                  props.loadData
                    ? props.formInput.questionare6
                    : formInput.questionare6
                }
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={8}>
            <Typography variant="subtitle1">
              Any recent contact with someone who had or suspected of having
              tuberculin (TB)?
              <Typography variant="subtitle2">
                <b>(If the answer is yes, notify provider)</b>
              </Typography>
            </Typography>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              I agree that these answers provided above are as true to my
              knowledge and give my full consent to be skin tested or have a
              chest x-ray taken.
            </Typography>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="signature2"
              name="signature2"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.signature2
                  : formInput.signature2
              }
              label="Signature"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="date2"
                name="date2"
                label="Date"
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setDate2(newDate);
                  setFormInput({ ...formInput, date2: newDate });
                  handleChange;
                }}
                value={props.loadData ? props.formInput.date2 : date2}
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={9}>
            <hr></hr>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              <b>For office use only</b>
            </Typography>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">Tuberculosis(PPD) Test</Typography>
          </Grid>
          <Grid md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="dateAdministered1"
                name="dateAdministered1"
                label="Date Administered"
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setDateAdministered1(newDate);
                  setFormInput({ ...formInput, dateAdministered1: newDate });
                  handleChange;
                }}
                value={
                  props.loadData
                    ? props.formInput.dateAdministered1
                    : dateAdministered1
                }
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="dateRead"
                name="dateRead"
                label="Date Read"
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setDateRead(newDate);
                  setFormInput({ ...formInput, dateRead: newDate });
                  handleChange;
                }}
                value={props.loadData ? props.formInput.dateRead : dateRead}
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={5}></Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="techInitials"
              name="techInitials"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.techInitials
                  : formInput.techInitials
              }
              label="Tech Initials"
            ></TextField>
          </Grid>
          <Grid md={1}>
            <Typography variant="subtitle2">Site:</Typography>
          </Grid>
          <Grid md={2}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="site"
                name="site"
                onChange={handleChange}
                value={props.loadData ? props.formInput.site : formInput.site}
              >
                <FormControlLabel
                  value="Left"
                  control={<Radio />}
                  label="Left"
                />
                <FormControlLabel
                  value="Right"
                  control={<Radio />}
                  label="Right"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={4}></Grid>
          <Grid md={9}>
            <Typography variant="subtitle2">Result:</Typography>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="result"
              name="result"
              onChange={handleChange}
              value={props.loadData ? props.formInput.result : formInput.result}
              label="mm"
            ></TextField>
          </Grid>
          <Grid md={7}>
            <Typography variant="subtitle2">
              (record in actual mm of duration. If no induration, write "0")
            </Typography>
          </Grid>
          <Grid md={2}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="resultA"
                name="resultA"
                onChange={handleChange}
                value={
                  props.loadData ? props.formInput.resultA : formInput.resultA
                }
              >
                <FormControlLabel
                  value="Positive"
                  control={<Radio />}
                  label="Positive"
                />
                <FormControlLabel
                  value="Negative"
                  control={<Radio />}
                  label="Negative"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">Chest X-ray</Typography>
          </Grid>
          <Grid md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="dateAdministered2"
                name="dateAdministered2"
                label="Date Administered"
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setDateAdministered2(newDate);
                  setFormInput({ ...formInput, dateAdministered2: newDate });
                  handleChange;
                }}
                value={
                  props.loadData
                    ? props.formInput.dateAdministered2
                    : dateAdministered2
                }
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={2}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="xray"
                name="xray"
                onChange={handleChange}
                value={props.loadData ? props.formInput.xray : formInput.xray}
              >
                <FormControlLabel
                  value="Negative"
                  control={<Radio />}
                  label="Negative"
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={5}></Grid>
          <Grid md={9}>
            <hr></hr>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="physicianName"
              name="physicianName"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physicianName
                  : formInput.physicianName
              }
              label="Name of Physician, NP, or PA"
            ></TextField>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="signature3"
              name="signature3"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.signature3
                  : formInput.signature3
              }
              label="Signature"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="date3"
                name="date3"
                label="Date"
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setDate3(newDate);
                  setFormInput({ ...formInput, date3: newDate });
                  handleChange;
                }}
                value={props.loadData ? props.formInput.date3 : date3}
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="texasLicenseNo"
              name="texasLicenseNo"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.texasLicenseNo
                  : formInput.texasLicenseNo
              }
              label="Texas License No."
            ></TextField>
          </Grid>
          <Grid md={5}>
            <Button variant="contained" onClick={handleInsert}>
              Send
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
