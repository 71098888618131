import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../utilities/globalContext";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Grid from "@mui/material/Unstable_Grid2";
import patientList from "../utilities/patientList";
import axios from "axios";

export default function PatientProfile() {
  const { URL } = useContext(GlobalContext);
  const [patientData, setPatientData] = useState([]);
  const patients = patientList();

  useEffect(() => {
    axios
      .get(`${URL}ThePortClinic-API/getAuthorizations.php?status=1`)
      .then((res) => {
        res.data.forEach((element) => {
          setPatientData(res.data);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  let rows = patientData.map((item) => ({
    id: item.id,
    name: item.employee_name,
    date: item.entry_date,
    company: item.company_name,
  }));
  return (
    <Box sx={{ flexGrow: 1, height: "100%" }}>
      <Grid container sx={{ height: "100%" }} spacing={2} columns={4}>
        <Grid md={4}>
          <Typography variant="h4">Patient List</Typography>
        </Grid>
        <Grid md={4}>
          <Paper elevation={5} sx={{ p: 3, height: "70vh" }}>
            <DataGrid
              rows={rows}
              columns={patients.columns}
              pageSize={15}
              rowsPerPageOptions={[15]}
              checkboxSelection
              components={{
                Toolbar: GridToolbar,
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: "id", sort: "desc" }],
                },
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
