import React, { useState, useNavigate, useContext } from "react";
import axios from "axios";
import { GlobalContext } from "../utilities/globalContext";
import { Outlet, Link, Navigate, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import { ClientList, AuthList, AdminList } from "../utilities/navList";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Logo from "../../public/The-Port-Clinic-Blanco.png";

const drawerWidth = 260;

export default function Navigation() {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { setLogin, userRole, URL } = useContext(GlobalContext);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setLogin(false);
    axios
      .get(`${URL}ThePortClinic-API/logout.php`)
      .then((res) => {
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <img src={Logo} alt="The Port Clinic" width={70} height="auto" />
          <Typography
            variant="h6"
            component="a"
            sx={{
              flexGrow: 1,
              color: "inherit",
              textDecoration: "none",
              ml: 2,
            }}
          >
            <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
              The Port Clinic
            </Link>
          </Typography>
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              sx={{ width: 320, maxWidth: "100%" }}
            >
              <MenuItem onClick={handleClose}>Settings</MenuItem>
              <MenuItem
                onClick={() => {
                  handleLogout();
                }}
              >
                Log Out
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <Typography
            m={2}
            color={"#0069B3"}
            variant="button"
            noWrap
            component="div"
          >
            {userRole === 1 || userRole === 2 || userRole === 3 || userRole === 4
              ? "Client Management"
              : ""}
          </Typography>
          <List>
            {userRole === 1 || userRole === 2 || userRole === 3 || userRole === 4 ? (
              <ClientList></ClientList>
            ) : null}
          </List>
          <Divider />
          <Typography
            m={2}
            color={"#0069B3"}
            variant="button"
            noWrap
            component="div"
          >
            {userRole === 1 || userRole === 2 || userRole === 3
              ? "Authorizations"
              : ""}
          </Typography>
          <List>
            {userRole === 1 || userRole === 2 || userRole === 3 ? (
              <AuthList></AuthList>
            ) : null}
          </List>
          <Divider />
          <Typography
            m={2}
            color={"#0069B3"}
            variant="button"
            noWrap
            component="div"
          >
            {userRole === 1 || userRole === 2 ? "Admin" : ""}
          </Typography>
          <List>
            {userRole === 1 || userRole === 2 ? <AdminList></AdminList> : null}
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}
