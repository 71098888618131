import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../utilities/globalContext";
import ClientContacts from "../clientContacts";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Modal from "@mui/material/Modal";
import axios from "axios";
import insertNewClient from "../../utilities/Queries/insertNewClient";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function ClientForm(props) {
  const { URL } = useContext(GlobalContext);
  const [deleteStatus, setDeleteStatus] = useState(0);
  const [insertStatus, setInsertStatus] = useState(0);
  const [updateStatus, setUpdateStatus] = useState(0);

  //Main form state
  const [dataInput, setDataInput] = React.useState({
    contact_type: "",
    accessType: "",
  });
  const updateDataInput = (e) => {
    setDataInput({
      ...data,
      ...dataInput,
      [e.target.name]: e.target.value,
    });
  };

  //Contact form state
  const [contactInput, setContactInput] = React.useState({});
  const updateContactInput = (e) => {
    setContactInput({
      ...contactInput,
      [e.target.name]: e.target.value,
      clientId: props.clientId,
    });
  };

  //Existing client info loading
  const [typeClient, setTypeClient] = useState(false);
  const [data, setData] = useState({});
  const clientURL = `${URL}ThePortClinic-API/getClient.php?id=${props.id}`;
  useEffect(() => {
    if (props.type) {
      axios
        .get(clientURL)
        .then((res) => {
          setData((data) => ({
            ...data,
            ...res.data,
          }));
          props.changeClientId(props.id);
          console.log(data);
        })
        .catch((error) => {
          console.log(error);
        });
      setTypeClient(true);
    }
  }, []);

  //Update client info
  const updateURL = `${URL}ThePortClinic-API/updateClient.php`;
  const updateData = (data, apiURL) => {
    axios
      .post(apiURL, data)
      .then((res) => {
        if (res.data.status === false) {
          console.log("No se pudo");
          return;
        } else if (res.data.status === true) {
          console.log("Si se pudo");
          alert("Success!");
          return;
        } else {
          console.log(res);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  };

  //Insert contact function
  const contactURL = `${URL}ThePortClinic-API/insertNewContact.php`;
  const insertNewData = (data, apiURL) => {
    axios
      .post(apiURL, data)
      .then((res) => {
        if (res.data.status === false) {
          console.log("No se pudo");
          return;
        } else if (res.data.status === true) {
          console.log("Si se pudo");
          alert("Success!");
          setInsertStatus(insertStatus + 1);
          props.changeClientId(res.data.id);
          return;
        } else {
          console.log(res);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  };

  //Get client contacts function
  const getContactsURL = `${URL}ThePortClinic-API/getClientContacts.php?client_id=${props.id}`;
  const [contactData, setContactData] = useState([]);
  const getContacts = (apiURL) => {
    axios
      .get(apiURL)
      .then((response) => {
        setContactData(response.data);
        console.log(contactData);
        console.log(props.clientId);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Modal toggle
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    insertNewData(contactInput, contactURL);
  };

  //Handle client update
  const handleUpdate = () => {
    updateData(dataInput, updateURL);
  };

  //Input data collecting
  const handleChange = (event) => {
    updateDataInput(event);
    setTypeClient(false);
    console.log(dataInput);
  };
  const handleChangeContact = (event) => {
    updateContactInput(event);
  };

  useEffect(() => {
    getContacts(getContactsURL);
  }, [insertStatus, deleteStatus, updateStatus]);

  return (
    <Box component="form">
      <Paper elevation={5} sx={{ p: 3, mt: 2 }}>
        <Grid container columns={8} spacing={2}>
          <Grid md={8}>
            <TextField
              required
              fullWidth
              id="companyName"
              name="company_name"
              label="Company Name"
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              value={
                typeClient === false
                  ? dataInput.company_name
                  : data.company_name
              }
            ></TextField>
          </Grid>
          <Grid md={6}>
            <TextField
              required
              fullWidth
              id="address"
              name="address"
              label="Address"
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              value={typeClient === false ? dataInput.address : data.address}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="suite"
              name="suite"
              label="Suite"
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              value={typeClient === false ? dataInput.suite : data.suite}
            ></TextField>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="city"
              name="city"
              label="City"
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              value={typeClient === false ? dataInput.city : data.city}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="state"
              name="state"
              label="State"
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              value={typeClient === false ? dataInput.state : data.state}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="zip"
              name="zip"
              label="Zip"
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              value={typeClient === false ? dataInput.zip : data.zip}
            ></TextField>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="companyPhone"
              name="company_phone"
              label="Company Phone"
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              value={
                typeClient === false
                  ? dataInput.company_phone
                  : data.company_phone
              }
            ></TextField>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="companyFax"
              name="company_fax"
              label="Company fax"
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              value={
                typeClient === false ? dataInput.company_fax : data.company_fax
              }
            ></TextField>
          </Grid>
          <Grid md={8}>
            <hr></hr>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="workersComp"
              name="wcic"
              label="Workers Compensation Insurance Company"
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              value={typeClient === false ? dataInput.wcic : data.wcic}
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="C_TPA"
              name="c_tpa"
              label="C/TPA (Consortium / Third Part Administrator)"
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              value={typeClient === false ? dataInput.c_tpa : data.c_tpa}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="phone"
              name="phone"
              label="Phone"
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              value={typeClient === false ? dataInput.phone : data.phone}
            ></TextField>
          </Grid>
          <Grid md={6}>
            <TextField
              required
              fullWidth
              id="dirBillAdd"
              name="direct_bill_address"
              label="Direct Bill Adress"
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              value={
                typeClient === false
                  ? dataInput.direct_bill_address
                  : data.direct_bill_address
              }
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="dirBillCity"
              name="direct_bill_city"
              label="Direct Bill City"
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              value={
                typeClient === false
                  ? dataInput.direct_bill_city
                  : data.direct_bill_city
              }
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="dirBillState"
              name="direct_bill_state"
              label="Direct Bill State"
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              value={
                typeClient === false
                  ? dataInput.direct_bill_state
                  : data.direct_bill_state
              }
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="dirBillZip"
              name="direct_bill_zip"
              label="Direct Bill Zip"
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              value={
                typeClient === false
                  ? dataInput.direct_bill_zip
                  : data.direct_bill_zip
              }
            ></TextField>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="dirBillAtt"
              name="direct_bill_attention"
              label="Direct Bill Attention"
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              value={
                typeClient === false
                  ? dataInput.direct_bill_attention
                  : data.direct_bill_attention
              }
            ></TextField>
          </Grid>
          <Grid>
            <Button variant="contained" size="large" onClick={handleUpdate}>
              Update info
            </Button>
          </Grid>
          <Grid md={8}>
            <hr></hr>
          </Grid>
          <Grid mdOffset="auto">
            <Button
              onClick={handleOpen}
              variant="contained"
              size="small"
              startIcon={<PermContactCalendarIcon />}
            >
              Add Contact
            </Button>
          </Grid>
          <Grid md={8}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Contact Name</TableCell>
                    <TableCell>Contact Email</TableCell>
                    <TableCell>Contact Phone</TableCell>
                    <TableCell>Contact Type</TableCell>
                    <TableCell>Access Type</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <ClientContacts
                    contacts={contactData}
                    url={URL}
                    clientId={props.id}
                    deleteStatus={deleteStatus}
                    setDeleteStatus={setDeleteStatus}
                    updateStatus={updateStatus}
                    setUpdateStatus={setUpdateStatus}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box component="form" sx={style}>
                <Grid container columns={8} spacing={2}>
                  <Grid md={8}>
                    <Typography variant="h4">
                      Enter new contact information
                    </Typography>
                  </Grid>
                  <Grid md={4}>
                    <TextField
                      required
                      fullWidth
                      id="contactName"
                      name="contactName"
                      label="Contact Name"
                      onChange={handleChangeContact}
                      value={contactInput.contactName}
                    ></TextField>
                  </Grid>
                  <Grid md={2}>
                    <TextField
                      required
                      fullWidth
                      id="contactEmail"
                      name="contactEmail"
                      label="Contact Email"
                      onChange={handleChangeContact}
                      value={contactInput.contactEmail}
                    ></TextField>
                  </Grid>
                  <Grid md={2}>
                    <TextField
                      required
                      fullWidth
                      id="contactPhone"
                      name="contactPhone"
                      label="Contact Phone"
                      onChange={handleChangeContact}
                      value={contactInput.contactPhone}
                    ></TextField>
                  </Grid>
                  <Grid md={2}>
                    <FormControl fullWidth>
                      <InputLabel id="contactType">Contact Type</InputLabel>
                      <Select
                        labelId="contactType"
                        id="contactType"
                        name="contactType"
                        label="Contact Type"
                        onChange={handleChangeContact}
                        defaultValue={""}
                      >
                        <MenuItem value="Default">Default</MenuItem>
                        <MenuItem value="Primary">Primary</MenuItem>
                        <MenuItem value="Billing">Billing</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid md={2}>
                    <FormControl fullWidth>
                      <InputLabel id="contactAccess">Access Type</InputLabel>
                      <Select
                        labelId="contactAccess"
                        id="contactAccess"
                        name="contactAccess"
                        label="Access Type"
                        onChange={handleChangeContact}
                        defaultValue={""}
                      >
                        <MenuItem value="Billing">Billing</MenuItem>
                        <MenuItem value="Default Contact">
                          Default Contact
                        </MenuItem>
                        <MenuItem value="Read Only">Read Only</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid md={4}></Grid>
                  <Grid md={2}>
                    <Button
                      variant="contained"
                      size="large"
                      onClick={handleClose}
                    >
                      Save contact
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
          </div>
        </Grid>
      </Paper>
    </Box>
  );
}
