import React, { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../utilities/globalContext";
import axios from "axios";
import ClientEmployees from "../clientEmployees";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import SelectDay from "../selectDate";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Modal from "@mui/material/Modal";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Label, ThirtyFpsOutlined } from "@mui/icons-material";
import { setFormValues } from "../../utilities/Forms/formFunctions";
import insertForm from "../../utilities/Queries/insertNewForm";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function Physical(props) {
  const [formInput, setFormInput] = useState({});
  const [dateOfExam, setDateOfExam] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [loadedData, setLoadedData] = useState(false);
  const [formData, setFormData] = useState({
    employee_id: props.employeeId,
    client_id: props.clientId,
    authorization_id: props.authId,
    form_name: "NonDOTPhysical",
  });
  const { URL } = useContext(GlobalContext);

  const handleChange = (event) => {
    setFormValues(formInput, setFormInput, formData, setFormData, event);
  };

  const handleInsert = () => {
    insertForm(formData, URL);
  };

  return (
    <Box>
      <Paper elevation={5} sx={{ p: 3, mt: 2 }}>
        <Grid container spacing={2} columns={9}>
          <Grid md={2}>
            <Typography variant="h5">Medical Record</Typography>
          </Grid>
          <Grid md={5}>
            <Typography variant="h5">Report of Medical Examination</Typography>
          </Grid>
          <Grid md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="dateOfExam"
                name="dateOfExam"
                label="Date of exam"
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setDateOfExam(newDate);
                  setFormInput({ ...formInput, dateOfExam: newDate });
                  handleChange;
                }}
                value={props.loadData ? props.formInput.dateOfExam : dateOfExam}
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={5}>
            <TextField
              required
              fullWidth
              id="EmployeeName"
              name="EmployeeName"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.EmployeeName
                  : formInput.EmployeeName
              }
              label="1. Last Name - First Name - Middle"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="idNumber"
              name="idNumber"
              onChange={handleChange}
              value={
                props.loadData ? props.formInput.idNumber : formInput.idNumber
              }
              label="2. Identification Number"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="gradeComponentPosition"
              name="gradeComponentPosition"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.gradeComponentPosition
                  : formInput.gradeComponentPosition
              }
              label="3. Grade and Component or Position"
            ></TextField>
          </Grid>
          <Grid md={5}>
            <TextField
              required
              fullWidth
              id="homeAddress"
              name="homeAddress"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.homeAddress
                  : formInput.homeAddress
              }
              label="4. Home Address (Number, street or RFD, city or town, state and ZIP Code"
            ></TextField>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="emergencyContact"
              name="emergencyContact"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.emergencyContact
                  : formInput.emergencyContact
              }
              label="5. Emergency Contact (Name and address of contact)"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                id="dateOfBirth"
                name="dateOfBirth"
                label="6. Date of birth"
                inputFormat="DD/MM/YYYY"
                onChange={(newDate) => {
                  setDateOfBirth(newDate);
                  setFormInput({ ...formInput, dateOfBirth: newDate });
                  handleChange;
                }}
                value={
                  props.loadData ? props.formInput.dateOfBirth : dateOfBirth
                }
                renderInput={(params) => <TextField {...params} required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={1}>
            <TextField
              required
              fullWidth
              id="age"
              name="age"
              onChange={handleChange}
              value={props.loadData ? props.formInput.age : formInput.age}
              label="7. Age"
            ></TextField>
          </Grid>
          <Grid md={3}>
            <Stack direction="row" spacing={2}>
              <Typography variant="subtitle1">8. Sex</Typography>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="sex"
                  onChange={handleChange}
                  value={props.loadData ? props.formInput.sex : formInput.sex}
                  name="sex"
                >
                  <FormControlLabel
                    value="Female"
                    control={<Radio />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="Male"
                    control={<Radio />}
                    label="Male"
                  />
                </RadioGroup>
              </FormControl>
            </Stack>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="relationshipOfContact"
              name="relationshipOfContact"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.relationshipOfContact
                  : formInput.relationshipOfContact
              }
              label="9. Relationship of Contact"
            ></TextField>
          </Grid>
          <Grid md={5}>
            <TextField
              required
              fullWidth
              id="placeOfBirth"
              name="placeOfBirth"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.placeOfBirth
                  : formInput.placeOfBirth
              }
              label="10. Place of Birth"
            ></TextField>
          </Grid>
          <Grid md={9}>
            <Stack direction="row" spacing={2}>
              <Typography variant="subtitle1">11. Race</Typography>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="race"
                  onChange={handleChange}
                  value={props.loadData ? props.formInput.sex : formInput.sex}
                  name="race"
                >
                  <FormControlLabel
                    value="White"
                    control={<Radio />}
                    label="White"
                  />
                  <FormControlLabel
                    value="Black"
                    control={<Radio />}
                    label="Black"
                  />
                  <FormControlLabel
                    value="American Indian/Alaska Native"
                    control={<Radio />}
                    label="American Indian/Alaska Native"
                  />
                  <FormControlLabel
                    value="Hispanic"
                    control={<Radio />}
                    label="Hispanic"
                  />
                  <FormControlLabel
                    value="Hispanic Black"
                    control={<Radio />}
                    label="Hispanic Black"
                  />
                  <FormControlLabel
                    value="Asian/Pacific Islander"
                    control={<Radio />}
                    label="Asian/Pacific Islander"
                  />
                </RadioGroup>
              </FormControl>
            </Stack>
          </Grid>
          <Grid md={5}>
            <TextField
              required
              fullWidth
              id="agency"
              name="agency"
              onChange={handleChange}
              value={props.loadData ? props.formInput.agency : formInput.agency}
              label="12a. Agency"
            ></TextField>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="organizationUnit"
              name="organizationUnit"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.organizationUnit
                  : formInput.organizationUnit
              }
              label="12b. Organization Unit"
            ></TextField>
          </Grid>
          <Grid md={3}>
            <Typography>13. Total Years Goverment Service</Typography>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="military"
              name="military"
              onChange={handleChange}
              value={
                props.loadData ? props.formInput.military : formInput.military
              }
              label="a. Military"
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="civilian"
              name="civilian"
              onChange={handleChange}
              value={
                props.loadData ? props.formInput.civilian : formInput.civilian
              }
              label="b. Civilian"
            ></TextField>
          </Grid>
          <Grid md={9}>
            <TextField
              required
              fullWidth
              id="examiningFacilityAddress"
              name="examiningFacilityAddress"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.examiningFacilityAddress
                  : formInput.examiningFacilityAddress
              }
              label="14. Name of Examining Facility or Examiner and Address"
            ></TextField>
          </Grid>
          <Grid md={5}>
            <TextField
              required
              fullWidth
              id="ratngSpecialtyExaminer"
              name="ratngSpecialtyExaminer"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.ratngSpecialtyExaminer
                  : formInput.ratngSpecialtyExaminer
              }
              label="15. Rating or Specialty of Examiner"
            ></TextField>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="purposeExamination"
              name="purposeExamination"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.purposeExamination
                  : formInput.purposeExamination
              }
              label="16. Purpose of Examination"
            ></TextField>
          </Grid>
          <Grid md={9}>
            <Typography variant="h6">17. Clinical Evaluation</Typography>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">
              A. Head, Face, Neck and Scalp
            </Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="headArea"
                name="headArea"
                value={
                  props.loadData ? props.formInput.headArea : formInput.headArea
                }
                onChange={handleChange}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">
              B. Ears - General (Internal Canals) (Auditory acuity under items
              39 and 40)
            </Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="earsGeneral"
                name="earsGeneral"
                value={
                  props.loadData
                    ? props.formInput.earsGeneral
                    : formInput.earsGeneral
                }
                onChange={handleChange}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">C. Drums (Perforation)</Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="drums"
                name="drums"
                value={props.loadData ? props.formInput.drums : formInput.drums}
                onChange={handleChange}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">C. Drums (Perforation)</Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="drums"
                name="drums"
                value={props.loadData ? props.formInput.drums : formInput.drums}
                onChange={handleChange}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">D. Nose</Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="nose"
                name="nose"
                value={props.loadData ? props.formInput.nose : formInput.nose}
                onChange={handleChange}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">E. Sinuses</Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="sinuses"
                name="sinuses"
                value={
                  props.loadData ? props.formInput.sinuses : formInput.sinuses
                }
                onChange={handleChange}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">F. Mouth and Throat</Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="mouthThroat"
                name="mouthThroat"
                value={
                  props.loadData
                    ? props.formInput.mouthThroat
                    : formInput.mouthThroat
                }
                onChange={handleChange}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">
              G. eyes - General (Visual acuity and refraction under items 28,
              29, ad 36)
            </Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="eyesGeneral"
                name="eyesGeneral"
                value={
                  props.loadData
                    ? props.formInput.eyesGeneral
                    : formInput.eyesGeneral
                }
                onChange={handleChange}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">H. Ophthalmoscopic</Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="ophthalmoscopic"
                name="ophthalmoscopic"
                value={
                  props.loadData
                    ? props.formInput.ophthalmoscopic
                    : formInput.ophthalmoscopic
                }
                onChange={handleChange}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">
              I. Pupils (Equiality and reaction)
            </Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="pupils"
                name="pupils"
                value={
                  props.loadData ? props.formInput.pupils : formInput.pupils
                }
                onChange={handleChange}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">
              J. Ocular motility (Associated parallel movements nystagmus)
            </Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="ocularMotility"
                name="ocularMotility"
                value={
                  props.loadData
                    ? props.formInput.ocularMotility
                    : formInput.ocularMotility
                }
                onChange={handleChange}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">K. Lungs and Chest</Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="lungsChest"
                name="lungsChest"
                value={
                  props.loadData
                    ? props.formInput.lungsChest
                    : formInput.lungsChest
                }
                onChange={handleChange}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">
              L. Heart (Thrust, size, rhythm, sounds)
            </Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="heart"
                name="heart"
                value={props.loadData ? props.formInput.heart : formInput.heart}
                onChange={handleChange}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">
              M. Vascular system (Varicosities, etc)
            </Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="vascularSystem"
                name="vascularSystem"
                value={
                  props.loadData
                    ? props.formInput.vascularSystem
                    : formInput.vascularSystem
                }
                onChange={handleChange}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">
              N. Abdomen and viscera (Include hernia)
            </Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="abdomenViscera"
                name="abdomenViscera"
                value={
                  props.loadData
                    ? props.formInput.abdomenViscera
                    : formInput.abdomenViscera
                }
                onChange={handleChange}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">
              O. Prostate (Over 40 or clinically indicated)
            </Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="prostate"
                name="prostate"
                value={
                  props.loadData ? props.formInput.prostate : formInput.prostate
                }
                onChange={handleChange}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">P. Testicular</Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="testicular"
                name="testicular"
                value={
                  props.loadData
                    ? props.formInput.testicular
                    : formInput.testicular
                }
                onChange={handleChange}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">
              Q. Anus and rectum (Hemorhoids, Fistulae) (Hemocult Results)
            </Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="anusRectum"
                name="anusRectum"
                value={
                  props.loadData
                    ? props.formInput.anusRectum
                    : formInput.anusRectum
                }
                onChange={handleChange}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">R. Endocrine system</Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="endocrineSystem"
                name="endocrineSystem"
                value={
                  props.loadData
                    ? props.formInput.endocrineSystem
                    : formInput.endocrineSystem
                }
                onChange={handleChange}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">S. G-U System</Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="guSystem"
                name="guSystem"
                value={
                  props.loadData ? props.formInput.guSystem : formInput.guSystem
                }
                onChange={handleChange}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">
              T. Upper extremities (Excerp feet) (Strength, range of motion)
            </Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="upperExtremities"
                name="upperExtremities"
                value={
                  props.loadData
                    ? props.formInput.upperExtremities
                    : formInput.upperExtremities
                }
                onChange={handleChange}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">U. Feet</Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="feet"
                name="feet"
                value={props.loadData ? props.formInput.feet : formInput.feet}
                onChange={handleChange}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">
              V. Lower extremities (Except feet) (Strength, range of motion)
            </Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="lowerExtremities"
                name="lowerExtremities"
                value={
                  props.loadData
                    ? props.formInput.lowerExtremities
                    : formInput.lowerExtremities
                }
                onChange={handleChange}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">
              W. Spine, other musculoskeletal
            </Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="spine"
                name="spine"
                value={props.loadData ? props.formInput.spine : formInput.spine}
                onChange={handleChange}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">
              X. Identifying body marks, scars, tattoos
            </Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="bodyMarks"
                name="bodyMarks"
                value={
                  props.loadData
                    ? props.formInput.bodyMarks
                    : formInput.bodyMarks
                }
                onChange={handleChange}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">Y. Skin, lymphatics</Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="skinLymphatics"
                name="skinLymphatics"
                value={
                  props.loadData
                    ? props.formInput.skinLymphatics
                    : formInput.skinLymphatics
                }
                onChange={handleChange}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">
              Z. Neurologic (Equilibrium test under item 41)
            </Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="neurologic"
                name="neurologic"
                value={
                  props.loadData
                    ? props.formInput.neurologic
                    : formInput.neurologic
                }
                onChange={handleChange}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">
              AA. Psychiatric (Specify any personality deviation)
            </Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="psychiatric"
                name="psychiatric"
                value={
                  props.loadData
                    ? props.formInput.psychiatric
                    : formInput.psychiatric
                }
                onChange={handleChange}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">BB. Breast</Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="breast"
                name="breast"
                value={
                  props.loadData ? props.formInput.breast : formInput.breast
                }
                onChange={handleChange}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <Typography variant="subtitle">
              CC. Pelvic (Females only)
            </Typography>
          </Grid>
          <Grid md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="pelvic"
                name="pelvic"
                value={
                  props.loadData ? props.formInput.pelvic : formInput.pelvic
                }
                onChange={handleChange}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormControlLabel
                  value="Normal"
                  control={<Radio />}
                  label="Normal"
                  sx={{ mr: 25 }}
                />
                <FormControlLabel
                  value="Abnormal"
                  control={<Radio />}
                  label="Abnormal"
                  sx={{ mr: 25 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={9}>
            <hr></hr>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              Notes: (Describe every abnormality in detail. Enter pertinent item
              number before each comment. Continue in item 42 and use aditional
              sheets if necessary)
            </Typography>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="surgeries"
              name="surgeries"
              onChange={handleChange}
              value={
                props.loadData ? props.formInput.surgeries : formInput.surgeries
              }
              label="Surgeries"
            ></TextField>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="tb"
              name="tb"
              onChange={handleChange}
              value={props.loadData ? props.formInput.tb : formInput.tb}
              label="TB"
            ></TextField>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="allergiesMedication"
              name="allergiesMedication"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.allergiesMedication
                  : formInput.allergiesMedication
              }
              label="Allergies to medications"
            ></TextField>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="tattoos"
              name="tattoos"
              onChange={handleChange}
              value={
                props.loadData ? props.formInput.tattoos : formInput.tattoos
              }
              label="Tattoos"
            ></TextField>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="asthma"
              name="asthma"
              onChange={handleChange}
              value={props.loadData ? props.formInput.asthma : formInput.asthma}
              label="Asthma"
            ></TextField>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="medicationsTaking"
              name="medicationsTaking"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.medicationsTaking
                  : formInput.medicationsTaking
              }
              label="Medications currently taking"
            ></TextField>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="alcohol"
              name="alcohol"
              onChange={handleChange}
              value={
                props.loadData ? props.formInput.alcohol : formInput.alcohol
              }
              label="Alcohol"
            ></TextField>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="epilepsy"
              name="epilepsy"
              onChange={handleChange}
              value={
                props.loadData ? props.formInput.epilepsy : formInput.epilepsy
              }
              label="Epilepsy"
            ></TextField>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="glassesContacts"
              name="glassesContacts"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.glassesContacts
                  : formInput.glassesContacts
              }
              label="Wear glasses / contacts"
            ></TextField>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="smoking"
              name="smoking"
              onChange={handleChange}
              value={
                props.loadData ? props.formInput.smoking : formInput.smoking
              }
              label="Smoking"
            ></TextField>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="hypertension"
              name="hypertension"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.hypertension
                  : formInput.hypertension
              }
              label="Hypertension"
            ></TextField>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="familyDiabetesCancer"
              name="familyDiabetesCancer"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.familyDiabetesCancer
                  : formInput.familyDiabetesCancer
              }
              label="Family HX: Diabetes / Cancer / HTN"
            ></TextField>
          </Grid>
          <Grid md={9}>
            <Typography variant="h6">18. Dental</Typography>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              Remarks and additional dental defects and diseases
            </Typography>
          </Grid>
          <Grid md={8}>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={6}
              id="remarks"
              name="remarks"
              value={
                props.loadData ? props.formInput.remarks : formInput.remarks
              }
              onChange={handleChange}
              style={{ width: 600 }}
            />
          </Grid>
          <Grid md={9}>
            <Typography variant="h6">
              19. Test results (Copies of results are preferred as attachments)
            </Typography>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">A. Urynalisis:</Typography>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="urineGravity"
              name="urineGravity"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.urineGravity
                  : formInput.urineGravity
              }
              label="(1) Specify gravity"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="urineAlbumin"
              name="urineAlbumin"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.urineAlbumin
                  : formInput.urineAlbumin
              }
              label="(2) Urine albumin"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="urineSugar"
              name="urineSugar"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.urineSugar
                  : formInput.urineSugar
              }
              label="(3) Urine sugar"
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="microscopic"
              name="microscopic"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.microscopic
                  : formInput.microscopic
              }
              label="(4) Microscopic"
            ></TextField>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="chestXRay"
              name="chestXRay"
              onChange={handleChange}
              value={
                props.loadData ? props.formInput.chestXRay : formInput.chestXRay
              }
              label="B. Chest X-Ray or PPD (Place, date, film number and result)"
            ></TextField>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="syphilisSerology"
              name="syphilisSerology"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.syphilisSerology
                  : formInput.syphilisSerology
              }
              label="C. Syphilis Serology (Specify test used and results"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="ekg"
              name="ekg"
              onChange={handleChange}
              value={props.loadData ? props.formInput.ekg : formInput.ekg}
              label="D. EKG"
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="bloodTypeHR"
              name="bloodTypeHR"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.bloodTypeHR
                  : formInput.bloodTypeHR
              }
              label="E. Blood type and HR factor"
            ></TextField>
          </Grid>
          <Grid md={9}>
            <TextField
              required
              fullWidth
              id="otherTests"
              name="otherTests"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.otherTests
                  : formInput.otherTests
              }
              label="F. Other tests"
            ></TextField>
          </Grid>
          <Grid md={9}>
            <hr></hr>
          </Grid>
          <Grid md={5}>
            <TextField
              required
              fullWidth
              id="physName"
              name="physName"
              onChange={handleChange}
              value={
                props.loadData ? props.formInput.physName : formInput.physName
              }
              label="Name"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="physIdentification"
              name="physIdentification"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physIdentification
                  : formInput.physIdentification
              }
              label="Identification"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="noSheetsAttached"
              name="noSheetsAttached"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.noSheetsAttached
                  : formInput.noSheetsAttached
              }
              label="No. of sheets attached"
            ></TextField>
          </Grid>
          <Grid md={9}>
            <Typography variant="h6">
              Measurements and other findings
            </Typography>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="physHeight"
              name="physHeight"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physHeight
                  : formInput.physHeight
              }
              label="20. Height"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="physWeight"
              name="physWeight"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physWeight
                  : formInput.physWeight
              }
              label="21. Weight"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="physColorHair"
              name="physColorHair"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physColorHair
                  : formInput.physColorHair
              }
              label="22. Color hair"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="physColorEyes"
              name="physColorEyes"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physColorEyes
                  : formInput.physColorEyes
              }
              label="23. Color eyes"
            ></TextField>
          </Grid>
          <Grid md={4}>
            <Stack direction="row" spacing={2}>
              <Typography variant="subtitle1">24. Build</Typography>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="sex"
                  onChange={handleChange}
                  value={props.loadData ? props.formInput.sex : formInput.sex}
                  name="sex"
                >
                  <FormControlLabel
                    value="Slender"
                    control={<Radio />}
                    label="Slender"
                  />
                  <FormControlLabel
                    value="Medium"
                    control={<Radio />}
                    label="Medium"
                  />
                  <FormControlLabel
                    value="Heavy"
                    control={<Radio />}
                    label="Heavy"
                  />
                  <FormControlLabel
                    value="Obese"
                    control={<Radio />}
                    label="Obese"
                  />
                </RadioGroup>
              </FormControl>
            </Stack>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="physTemperature"
              name="physTemperature"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physTemperature
                  : formInput.physTemperature
              }
              label="25. Temperature"
            ></TextField>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              26. Blood presure (Arm at heart level)
            </Typography>
          </Grid>
          <Grid m={2}>
            <Typography variant="subtitle2">A. Sitting</Typography>
          </Grid>
          <Grid md={1}>
            <TextField
              required
              fullWidth
              id="physSittingSys1"
              name="physSittingSys1"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physSittingSys1
                  : formInput.physSittingSys1
              }
              label="Sys."
            ></TextField>
          </Grid>
          <Grid md={1}>
            <TextField
              required
              fullWidth
              id="physSittingDias1"
              name="physSittingDias1"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physSittingDias1
                  : formInput.physSittingDias1
              }
              label="Dias."
            ></TextField>
          </Grid>
          <Grid m={2}>
            <Typography variant="subtitle2">B. Recumbent</Typography>
          </Grid>
          <Grid md={1}>
            <TextField
              required
              fullWidth
              id="physRecumbentSys1"
              name="physRecumbentSys1"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physRecumbentSys1
                  : formInput.physRecumbentSys1
              }
              label="Sys."
            ></TextField>
          </Grid>
          <Grid md={1}>
            <TextField
              required
              fullWidth
              id="physRecumbentDias1"
              name="physRecumbentDias1"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physRecumbentDias1
                  : formInput.physRecumbentDias1
              }
              label="Dias."
            ></TextField>
          </Grid>
          <Grid m={2}>
            <Typography variant="subtitle2">C. Standing (5 mins)</Typography>
          </Grid>
          <Grid md={1}>
            <TextField
              required
              fullWidth
              id="physStandingSys1"
              name="physStandingSys1"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physStandingSys1
                  : formInput.physStandingSys1
              }
              label="Sys."
            ></TextField>
          </Grid>
          <Grid md={1}>
            <TextField
              required
              fullWidth
              id="physStandingDias1"
              name="physStandingDias1"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physStandingDias1
                  : formInput.physStandingDias1
              }
              label="Dias."
            ></TextField>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              27. Pulse (Arm at heart level)
            </Typography>
          </Grid>
          <Grid md={1}>
            <TextField
              required
              fullWidth
              id="physPulseSitting"
              name="physPulseSitting"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physPulseSitting
                  : formInput.physPulseSitting
              }
              label="A. Sitting"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="physPulseRecumbent"
              name="physPulseRecumbent"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physPulseRecumbent
                  : formInput.physPulseRecumbent
              }
              label="B. Recumbent"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="physPulseStanding"
              name="physPulseStanding"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physPulseStanding
                  : formInput.physPulseStanding
              }
              label="C. Standing (3 mins)"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="physPulseExercise"
              name="physPulseExercise"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physPulseExercise
                  : formInput.physPulseExercise
              }
              label="D. After exercise"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="physPulseAfter"
              name="physPulseAfter"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physPulseAfter
                  : formInput.physPulseAfter
              }
              label="E. 2 mins. after"
            ></TextField>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">28. Distant vision</Typography>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="physDistVisionRight"
              name="physDistVisionRight"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physDistVisionRight
                  : formInput.physDistVisionRight
              }
              label="Right 20/"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="physDistVisionRightCorr"
              name="physDistVisionRightCorr"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physDistVisionRightCorr
                  : formInput.physDistVisionRightCorr
              }
              label="Corr. to 20/"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="physDistVisionLeft"
              name="physDistVisionLeft"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physDistVisionLeft
                  : formInput.physDistVisionLeft
              }
              label="Left 20/"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="physDistVisionLeftCorr"
              name="physDistVisionLeftCorr"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physDistVisionLeftCorr
                  : formInput.physDistVisionLeftCorr
              }
              label="Corr. to 20/"
            ></TextField>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">29. Refraction</Typography>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="physRefractionBy1"
              name="physRefractionBy1"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physRefractionBy1
                  : formInput.physRefractionBy1
              }
              label="By"
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="physRefractionS1"
              name="physRefractionS1"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physRefractionS1
                  : formInput.physRefractionS1
              }
              label="S."
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="physRefractionCx1"
              name="physRefractionCx1"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physRefractionCx1
                  : formInput.physRefractionCx1
              }
              label="CX."
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="physRefractionBy2"
              name="physRefractionBy2"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physRefractionBy2
                  : formInput.physRefractionBy2
              }
              label="By"
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="physRefractionS2"
              name="physRefractionS2"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physRefractionS2
                  : formInput.physRefractionS2
              }
              label="S."
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="physRefractionCx2"
              name="physRefractionCx2"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physRefractionCx2
                  : formInput.physRefractionCx2
              }
              label="CX."
            ></TextField>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">30. Near vision</Typography>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="physNearVisionRightCorr1"
              name="physNearVisionRightCorr1"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physNearVisionRightCorr1
                  : formInput.physNearVisionRightCorr1
              }
              label="Corr. to"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="physNearVisionBy1"
              name="physNearVisionBy1"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physNearVisionBy1
                  : formInput.physNearVisionBy1
              }
              label="By"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="physNearVisionRightCorr2"
              name="physNearVisionRightCorr2"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physNearVisionRightCorr2
                  : formInput.physNearVisionRightCorr2
              }
              label="Corr. to"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="physNearVisionBy2"
              name="physNearVisionBy2"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physNearVisionBy2
                  : formInput.physNearVisionBy2
              }
              label="By"
            ></TextField>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              31. Heterophoria (Specify distance)
            </Typography>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="physHPEso"
              name="physHPEso"
              onChange={handleChange}
              value={
                props.loadData ? props.formInput.physHPEso : formInput.physHPEso
              }
              label="Eso"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="physHPExo"
              name="physHPExo"
              onChange={handleChange}
              value={
                props.loadData ? props.formInput.physHPExo : formInput.physHPExo
              }
              label="Exo"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="physHPRH"
              name="physHPRH"
              onChange={handleChange}
              value={
                props.loadData ? props.formInput.physHPRH : formInput.physHPRH
              }
              label="R.H"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="physHPLH"
              name="physHPLH"
              onChange={handleChange}
              value={
                props.loadData ? props.formInput.physHPLH : formInput.physHPLH
              }
              label="L.H"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="physHPPrismDiv"
              name="physHPPrismDiv"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physHPPrismDiv
                  : formInput.physHPPrismDiv
              }
              label="Prism Div."
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="physHPPrismConv"
              name="physHPPrismConv"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physHPPrismConv
                  : formInput.physHPPrismConv
              }
              label="Prism Conv. CT"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="physHPPC"
              name="physHPPC"
              onChange={handleChange}
              value={
                props.loadData ? props.formInput.physHPPC : formInput.physHPPC
              }
              label="PC"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="physHPPD"
              name="physHPPD"
              onChange={handleChange}
              value={
                props.loadData ? props.formInput.physHPPD : formInput.physHPPD
              }
              label="PD"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <Typography variant="subtitle1">32. Accommodation</Typography>
          </Grid>
          <Grid md={1}>
            <TextField
              required
              fullWidth
              id="physAccommodationRight"
              name="physAccommodationRight"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physAccommodationRight
                  : formInput.physAccommodationRight
              }
              label="Right"
            ></TextField>
          </Grid>
          <Grid md={1}>
            <TextField
              required
              fullWidth
              id="physAccommodationLeft"
              name="physAccommodationLeft"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physAccommodationLeft
                  : formInput.physAccommodationLeft
              }
              label="Left"
            ></TextField>
          </Grid>
          <Grid md={5}>
            <TextField
              required
              fullWidth
              id="physColorVision"
              name="physColorVision"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physColorVision
                  : formInput.physColorVision
              }
              label="33. Color vision (Test used and result)"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <Typography variant="subtitle1">
              34. Depth Perception (Test used and score)
            </Typography>
          </Grid>
          <Grid md={1}>
            <TextField
              required
              fullWidth
              id="physDepthPerceptionUncorr"
              name="physDepthPerceptionUncorr"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physDepthPerceptionUncorr
                  : formInput.physDepthPerceptionUncorr
              }
              label="Uncorrected"
            ></TextField>
          </Grid>
          <Grid md={1}>
            <TextField
              required
              fullWidth
              id="physDepthPerceptionCorr"
              name="physDepthPerceptionCorr"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physDepthPerceptionCorr
                  : formInput.physDepthPerceptionCorr
              }
              label="Corrected"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <Typography variant="subtitle1">35. Field of vision</Typography>
          </Grid>
          <Grid md={1}>
            <TextField
              required
              fullWidth
              id="physFieldVisionRight"
              name="physFieldVisionRight"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physFieldVisionRight
                  : formInput.physFieldVisionRight
              }
              label="Right"
            ></TextField>
          </Grid>
          <Grid md={1}>
            <TextField
              required
              fullWidth
              id="physFieldVisionLeft"
              name="physFieldVisionLeft"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physFieldVisionLeft
                  : formInput.physFieldVisionLeft
              }
              label="Left"
            ></TextField>
          </Grid>
          <Grid md={5}>
            <TextField
              required
              fullWidth
              id="physNightVision"
              name="physNightVision"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physNightVision
                  : formInput.physNightVision
              }
              label="36. Night Vision (Test used and result)"
            ></TextField>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="physRedLens"
              name="physRedLens"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physRedLens
                  : formInput.physRedLens
              }
              label="37. Red lens"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <Typography variant="subtitle1">38. Intraocular tension</Typography>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="physIntraocularRight"
              name="physIntraocularRight"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physIntraocularRight
                  : formInput.physIntraocularRight
              }
              label="Right"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="physIntraocularLeft"
              name="physIntraocularLeft"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physIntraocularLeft
                  : formInput.physIntraocularLeft
              }
              label="Left"
            ></TextField>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">39. Hearing</Typography>
          </Grid>
          <Grid md={1}>
            <Typography variant="subtitle1">Right W/V</Typography>
          </Grid>
          <Grid md={1}>
            <TextField
              required
              fullWidth
              id="physHearingRight1"
              name="physHearingRight1"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physHearingRight1
                  : formInput.physHearingRight1
              }
              label="/15SV"
            ></TextField>
          </Grid>
          <Grid md={1}>
            <TextField
              required
              fullWidth
              id="physHearingRight2"
              name="physHearingRight2"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physHearingRight2
                  : formInput.physHearingRight2
              }
              label="/15"
            ></TextField>
          </Grid>
          <Grid md={1}>
            <Typography variant="subtitle1">Left W/V</Typography>
          </Grid>
          <Grid md={1}>
            <TextField
              required
              fullWidth
              id="physHearingLeft1"
              name="physHearingLeft1"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physHearingLeft1
                  : formInput.physHearingLeft1
              }
              label="/15SV"
            ></TextField>
          </Grid>
          <Grid md={1}>
            <TextField
              required
              fullWidth
              id="physHearingLeft2"
              name="physHearingLeft2"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physHearingLeft2
                  : formInput.physHearingLeft2
              }
              label="/15"
            ></TextField>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">40. Audiometer</Typography>
          </Grid>
          <Grid md={9}>
            <Stack direction="row" spacing={2}>
              <Typography variant="subtitle1">Right</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      props.formInput.physAudiometerRight1 ||
                      formInput.physAudiometerRight1
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      setFormInput({
                        ...formInput,
                        physAudiometerRight1: value,
                      });
                    }}
                  />
                }
                label="250/258"
                name="physAudiometerRight1"
                value={
                  props.loadData
                    ? props.formInput.physAudiometerRight1
                    : formInput.physAudiometerRight1
                }
                onChange={handleChange}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      props.formInput.physAudiometerRight2 ||
                      formInput.physAudiometerRight2
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      setFormInput({
                        ...formInput,
                        physAudiometerRight2: value,
                      });
                    }}
                  />
                }
                label="500/512"
                name="physAudiometerRight2"
                value={
                  props.loadData
                    ? props.formInput.physAudiometerRight2
                    : formInput.physAudiometerRight2
                }
                onChange={handleChange}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      props.formInput.physAudiometerRight3 ||
                      formInput.physAudiometerRight3
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      setFormInput({
                        ...formInput,
                        physAudiometerRight3: value,
                      });
                    }}
                  />
                }
                label="1000/1024"
                name="physAudiometerRight3"
                value={
                  props.loadData
                    ? props.formInput.physAudiometerRight3
                    : formInput.physAudiometerRight3
                }
                onChange={handleChange}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      props.formInput.physAudiometerRight4 ||
                      formInput.physAudiometerRight4
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      setFormInput({
                        ...formInput,
                        physAudiometerRight4: value,
                      });
                    }}
                  />
                }
                label="2000/2048"
                name="physAudiometerRight4"
                value={
                  props.loadData
                    ? props.formInput.physAudiometerRight4
                    : formInput.physAudiometerRight4
                }
                onChange={handleChange}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      props.formInput.physAudiometerRight5 ||
                      formInput.physAudiometerRight5
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      setFormInput({
                        ...formInput,
                        physAudiometerRight5: value,
                      });
                    }}
                  />
                }
                label="3000/2896"
                name="physAudiometerRight5"
                value={
                  props.loadData
                    ? props.formInput.physAudiometerRight5
                    : formInput.physAudiometerRight5
                }
                onChange={handleChange}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      props.formInput.physAudiometerRight6 ||
                      formInput.physAudiometerRight6
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      setFormInput({
                        ...formInput,
                        physAudiometerRight6: value,
                      });
                    }}
                  />
                }
                label="4000/4095"
                name="physAudiometerRight6"
                value={
                  props.loadData
                    ? props.formInput.physAudiometerRight6
                    : formInput.physAudiometerRight6
                }
                onChange={handleChange}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      props.formInput.physAudiometerRight7 ||
                      formInput.physAudiometerRight7
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      setFormInput({
                        ...formInput,
                        physAudiometerRight7: value,
                      });
                    }}
                  />
                }
                label="6000/6144"
                name="physAudiometerRight7"
                value={
                  props.loadData
                    ? props.formInput.physAudiometerRight7
                    : formInput.physAudiometerRight7
                }
                onChange={handleChange}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      props.formInput.physAudiometerRight8 ||
                      formInput.physAudiometerRight8
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      setFormInput({
                        ...formInput,
                        physAudiometerRight8: value,
                      });
                    }}
                  />
                }
                label="8000/8192"
                name="physAudiometerRight8"
                value={
                  props.loadData
                    ? props.formInput.physAudiometerRight8
                    : formInput.physAudiometerRight8
                }
                onChange={handleChange}
              />
            </Stack>
          </Grid>
          <Grid md={9}>
            <Stack direction="row" spacing={2}>
              <Typography variant="subtitle1">Left</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      props.formInput.physAudiometerLeft1 ||
                      formInput.physAudiometerLeft1
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      setFormInput({
                        ...formInput,
                        physAudiometerLeft1: value,
                      });
                    }}
                  />
                }
                label="250/258"
                name="physAudiometerLeft1"
                value={
                  props.loadData
                    ? props.formInput.physAudiometerLeft1
                    : formInput.physAudiometerLeft1
                }
                onChange={handleChange}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      props.formInput.physAudiometerLeft2 ||
                      formInput.physAudiometerLeft2
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      setFormInput({
                        ...formInput,
                        physAudiometerLeft2: value,
                      });
                    }}
                  />
                }
                label="500/512"
                name="physAudiometerLeft2"
                value={
                  props.loadData
                    ? props.formInput.physAudiometerLeft2
                    : formInput.physAudiometerLeft2
                }
                onChange={handleChange}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      props.formInput.physAudiometerLeft3 ||
                      formInput.physAudiometerLeft3
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      setFormInput({
                        ...formInput,
                        physAudiometerLeft3: value,
                      });
                    }}
                  />
                }
                label="1000/1024"
                name="physAudiometerLeft"
                value={
                  props.loadData
                    ? props.formInput.physAudiometerLeft3
                    : formInput.physAudiometerLeft3
                }
                onChange={handleChange}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      props.formInput.physAudiometerLeft4 ||
                      formInput.physAudiometerLeft4
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      setFormInput({
                        ...formInput,
                        physAudiometerLeft4: value,
                      });
                    }}
                  />
                }
                label="2000/2048"
                name="physAudiometerLeft4"
                value={
                  props.loadData
                    ? props.formInput.physAudiometerLeft4
                    : formInput.physAudiometerLeft4
                }
                onChange={handleChange}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      props.formInput.physAudiometerLeft5 ||
                      formInput.physAudiometerLeft5
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      setFormInput({
                        ...formInput,
                        physAudiometerLeft5: value,
                      });
                    }}
                  />
                }
                label="3000/2896"
                name="physAudiometerLeft5"
                value={
                  props.loadData
                    ? props.formInput.physAudiometerLeft5
                    : formInput.physAudiometerLeft5
                }
                onChange={handleChange}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      props.formInput.physAudiometerLeft6 ||
                      formInput.physAudiometerLeft6
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      setFormInput({
                        ...formInput,
                        physAudiometerLeft6: value,
                      });
                    }}
                  />
                }
                label="4000/4095"
                name="physAudiometerLeft6"
                value={
                  props.loadData
                    ? props.formInput.physAudiometerLeft6
                    : formInput.physAudiometerLeft6
                }
                onChange={handleChange}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      props.formInput.physAudiometerLeft7 ||
                      formInput.physAudiometerLeft7
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      setFormInput({
                        ...formInput,
                        physAudiometerLeft7: value,
                      });
                    }}
                  />
                }
                label="6000/6144"
                name="physAudiometerLeft7"
                value={
                  props.loadData
                    ? props.formInput.physAudiometerLeft7
                    : formInput.physAudiometerLeft7
                }
                onChange={handleChange}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      props.formInput.physAudiometerLeft8 ||
                      formInput.physAudiometerLeft8
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      const value = e.target.checked ? "on" : "";
                      setFormInput({
                        ...formInput,
                        physAudiometerLeft8: value,
                      });
                    }}
                  />
                }
                label="8000/8192"
                name="physAudiometerLeft8"
                value={
                  props.loadData
                    ? props.formInput.physAudiometerLeft8
                    : formInput.physAudiometerLeft8
                }
                onChange={handleChange}
              />
            </Stack>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">Audiometer notes</Typography>
          </Grid>
          <Grid md={9}>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={6}
              id="physAudiometerNotes"
              name="physAudiometerNotes"
              value={
                props.loadData
                  ? props.formInput.physAudiometerNotes
                  : formInput.physAudiometerNotes
              }
              onChange={handleChange}
              style={{ width: 600 }}
            />
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              41. Psychological and psychomotor (Tests used and score)
            </Typography>
          </Grid>
          <Grid md={9}>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={6}
              id="physPsychological"
              name="physPsychological"
              value={
                props.loadData
                  ? props.formInput.physPsychological
                  : formInput.physPsychological
              }
              onChange={handleChange}
              style={{ width: 600 }}
            />
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              42. Notes (Continued) and significant or internal history
            </Typography>
          </Grid>
          <Grid md={9}>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={6}
              id="physNotesHistory"
              name="physNotesHistory"
              value={
                props.loadData
                  ? props.formInput.physNotesHistory
                  : formInput.physNotesHistory
              }
              onChange={handleChange}
              style={{ width: 600 }}
            />
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              43. Summary of defects and diagnoses (List diagnoses with item
              number)
            </Typography>
          </Grid>
          <Grid md={9}>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={6}
              id="physSummaryDiagnoses"
              name="physSummaryDiagnoses"
              value={
                props.loadData
                  ? props.formInput.physSummaryDiagnoses
                  : formInput.physSummaryDiagnoses
              }
              onChange={handleChange}
              style={{ width: 600 }}
            />
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              44. Recomendations - Further specialist examinations indicated
              (Specify)
            </Typography>
          </Grid>
          <Grid md={9}>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={6}
              id="physRecomendations"
              name="physRecomendations"
              value={
                props.loadData
                  ? props.formInput.physRecomendations
                  : formInput.physRecomendations
              }
              onChange={handleChange}
              style={{ width: 600 }}
            />
          </Grid>
          <Grid md={9}>
            <Stack direction="row" spacing={2}>
              <Typography variant="subtitle1">45. Examinee (Check)</Typography>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="physPhysicalCategory"
                  onChange={handleChange}
                  value={
                    props.loadData
                      ? props.formInput.physPhysicalCategory
                      : formInput.physPhysicalCategory
                  }
                  name="physPhysicalCategory"
                >
                  <FormControlLabel
                    value="Qualified for"
                    control={<Radio />}
                    label="A. Is qualified for"
                  />
                  <FormControlLabel
                    value="Not qualified for"
                    control={<Radio />}
                    label="B. Is not qualified for"
                  />
                </RadioGroup>
              </FormControl>
            </Stack>
          </Grid>
          <Grid md={9}>
            <Typography variant="subtitle1">
              46. If not quaified, list disqualifying defects by item number
            </Typography>
          </Grid>
          <Grid md={9}>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={6}
              id="physDisqualified"
              name="physDisqualified"
              value={
                props.loadData
                  ? props.formInput.physDisqualified
                  : formInput.physDisqualified
              }
              onChange={handleChange}
              style={{ width: 600 }}
            />
          </Grid>
          <Grid md={2}>
            <Typography variant="subtitle1">
              47. Typed or printed name of physician
            </Typography>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="physNamePhysician1"
              name="physNamePhysician1"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physNamePhysician1
                  : formInput.physNamePhysician1
              }
              label="Name"
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="physSignaturePhysician1"
              name="physSignaturePhysician1"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physSignaturePhysician1
                  : formInput.physSignaturePhysician1
              }
              label="Signature"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <Typography variant="subtitle1">
              48. Typed or printed name of physician
            </Typography>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="physNamePhysician2"
              name="physNamePhysician2"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physNamePhysician2
                  : formInput.physNamePhysician2
              }
              label="Name"
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="physSignaturePhysician2"
              name="physSignaturePhysician2"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physSignaturePhysician2
                  : formInput.physSignaturePhysician2
              }
              label="Signature"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <Typography variant="subtitle1">
              49. Typed or printed name of dentist of physician (Indicate which)
            </Typography>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="physNamePhysicianDentist"
              name="physNamePhysicianDentist"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physNamePhysicianDentist
                  : formInput.physNamePhysicianDentist
              }
              label="Name"
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="physSignaturePhysicianDentist"
              name="physSignaturePhysicianDentist"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physSignaturePhysicianDentist
                  : formInput.physSignaturePhysicianDentist
              }
              label="Signature"
            ></TextField>
          </Grid>
          <Grid md={2}>
            <Typography variant="subtitle1">
              50. Typed or printed name of reviewing officer or approving
              authority
            </Typography>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="physNameReviewingAuth"
              name="physNameReviewingAuth"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physNameReviewingAuth
                  : formInput.physNameReviewingAuth
              }
              label="Name"
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="physSignatureReviewingAuth"
              name="physSignatureReviewingAuth"
              onChange={handleChange}
              value={
                props.loadData
                  ? props.formInput.physSignatureReviewingAuth
                  : formInput.physSignatureReviewingAuth
              }
              label="Signature"
            ></TextField>
          </Grid>
          <Grid md={5}>
            <Button variant="contained" onClick={handleInsert}>
              Send
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
