import axios from "axios";

const getAuthorizations = (apiURL, setAuthorizations) => {
  axios
    .get(apiURL, { responseType: "json" })
    .then((res) => {
      if (res.data.status === false) {
        return;
      }
      setAuthorizations(res.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default getAuthorizations;
