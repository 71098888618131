import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../utilities/globalContext";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "axios";

export default function EmployeeInformation(props) {
  const { URL } = useContext(GlobalContext);
  const [date, setDate] = useState("");
  const [employeeInput, setEmployeeInput] = useState({});
  const [updateStatus, setUpdateStatus] = useState(0);

  //Get employee
  const getEmployeeURL = `${URL}ThePortClinic-API/getEmployee.php?id=${props.employeeId}`;
  const getEmployee = (url) => {
    axios
      .get(url)
      .then((response) => {
        setEmployeeInput(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Get states
  const getStatesURL = `${URL}ThePortClinic-API/getStates.php`;
  const [statesData, setStatestData] = useState([]);
  const getStates = (apiURL) => {
    axios
      .get(apiURL)
      .then((response) => {
        setStatestData(response.data);
        console.log(statesData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectOptions = () => {
    try {
      return statesData.map((item) => (
        <MenuItem value={item.state}>{item.state}</MenuItem>
      ));
    } catch {
      return <MenuItem value={0}>No states...</MenuItem>;
    }
  };

  const handleChange = (event) => {
    setEmployeeInput({
      ...employeeInput,
      [event.target.name]: event.target.value,
      client_id: props.clientId,
      id: props.employeeId,
      dob: date,
    });
  };

  useEffect(() => {
    getEmployee(getEmployeeURL);
    getStates(getStatesURL);
  }, [updateStatus]);

  return (
    <Box component="form">
      <Paper elevation={5} sx={{ p: 3, mt: 2 }}>
        <Grid container columns={8} spacing={2}>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="first_name"
              name="first_name"
              label="Employee First Name"
              value={employeeInput.first_name}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="middle_name"
              name="middle_name"
              label="Employee Middle Name"
              value={employeeInput.middle_name}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="last_name"
              name="last_name"
              label="Employee Last Name"
              value={employeeInput.last_name}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <FormControl fullWidth>
              <InputLabel id="gender-label">Gender</InputLabel>
              <Select
                labelId="gender-label"
                id="gender"
                name="gender"
                onChange={handleChange}
                value={!employeeInput.gender ? "" : employeeInput.gender}
                label="Gender"
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="address"
              name="address"
              label="Employee Adress"
              value={employeeInput.address}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="suite"
              name="suite"
              label="Suite"
              value={employeeInput.suite}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="city"
              name="city"
              label="City"
              value={employeeInput.city}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <FormControl fullWidth>
              <InputLabel id="state-label">State</InputLabel>
              <Select
                labelId="state-label"
                id="state"
                name="state"
                label="State"
                onChange={handleChange}
                value={!employeeInput.state ? "" : employeeInput.state}
              >
                {selectOptions()}
              </Select>
            </FormControl>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="zip"
              name="zip"
              label="Zip"
              value={employeeInput.zip}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="phone"
              name="phone"
              label="Phone"
              value={employeeInput.phone}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={employeeInput.email}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Date of birth"
                value={employeeInput.dob}
                onChange={(newDate) => {
                  setDate(newDate);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="ssn"
              name="ssn"
              label="Social Security Number"
              value={employeeInput.ssn}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Grid>
          <Grid md={4}>
            <TextField
              required
              fullWidth
              id="dln"
              name="dln"
              label="Drivers License Number"
              value={employeeInput.dln}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
