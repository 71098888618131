import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { setFormValues } from "../../../../utilities/Forms/formFunctions";

export default function MEDFed(props) {
  const handleForm = (event) => {
    setFormValues(
      props.formInput,
      props.setFormInput,
      props.formData,
      props.setFormData,
      event
    );
  };

  const handleInsert = () => {
    props.insertForm(props.formData,props.URL);
  };

  return (
    <Box component="form">
      <Paper elevation={5} sx={{ p: 3, mt: 2 }}>
        <Grid container columns={10} spacing={2}>
          <Grid md={10}>
            <Typography variant="subtitle">
              Use this section for examinations performed in accordance with the
              Federal Motor Carrier Safety Regulations (49 CFR 391.41-391.49):
            </Typography>
          </Grid>
          <Grid md={10}>
            <FormControl>
              <RadioGroup
                aria-labelledby="MEDStandards"
                name="MEDStandards"
                value={props.formInput.MEDStandards}
                onChange={handleForm}
              >
                <FormControlLabel
                  value="standard1"
                  control={<Radio />}
                  label="Does not meet standards (specify reason):"
                />
                <TextField
                  required
                  fullWidth
                  id="standard1Text"
                  name="standard1Text"
                  value={props.formInput.standard1Text}
                  onChange={handleForm}
                ></TextField>
                <FormControlLabel
                  value="standard2"
                  control={<Radio />}
                  label="Meets standards in 49 CFR 391.41; qualifies for 2-year certificate."
                />
                <FormControlLabel
                  value="standard3"
                  control={<Radio />}
                  label="Meets standards, but periodic monitoring required (specify reason):"
                />
                <TextField
                  required
                  fullWidth
                  id="standards2Text"
                  name="standards2Text"
                  value={props.formInput.standards2Text}
                  onChange={handleForm}
                ></TextField>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={10}>
            <Typography variant="subtitle">Driver qualified for:</Typography>
          </Grid>
          <Grid md={10}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="driverQualified"
                name="driverQualified"
                value={props.formInput.driverQualified}
                onChange={handleForm}
              >
                <FormControlLabel
                  value="3 months"
                  control={<Radio />}
                  label="3 Months"
                />
                <FormControlLabel
                  value="6 months"
                  control={<Radio />}
                  label="6 months"
                />
                <FormControlLabel
                  value="1 year"
                  control={<Radio />}
                  label="1 year"
                />
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label="other (specify):"
                />
                <TextField
                  required
                  id="DQOtherText"
                  name="DQOtherText"
                  value={props.formInput.DQOtherText}
                  onChange={handleForm}
                ></TextField>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.formInput.correctiveLenses ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    props.setFormInput({
                      ...props.formInput,
                      correctiveLenses: value,
                    });
                  }}
                />
              }
              label="Wearing corrective lenses"
              name="correctiveLenses"
              value={props.formInput.correctiveLenses}
              onChange={handleForm}
            />
          </Grid>
          <Grid md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.formInput.hearingAid ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    props.setFormInput({
                      ...props.formInput,
                      hearingAid: value,
                    });
                  }}
                />
              }
              label="Wearing hearing aid"
              name="hearingAid"
              value={props.formInput.hearingAid}
              onChange={handleForm}
            />
          </Grid>
          <Grid md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.formInput.waiverExemption ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    props.setFormInput({
                      ...props.formInput,
                      waiverExemption: value,
                    });
                  }}
                />
              }
              label="Accompanied by a waiver/exemption (specify type):"
              name="waiverExemption"
              value={props.formInput.waiverExemption}
              onChange={handleForm}
            />
          </Grid>
          <Grid md={10}>
            <TextField
              required
              fullWidth
              id="waiverExemptionText"
              name="waiverExemptionText"
              value={props.formInput.waiverExemptionText}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.formInput.SPECert ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    props.setFormInput({ ...props.formInput, SPECert: value });
                  }}
                />
              }
              label="Accompanied by a Skill Performance Evaluation (SPE) Certificate"
              name="SPECert"
              value={props.formInput.SPECert}
              onChange={handleForm}
            />
          </Grid>
          <Grid md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.formInput.qualified49CFR ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    props.setFormInput({
                      ...props.formInput,
                      qualified49CFR: value,
                    });
                  }}
                />
              }
              label="Qualified by operation of 49 CFR 391.64 (Federal)"
              name="qualified49CFR"
              value={props.formInput.qualified49CFR}
              onChange={handleForm}
            />
          </Grid>
          <Grid md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.formInput.exemptIntercity ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    props.setFormInput({
                      ...props.formInput,
                      exemptIntercity: value,
                    });
                  }}
                />
              }
              label="Driving within an exempt intracity zone (see 49 CFR 391.62) (Federal)"
              name="exemptIntercity"
              value={props.formInput.exemptIntercity}
              onChange={handleForm}
            />
          </Grid>
          <Grid md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.formInput.detPending ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    props.setFormInput({
                      ...props.formInput,
                      detPending: value,
                    });
                  }}
                />
              }
              label="Determination pending (specify reason):"
              name="detPending"
              value={props.formInput.detPending}
              onChange={handleForm}
            />
          </Grid>
          <Grid md={6}>
            <TextField
              required
              fullWidth
              id="detPendingText"
              name="detPendingText"
              value={props.formInput.detPendingText}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.formInput.returnFollowUp ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    props.setFormInput({
                      ...props.formInput,
                      returnFollowUp: value,
                    });
                  }}
                />
              }
              label="Return to medical exam office for follow-up on (must be 45 days or less):"
              name="returnFollowUp"
              value={props.formInput.returnFollowUp}
              onChange={handleForm}
            />
          </Grid>
          <Grid md={6}>
            <TextField
              required
              fullWidth
              id="returnFollowUpText"
              name="returnFollowUpText"
              value={props.formInput.returnFollowUpText}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.formInput.MEReport ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    props.setFormInput({ ...props.formInput, MEReport: value });
                  }}
                />
              }
              label="Medical Examination Report amended (specify reason):"
              name="MEReport"
              value={props.formInput.MEReport}
              onChange={handleForm}
            />
          </Grid>
          <Grid md={6}>
            <TextField
              required
              fullWidth
              id="MEReportText"
              name="MEReportText"
              value={props.formInput.MEReportText}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={4}>
            <Typography variant="subtitle">(if amended)</Typography>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="examinerSignatureAmend"
              label="Medical Examiners Signature"
              name="examinerSignatureAmend"
              value={props.formInput.examinerSignatureAmend}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="amendDate"
              label="Date"
              name="amendDate"
              value={props.formInput.amendDate}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.formInput.incompleteExam ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    props.setFormInput({
                      ...props.formInput,
                      incompleteExam: value,
                    });
                  }}
                />
              }
              label="Incomplete examination (specify reason):"
              name="incompleteExam"
              value={props.formInput.incompleteExam}
              onChange={handleForm}
            />
          </Grid>
          <Grid md={6}>
            <TextField
              required
              fullWidth
              id="incompleteExamText"
              name="incompleteExamText"
              value={props.formInput.incompleteExamText}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={10}>
            <Typography variant="subtitle">
              <b>
                If the driver meets the standards outlined in 49 CFR 391.41,
                then complete a Medical Examiner's Certificate as stated in 49
                CFR 391.43(h), as appropriate.
              </b>
            </Typography>
          </Grid>
          <Grid md={10}>
            <Typography variant="subtitle">
              I have performed this evaluation for certification. I have
              personally reviewed all available records and recorded information
              pertaining to this evaluation, and attest that to the best of my
              knowledge, I believe it to be true and correct.
            </Typography>
          </Grid>
          <Grid md={10}>
            <TextField
              required
              fullWidth
              id="examinerSignature"
              label="Medical Examiner's Signature"
              name="examinerSignature"
              value={props.formInput.examinerSignature}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={10}>
            <TextField
              required
              fullWidth
              id="examinerName"
              label="Medical Examiner's Name (please print or type)"
              name="examinerName"
              value={props.formInput.examinerName}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="examinerAddress"
              label="Medical Examiner's Address"
              name="examinerAddress"
              value={props.formInput.examinerAddress}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={3}>
            <TextField
              required
              fullWidth
              id="city"
              label="City"
              name="city"
              value={props.formInput.city}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="state"
              label="State"
              name="state"
              value={props.formInput.state}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="zip"
              label="Zip"
              name="zip"
              value={props.formInput.zip}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={5}>
            <TextField
              required
              fullWidth
              id="examinerTelephone"
              label="Medical Examiner's Telephone Number"
              name="examinerTelephone"
              value={props.formInput.examinerTelephone}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={5}>
            <TextField
              required
              fullWidth
              id="certificateSigned"
              label="Date Certificate Signed"
              name="certificateSigned"
              value={props.formInput.certificateSigned}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={8}>
            <TextField
              required
              fullWidth
              id="examinerCredentials"
              label="Medical Examiner's State License, Certificate, or Registration Number"
              name="examinerCredentials"
              value={props.formInput.examinerCredentials}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <TextField
              required
              fullWidth
              id="issuingState"
              label="Issuing State"
              name="issuingState"
              value={props.formInput.issuingState}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.formInput.MD ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    props.setFormInput({ ...props.formInput, MD: value });
                  }}
                />
              }
              label="MD"
              name="MD"
              value={props.formInput.MD}
              onChange={handleForm}
            />
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.formInput.DO ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    props.setFormInput({ ...props.formInput, DO: value });
                  }}
                />
              }
              label="DO"
              name="DO"
              value={props.formInput.DO}
              onChange={handleForm}
            />
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.formInput.physAssistant ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    props.setFormInput({
                      ...props.formInput,
                      physAssistant: value,
                    });
                  }}
                />
              }
              label="Physician Assistant"
              name="physAssistant"
              value={props.formInput.physAssistant}
              onChange={handleForm}
            />
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.formInput.Chiropractor ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    props.setFormInput({
                      ...props.formInput,
                      Chiropractor: value,
                    });
                  }}
                />
              }
              label="Chiropractor"
              name="Chiropractor"
              value={props.formInput.Chiropractor}
              onChange={handleForm}
            />
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.formInput.APNurse ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    props.setFormInput({ ...props.formInput, APNurse: value });
                  }}
                />
              }
              label="Advanced Practice Nurse"
              name="APNurse"
              value={props.formInput.APNurse}
              onChange={handleForm}
            />
          </Grid>
          <Grid md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.formInput.otherPract ? true : false}
                  onChange={(e) => {
                    const value = e.target.checked ? "on" : "";
                    props.setFormInput({
                      ...props.formInput,
                      otherPract: value,
                    });
                  }}
                />
              }
              label="Other Practitioner (specify):"
              name="otherPract"
              value={props.formInput.otherPract}
              onChange={handleForm}
            />
          </Grid>
          <Grid md={8}>
            <TextField
              required
              fullWidth
              id="otherPractText"
              name="otherPractText"
              value={props.formInput.otherPractText}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={5}>
            <TextField
              required
              fullWidth
              id="nationalRegistryNo"
              label="National Registry Number"
              name="nationalRegistryNo"
              value={props.formInput.nationalRegistryNo}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={5}>
            <TextField
              required
              fullWidth
              id="certificateExpDate"
              label="Medical Examiner's Certificate Expiration Date"
              name="certificateExpDate"
              value={props.formInput.certificateExpDate}
              onChange={handleForm}
            ></TextField>
          </Grid>
          <Grid md={5}>
            {props.loadedData ? (
              <Button variant="contained" onClick={handleInsert} disabled>
                Send
              </Button>
            ) : (
              <Button variant="contained" onClick={handleInsert}>
                Send
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
