import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../utilities/globalContext";
import { Outlet, Link, Navigate, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import DynamicTabs from "../components/DynamicTabs";
import axios from "axios";
import EmployeeForm from "../components/employeeForm";

export default function NewAuthorization() {
  const { URL } = useContext(GlobalContext);
  const [clients, setClients] = useState([]);
  const [patients, setPatients] = useState([]);
  const [client, setClient] = React.useState("");
  const [patient, setPatient] = React.useState("");
  const [insertStatus, setInsertStatus] = useState(0);
  const [updateStatus, setUpdateStatus] = useState(0);
  const [edit, setEdit] = useState(false);
  const [date, setDate] = useState("");
  const [datePhysical, setDatePhysical] = useState("");
  const [employeeInput, setEmployeeInput] = useState({});
  const [employeePhysical, setEmployeePhysical] = useState({});

  //Employee form state
  const updateEmployeeInput = (e) => {
    setEmployeeInput({
      ...employeeInput,
      [e.target.name]: e.target.value,
      clientId: client,
      dob: date,
    });
  };

  //Employee Physical form state
  const updateEmployeePhysical = (e) => {
    setEmployeePhysical({
      ...employeePhysical,
      [e.target.name]: e.target.value,
    });
  };

  //Get clients
  const getClients = () => {
    axios
      .get(`${URL}ThePortClinic-API/getClients.php`)
      .then((res) => {
        res.data.forEach((element) => {
          setClients(res.data);
          console.log(clients);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Get patients
  const getPatients = (id) => {
    axios
      .get(`${URL}ThePortClinic-API/getClientEmployees.php?client_id=${id}`)
      .then((res) => {
        setPatients((patients) => res.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Call get clients
  useEffect(() => {
    getClients();
  }, []);

  //Input data collecting
  const handleChange = (event) => {
    updateEmployeeInput(event);
  };

  //Employee Physical data collecting
  const handlePhysicalChange = (event) => {
    updateEmployeePhysical(event);
  }

  //Modal toggle
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    setEdit(false);
    setDate("");
    setEmployeeInput(initialFormState);
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    setEmployeeInput(initialFormState);
    console.log(date._d);
  };

  //Handle selects
  const handleClientChange = (event) => {
    setClient(event.target.value);
    getPatients(event.target.value);
  };

  const selectClientOptions = () => {
    return clients.map((item) => (
      <MenuItem value={item.id}>{item.company_name}</MenuItem>
    ));
  };

  const handlePatientChange = (event) => {
    setPatient(event.target.value);
  };

  useEffect(() => {
    getPatients(client);
  }, [insertStatus]);

  const selectPatientOptions = () => {
    if (patients.length === 0 || patients.status === false) {
      return <MenuItem value={0}>No patients...</MenuItem>;
    } else {
      return patients.map((item) =>
        item.middle_name ? (
          <MenuItem
            value={item.id}
          >{`${item.first_name} ${item.middle_name} ${item.last_name}`}</MenuItem>
        ) : (
          <MenuItem
            value={item.id}
          >{`${item.first_name} ${item.last_name}`}</MenuItem>
        )
      );
    }
  };

  const initialFormState = {};
  Array.from(document.querySelectorAll("input")).forEach((input) => {
    initialFormState[input.name] = "";
  });

  return (
    <Box>
      <Typography variant="h4">New Authorization</Typography>
      <Paper elevation={5} sx={{ p: 3, mt: 2 }}>
        <Grid container spacing={2} columns={8}>
          <Grid md={3}>
            <FormControl fullWidth>
              <InputLabel id="client-label">Company</InputLabel>
              <Select
                labelId="client-label"
                id="client"
                value={client}
                label="Client"
                onChange={handleClientChange}
              >
                {selectClientOptions()}
              </Select>
            </FormControl>
          </Grid>
          <Grid md={3}>
            <FormControl fullWidth>
              <InputLabel id="patient-label">Patient</InputLabel>
              <Select
                labelId="patient-label"
                id="patient"
                value={patient}
                label="Patient"
                onChange={handlePatientChange}
              >
                {selectPatientOptions()}
              </Select>
            </FormControl>
          </Grid>
          <Grid mdOffset="auto">
            {client === "" ? (
              <Button variant="contained" onClick={handleOpen} disabled>
                Add New Patient
              </Button>
            ) : (
              <Button variant="contained" onClick={handleOpen}>
                Add New Patient
              </Button>
            )}
          </Grid>
          <Grid md={2}>
            {client === "" || patient === "" ? (
              <Link
                to="/New_Authorization_Info"
                state={{ client_id: client, employee_id: patient }}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Button variant="contained" disabled>Next</Button>
              </Link>
            ) : (
              <Link
                to="/New_Authorization_Info"
                state={{ client_id: client, employee_id: patient }}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Button variant="contained">Next</Button>
              </Link>
            )}
          </Grid>
        </Grid>
      </Paper>
      <EmployeeForm
        edit={edit}
        setEdit={setEdit}
        open={open}
        setOpen={setOpen}
        insertStatus={insertStatus}
        setInsertStatus={setInsertStatus}
        updateStatus={updateStatus}
        setUpdateStatus={setUpdateStatus}
        date={date}
        setDate={setDate}
        datePhysical={datePhysical}
        setDatePhysical={setDatePhysical}
        employeeInput={employeeInput}
        setEmployeeInput={setEmployeeInput}
        handleChange={handleChange}
        employeePhysical={employeePhysical}
        setEmployeePhysical={setEmployeePhysical}
        handlePhysicalChange={handlePhysicalChange}
        handleClose={handleClose}
        URL={URL}
      />
    </Box>
  );
}
